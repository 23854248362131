import React from "react";
import { useParams } from "react-router-dom";
import ProjectHeading from "./ProjectHeading";

const CommercialDeatils = ({ CommercialProjectsDetails }) => {
    const { id } = useParams();
    const CommercialProjectsDetail = CommercialProjectsDetails.find((p) => p.id === parseInt(id));
  
    if (!CommercialProjectsDetail) {
      return <div>Product not found</div>;
    }
  {console.log()}
    return (
      <div>
        <div className="project-main-image">
          <div >
          <img
          src={CommercialProjectsDetail.src}
          alt={`CommercialProject ${CommercialProjectsDetail.id}`}
          style={{ width: "100%", height: "400px", objectFit: "cover" }}
        />
          </div>
          <div className="DprojectStrip">
          <h2>{CommercialProjectsDetail.content1} <span>{CommercialProjectsDetail.content2}</span></h2>
        <br></br>
        <p>{CommercialProjectsDetail.paragraph}</p>
          </div>
        </div>
       
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "20px",
              justifyContent: "center",
              marginTop: "20px",
          }}
        >
          {CommercialProjectsDetail.children.map((child) => (
            <img
              key={child.id}
              src={child.src}
              alt={`Product ${child.id}`}
              style={{
              
                width: "350px",
                objectFit: "cover",
                borderRadius: "10px",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              }}
            />
          ))}
        </div>
      </div>
    );
  };

export default CommercialDeatils
