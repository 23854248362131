import React, { useState } from "react";
import Contact from "./Contact";
import contactimage1 from "../../Assests/ContactUsCover.jpg";
import "./ContactMain.css";
import CallIcon from "@mui/icons-material/Call";
import MailIcon from "@mui/icons-material/Mail";
import contactMobile from "../../Assests/Testimonials/contact-phone.jpg";

const ContactMain = () => {


  return (
    <div>
      <div>
        <div className="main-img-about">
          <img src={contactimage1} alt="contactimage1" />
        </div>
        <div className="main-img-about-phone">
          <img src={contactMobile} alt="contactimage1" />
        </div>
      </div>
      <div>
        <div className="contactus-banner">
        <div className="contact-us-banner-main1">
          <div className="contact-us-banner-sub1">
            <div>
              <h2> For More Vendor Related Queries, Contact Us At </h2>
            </div>
            <div
              style={{
                display: "flex",
                gap: "10px",
                width:"250px",
                margin:"15px"
              }}
            >
              <div>
                <CallIcon />
              </div>
              <div><b>+91 86607 69235</b></div>
            </div>
            <div
              style={{
                display: "flex",
                gap: "10px",
                width:"250px",
               
              
              }}
            >
              <div>
                <MailIcon />
              </div>
              <div><b>cbedesignstudio@gmail.com</b></div>
            </div>
          </div>
        </div>
        <div className="contact-us-banner-main1">
          <div className="contact-us-banner-sub1">
            <div>
              <h2> For More Job Related Queries  Contact Us At</h2>
            </div>
            <div
              style={{
                display: "flex",
                gap: "10px",
                width:"250px",
                margin:"15px"
              }}
            >
              <div>
                <CallIcon />
              </div>
              <div><b>+91 8660769235</b></div>
            </div>
            <div
              style={{
                display: "flex",
                gap: "10px",
                width:"250px",
               
              
              }}
            >
              <div>
                <MailIcon />
              </div>
              <div><b>infocbedesignstudio@gmail.com</b></div>
            </div>
          </div>
        </div>
        </div>
      </div>
      <div>
        <Contact />
      </div>
      <div className="responsive-map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3888.581598172859!2d77.62050487484069!3d12.934591487377393!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae15a5d7337eed%3A0x4bed764145c3b352!2sCBE%20Design%20Studio%20%7C%20Best%20Architects%20%7C%20Interior%20Design%20Company%20%7C%20Bangalore!5e0!3m2!1sen!2sin!4v1716874265422!5m2!1sen!2sin"
          allowFullScreen
          Loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
    </div>
  );
};

export default ContactMain;
