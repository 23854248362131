import React from "react";
import ongoing from "../../Assests/Ongoing/OngoingCover1.jpg";
import { Link } from "react-router-dom";
import "./OngoingProject.css"
import ongoingphonecover from "../../Assests/Ongoing/ongoingphonecover.jpg"

const OngoingProject = ({ Ongoing }) => {
  return (
    <div>
      <div>
        <div>
          <div className="main-complete">
            <img src={ongoing} alt="xx" />
          </div>
          <div className="video-container1">
            <img src={ongoingphonecover} alt="kk" />
          </div>
          
        
          <div className="ongoingbaground"
          style={{
            margin: "20px",
            display: "flex",
            justifyContent: "center",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "20px",
            
          }}>
            {Ongoing.map((Ongoing) => (
              <div key={Ongoing.id} className="image1">
                <Link to={`/Ongoing/${Ongoing.id}`}>
                  <img
                    src={Ongoing.src}
                    alt={`Ongoing ${Ongoing.id}`}
                    style={{
                      width: "350px",
                      height:"250px",
                     
                      objectFit: "cover",
                      borderRadius: "20px",
                     boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    }}
                  />
                  <div className="content">
                    <h2>{Ongoing.area} <span>{Ongoing.area1}</span></h2>
                    
                  </div>
                </Link>
              </div>
              
              
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default OngoingProject;
