import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InfoIcon from '@mui/icons-material/Info';
import ContactsIcon from '@mui/icons-material/Contacts';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import PersonIcon from '@mui/icons-material/Person';
import AssistantPhotoIcon from '@mui/icons-material/AssistantPhoto';
import './Sidebar.css'
export default function Sidebar() {
  const [state, setState] = React.useState({
    right: false,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <List>
        {['Home', 'About Us', 'Projects', '3D Reference Projects','Completed Projects', 'Commercial Projects', 'Ongoing Projects'].map((text, index) => (
            
          <ListItem key={text} disablePadding>
            <ListItemButton>
            {index === 0 ? <HomeIcon /> : index === 1 ? <InfoIcon /> : index === 2 ?<AccountBalanceIcon/>  : index === 3 }
            <Link to={`/${text}`}  className='Link'>
            <ListItemText primary={text} sx={{ml:1}}/>
            </Link>
            </ListItemButton>
          </ListItem>
        ))}
      </List>
      
      <List>
        {['Careers','Contact Us' , 'Inspiration'].map((text, index) => (
           <Link to={`/${text}`} className='Link'>
      
          <ListItem key={text} disablePadding sx={{ width: '100%' }}>
           
            <ListItemButton sx={{ width: '100%' }}>
                {index === 0 ? <PersonIcon/> : index === 1 ? <ContactsIcon/> : <AssistantPhotoIcon/>}
             
             <ListItemText primary={text} sx={{ml:1 , width: '100%'}} />
           
            </ListItemButton>
       
          </ListItem>
          </Link>
        ))}
      </List>
    </Box>
  );

  return (
    <div>
      {['right' ].map((anchor) => (
        <React.Fragment key={anchor}>
          <Button onClick={toggleDrawer(anchor, true)}><MenuIcon/></Button>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}