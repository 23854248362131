import React from 'react'
import './ProjectHeading.css'
const ProjectHeading = () => {
  return (
    <div className='Projectheading'>
      <h1>Project Details</h1>
    </div>
  )
}

export default ProjectHeading
