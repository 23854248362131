import React from "react";
import "./Privacy.css"
const Privacy = () => {
  return (
    <div className="Main-Privacy-div">
      <p>Our website address is: https://www.cbedesignstudio.com.</p>
      <h2>
        At CBE Design Studio, we are committed to safeguarding your privacy.
        This Privacy Statement outlines how we collect, use, and protect your
        personal information.
      </h2>
      <ul>
        <li>
          <b>Information Collection:</b> We only collect your information when you
          voluntarily submit it through our online forms. This may include your
          name, email address, phone number, and any other details you provide.
        </li>
        <li>
        <b>Use of Information:</b> Once you submit your information, we securely
          store it in our database. We use this information solely for the
          purpose of communicating with you regarding your inquiry or project.
        </li>
        <li>
        <b> Third-Party Disclosure:</b> We never share your personal details with any
          third party without your explicit consent. Your privacy is of utmost
          importance to us, and we ensure that your information remains
          confidential.
        </li>
        <li>
        <b>Data Removal:</b> Upon completion or declination of your project, we
          permanently delete your details from our database. We understand the
          importance of data security and take proactive measures to protect
          your information.
        </li>
       
      </ul>
      <p>
        By using our website and submitting your information, you consent to the
        collection and use of your personal data as outlined in this Privacy
        Statement.
      </p>
      <p>
        If you have any questions or concerns regarding our privacy practices,
        please don't hesitate to contact us at cbedesignstudio@gmail.com.
      </p>
    </div>
  );
};

export default Privacy;
