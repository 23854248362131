import nazeeba1 from "../src/Assests/Ongoing/1,Najeeba/IMG-5098.jpg";
import nazeeba2 from "../src/Assests/Ongoing/1,Najeeba/IMG-5099.jpg"
import nazeeba3 from "../src/Assests/Ongoing/1,Najeeba/IMG-5100.jpg"
import nazeeba4 from "../src/Assests/Ongoing/1,Najeeba/IMG-5101.jpg"
import nandihills1 from "../src/Assests/Ongoing/NandiHills/NandiHills (1).jpeg"
import nandihills2 from "../src/Assests/Ongoing/NandiHills/NandiHills (2).jpeg"
import nandihills3 from "../src/Assests/Ongoing/NandiHills/NandiHills (3).jpeg"
import nandihills4 from "../src/Assests/Ongoing/NandiHills/NandiHills (4).jpeg"
import nandihills5 from "../src/Assests/Ongoing/NandiHills/NandiHills (5).jpeg"
import nandihills6 from "../src/Assests/Ongoing/NandiHills/NandiHills (6).jpeg"
import nandihills7 from "../src/Assests/Ongoing/NandiHills/NandiHills (7).jpeg"
import nandihills8 from "../src/Assests/Ongoing/NandiHills/NandiHills (8).jpeg"
import coorgcover from "../src/Assests/Ongoing/A20Coorg/CoorgCover (41).jpeg"
import  coorg1 from "../src/Assests/Ongoing/A20Coorg/Coorg (1).jpeg"
import  coorg2 from "../src/Assests/Ongoing/A20Coorg/Coorg (2).jpeg"
import  coorg3 from "../src/Assests/Ongoing/A20Coorg/Coorg (3).jpeg"
import  coorg4 from "../src/Assests/Ongoing/A20Coorg/Coorg (4).jpeg"
import  coorg5 from "../src/Assests/Ongoing/A20Coorg/Coorg (5).jpeg"
import  coorg6 from "../src/Assests/Ongoing/A20Coorg/Coorg (6).jpeg"
import  coorg7 from "../src/Assests/Ongoing/A20Coorg/Coorg (7).jpeg"
import  coorg8 from "../src/Assests/Ongoing/A20Coorg/Coorg (8).jpeg"
import  coorg9 from "../src/Assests/Ongoing/A20Coorg/Coorg (9).jpeg"
import  coorg10 from "../src/Assests/Ongoing/A20Coorg/Coorg (10).jpeg"
import  coorg11 from "../src/Assests/Ongoing/A20Coorg/Coorg (11).jpeg"
import  coorg12 from "../src/Assests/Ongoing/A20Coorg/Coorg (12).jpeg"
import  coorg13 from "../src/Assests/Ongoing/A20Coorg/Coorg (13).jpeg"
import  coorg14 from "../src/Assests/Ongoing/A20Coorg/Coorg (14).jpeg"
import  coorg15 from "../src/Assests/Ongoing/A20Coorg/Coorg (15).jpeg"
import  coorg16 from "../src/Assests/Ongoing/A20Coorg/Coorg (16).jpeg"
import  coorg17 from "../src/Assests/Ongoing/A20Coorg/Coorg (17).jpeg"
import  coorg18 from "../src/Assests/Ongoing/A20Coorg/Coorg (18).jpeg"
import  coorg19 from "../src/Assests/Ongoing/A20Coorg/Coorg (19).jpeg"
import  coorg20 from "../src/Assests/Ongoing/A20Coorg/Coorg (20).jpeg"
import  coorg21 from "../src/Assests/Ongoing/A20Coorg/Coorg (21).jpeg"
import  coorg22 from "../src/Assests/Ongoing/A20Coorg/Coorg (22).jpeg"
import  coorg23 from "../src/Assests/Ongoing/A20Coorg/Coorg (23).jpeg"
import  coorg24 from "../src/Assests/Ongoing/A20Coorg/Coorg (24).jpeg"
import  coorg25 from "../src/Assests/Ongoing/A20Coorg/Coorg (25).jpeg"
import  coorg26 from "../src/Assests/Ongoing/A20Coorg/Coorg (26).jpeg"
import  coorg27 from "../src/Assests/Ongoing/A20Coorg/Coorg (27).jpeg"
import  coorg28 from "../src/Assests/Ongoing/A20Coorg/Coorg (28).jpeg"
import  coorg29 from "../src/Assests/Ongoing/A20Coorg/Coorg (29).jpeg"
import  coorg30 from "../src/Assests/Ongoing/A20Coorg/Coorg (30).jpeg"
import  coorg31 from "../src/Assests/Ongoing/A20Coorg/Coorg (31).jpeg"
import  coorg32 from "../src/Assests/Ongoing/A20Coorg/Coorg (32).jpeg"
import  coorg33 from "../src/Assests/Ongoing/A20Coorg/Coorg (33).jpeg"
import  coorg34 from "../src/Assests/Ongoing/A20Coorg/Coorg (34).jpeg"
import  coorg35 from "../src/Assests/Ongoing/A20Coorg/Coorg (35).jpeg"
import  coorg36 from "../src/Assests/Ongoing/A20Coorg/Coorg (36).jpeg"
import  coorg37 from "../src/Assests/Ongoing/A20Coorg/Coorg (37).jpeg"
import  coorg38 from "../src/Assests/Ongoing/A20Coorg/Coorg (38).jpeg"
import  coorg39 from "../src/Assests/Ongoing/A20Coorg/Coorg (39).jpeg"
import  coorg40 from "../src/Assests/Ongoing/A20Coorg/Coorg (40).jpeg"
import skcover from "../src/Assests/Ongoing/12,SK Reddy/skcover.jpeg"
import skreddy1 from "../src/Assests/Ongoing/12,SK Reddy/SKREDDY (11).jpeg"
import skreddy2 from "../src/Assests/Ongoing/12,SK Reddy/SKREDDY (12).jpeg"
import skreddy3 from "../src/Assests/Ongoing/12,SK Reddy/SKREDDY (13).jpeg"
import skreddy4 from "../src/Assests/Ongoing/12,SK Reddy/SKREDDY (14).jpeg"
import skreddy5 from "../src/Assests/Ongoing/12,SK Reddy/SKREDDY (15).jpeg"
import skreddy6 from "../src/Assests/Ongoing/12,SK Reddy/SKREDDY (16).jpeg"
import skreddy7 from "../src/Assests/Ongoing/12,SK Reddy/SKREDDY (19).jpeg"
import skreddy8 from "../src/Assests/Ongoing/12,SK Reddy/SKREDDY (17).jpeg"
import skreddy9 from "../src/Assests/Ongoing/12,SK Reddy/SKREDDY (18).jpeg"
import skreddy10 from "../src/Assests/Ongoing/12,SK Reddy/SKREDDY (20).jpeg"
import skreddy11 from "../src/Assests/Ongoing/12,SK Reddy/SKREDDY (23).jpeg"
import skreddy12 from "../src/Assests/Ongoing/12,SK Reddy/SKREDDY (21).jpeg"
import skreddy13 from "../src/Assests/Ongoing/12,SK Reddy/SKREDDY (22).jpeg"
import skreddy14 from "../src/Assests/Ongoing/12,SK Reddy/SKREDDY (24).jpeg"
import skreddy15 from "../src/Assests/Ongoing/12,SK Reddy/SKREDDY (26).jpeg"
import skreddy16 from "../src/Assests/Ongoing/12,SK Reddy/SKREDDY (25).jpeg"
import skreddy17 from "../src/Assests/Ongoing/12,SK Reddy/SKREDDY (27).jpeg"
import skreddy18 from "../src/Assests/Ongoing/12,SK Reddy/SKREDDY (28).jpeg"
import skreddy19 from "../src/Assests/Ongoing/12,SK Reddy/SKREDDY (29).jpeg"
import skreddy20 from "../src/Assests/Ongoing/12,SK Reddy/SKREDDY (10).jpeg"
import skreddy21 from "../src/Assests/Ongoing/12,SK Reddy/SKREDDY (9).jpeg"
import skreddy22 from "../src/Assests/Ongoing/12,SK Reddy/SKREDDY (7).jpeg"
import skreddy23 from "../src/Assests/Ongoing/12,SK Reddy/SKREDDY (6).jpeg"
import skreddy24 from "../src/Assests/Ongoing/12,SK Reddy/SKREDDY (8).jpeg"
import skreddy25 from "../src/Assests/Ongoing/12,SK Reddy/SKREDDY (3).jpeg"
import skreddy26 from "../src/Assests/Ongoing/12,SK Reddy/sk reddy (1).jpeg"
import skreddy27 from "../src/Assests/Ongoing/12,SK Reddy/sk reddy (8).jpeg"
import skreddy28 from "../src/Assests/Ongoing/12,SK Reddy/sk reddy (14).jpeg"
import skreddy29 from "../src/Assests/Ongoing/12,SK Reddy/sk reddy (12).jpeg"
import skreddy30 from "../src/Assests/Ongoing/12,SK Reddy/sk reddy (15).jpeg"
import skreddy31 from "../src/Assests/Ongoing/12,SK Reddy/sk reddy (17).jpeg"
import skreddy32 from "../src/Assests/Ongoing/12,SK Reddy/SKREDDY (33).jpeg"
import skreddy33 from "../src/Assests/Ongoing/12,SK Reddy/SKREDDY (35).jpeg"
import skreddy34 from "../src/Assests/Ongoing/12,SK Reddy/SKREDDY (38).jpeg"
import skreddy35 from "../src/Assests/Ongoing/12,SK Reddy/SKREDDY (39).jpeg"
import skreddy36 from "../src/Assests/Ongoing/12,SK Reddy/SKREDDY (40).jpeg"
import skreddy37 from "../src/Assests/Ongoing/12,SK Reddy/SKREDDY (2).jpeg"
import skreddy38 from "../src/Assests/Ongoing/12,SK Reddy/SKREDDY (30).jpeg"

import shimja1 from "../src/Assests/Ongoing/13,Shimja/SHIMJA (1).jpeg"
import shimja2 from "../src/Assests/Ongoing/13,Shimja/SHIMJA (2).jpeg"
import shimja3 from "../src/Assests/Ongoing/13,Shimja/SHIMJA (3).jpeg"
import shimja4 from "../src/Assests/Ongoing/13,Shimja/SHIMJA (4).jpeg"
import shimja5 from "../src/Assests/Ongoing/13,Shimja/SHIMJA (5).jpeg"
import shimja6 from "../src/Assests/Ongoing/13,Shimja/SHIMJA (6).jpeg"
import shimja7 from "../src/Assests/Ongoing/13,Shimja/SHIMJA (7).jpeg"
import shimja8 from "../src/Assests/Ongoing/13,Shimja/SHIMJA (8).jpeg"
import shimja9 from "../src/Assests/Ongoing/13,Shimja/SHIMJA (9).jpeg"
import shimja10 from "../src/Assests/Ongoing/13,Shimja/SHIMJA (10).jpeg"
import shimja11 from "../src/Assests/Ongoing/13,Shimja/SHIMJA (11).jpeg"
import shimja12 from "../src/Assests/Ongoing/13,Shimja/SHIMJA (12).jpeg"
import shimja13 from "../src/Assests/Ongoing/13,Shimja/SHIMJA (13).jpeg"
import shimja14 from "../src/Assests/Ongoing/13,Shimja/SHIMJA (14).jpeg"
import shimja15 from "../src/Assests/Ongoing/13,Shimja/SHIMJA (15).jpeg"
import shimja16 from "../src/Assests/Ongoing/13,Shimja/SHIMJA (16).jpeg"
import shimja17 from "../src/Assests/Ongoing/13,Shimja/SHIMJA (17).jpeg"
import surjapura1 from "../src/Assests/Ongoing/14,Sarjapur/sarjapur (1).jpeg"
import surjapura2 from "../src/Assests/Ongoing/14,Sarjapur/sarjapur (2).jpeg"
import surjapura3 from "../src/Assests/Ongoing/14,Sarjapur/sarjapur (3).jpeg"
import surjapura4 from "../src/Assests/Ongoing/14,Sarjapur/sarjapur (4).jpeg"
import surjapura5 from "../src/Assests/Ongoing/14,Sarjapur/sarjapur (5).jpeg"
import surjapura6 from "../src/Assests/Ongoing/14,Sarjapur/sarjapur (6).jpeg"
import surjapura7 from "../src/Assests/Ongoing/14,Sarjapur/sarjapur (7).jpeg"
import surjapura8 from "../src/Assests/Ongoing/14,Sarjapur/sarjapur (8).jpeg"
import surjapura9 from "../src/Assests/Ongoing/14,Sarjapur/sarjapur (9).jpeg"

import NazeebaCover1 from "../src/Assests/Ongoing/15,Anjanapura site/nazeebaCover.jpeg"
import Anjanapura1 from "../src/Assests/Ongoing/15,Anjanapura site/Anjanapura (1).jpeg"
import Anjanapura2 from "../src/Assests/Ongoing/15,Anjanapura site/Anjanapura (2).jpeg"
import Anjanapura3 from "../src/Assests/Ongoing/15,Anjanapura site/Anjanapura (3).jpeg"
import Anjanapura4 from "../src/Assests/Ongoing/15,Anjanapura site/Anjanapura (4).jpeg"
import Anjanapura5 from "../src/Assests/Ongoing/15,Anjanapura site/Anjanapura (5).jpeg"
import Anjanapura6 from "../src/Assests/Ongoing/15,Anjanapura site/Anjanapura (7).jpeg"
import Anjanapura7 from "../src/Assests/Ongoing/15,Anjanapura site/Anjanapura (8).jpeg"
import Anjanapura8 from "../src/Assests/Ongoing/15,Anjanapura site/Anjanapura (9).jpeg"
import Anjanapura9 from "../src/Assests/Ongoing/15,Anjanapura site/Anjanapura (10).jpeg"
import Anjanapura10 from "../src/Assests/Ongoing/15,Anjanapura site/Anjanapura (11).jpeg"
import Anjanapura11 from "../src/Assests/Ongoing/15,Anjanapura site/Anjanapura (13).jpeg"
import palakkad1 from "../src/Assests/Ongoing/5,bineesh palakkad/palakkad (1).jpeg"
import palakkad2 from "../src/Assests/Ongoing/5,bineesh palakkad/palakkad (2).jpeg"
import palakkad3 from "../src/Assests/Ongoing/5,bineesh palakkad/palakkad (3).jpeg"
import palakkad4 from "../src/Assests/Ongoing/5,bineesh palakkad/palakkad (4).jpeg"
import palakkad5 from "../src/Assests/Ongoing/5,bineesh palakkad/palakkad (5).jpeg"
import palakkad6 from "../src/Assests/Ongoing/5,bineesh palakkad/palakkad (6).jpeg"
import palakkad7 from "../src/Assests/Ongoing/5,bineesh palakkad/palakkad (7).jpeg"
import palakkad8 from "../src/Assests/Ongoing/5,bineesh palakkad/palakkad (8).jpeg"
import palakkad9 from "../src/Assests/Ongoing/5,bineesh palakkad/palakkad (9).jpeg"
import palakkad10 from "../src/Assests/Ongoing/5,bineesh palakkad/palakkad (10).jpeg"
import ecity1 from "../src/Assests/Ongoing/16,Ecity/Ecity (1).jpeg"
import ecity2 from "../src/Assests/Ongoing/16,Ecity/Ecity (2).jpeg"
import ecity3 from "../src/Assests/Ongoing/16,Ecity/Ecity (3).jpeg"
import ecity4 from "../src/Assests/Ongoing/16,Ecity/Ecity (4).jpeg"
import ecity5 from "../src/Assests/Ongoing/16,Ecity/Ecity (5).jpeg"
import ecity6 from "../src/Assests/Ongoing/16,Ecity/Ecity (6).jpeg"
import ecity7 from "../src/Assests/Ongoing/16,Ecity/Ecity (7).jpeg"
import ecity8 from "../src/Assests/Ongoing/16,Ecity/Ecity (8).jpeg"
import ecity9 from "../src/Assests/Ongoing/16,Ecity/Ecity (9).jpeg"
import ecity10 from "../src/Assests/Ongoing/16,Ecity/Ecity (10).jpeg"
import ecity11 from "../src/Assests/Ongoing/16,Ecity/Ecity (11).jpeg"
import ecity12 from "../src/Assests/Ongoing/16,Ecity/Ecity (12).jpeg"
import ecity13 from "../src/Assests/Ongoing/16,Ecity/Ecity (13).jpeg"
import ecity14 from "../src/Assests/Ongoing/16,Ecity/Ecity (14).jpeg"
import ecity15 from "../src/Assests/Ongoing/16,Ecity/Ecity (15).jpeg"
import ecity16 from "../src/Assests/Ongoing/16,Ecity/Ecity (16).jpeg"
import ecity17 from "../src/Assests/Ongoing/16,Ecity/Ecity (17).jpeg"
import ecity18 from "../src/Assests/Ongoing/16,Ecity/Ecity (18).jpeg"
import ecity19 from "../src/Assests/Ongoing/16,Ecity/Ecity (19).jpeg"
import ecity20 from "../src/Assests/Ongoing/16,Ecity/Ecity (20).jpeg"
import ecity21 from "../src/Assests/Ongoing/16,Ecity/Ecity (21).jpeg"
import ecity22 from "../src/Assests/Ongoing/16,Ecity/Ecity (22).jpeg"
import ecity23 from "../src/Assests/Ongoing/16,Ecity/Ecity (23).jpeg"
import ecity24 from "../src/Assests/Ongoing/16,Ecity/Ecity (24).jpeg"
import ecity25 from "../src/Assests/Ongoing/16,Ecity/Ecity (25).jpeg"
import hasura1 from "../src/Assests/Ongoing/17,Hasuragatta/Hasurgatta (1).jpeg"
import hasura3 from "../src/Assests/Ongoing/17,Hasuragatta/Hasurgatta (3).jpeg"
import hasura4 from "../src/Assests/Ongoing/17,Hasuragatta/Hasurgatta (4).jpeg"
import hasura5 from "../src/Assests/Ongoing/17,Hasuragatta/Hasurgatta (5).jpeg"
import hasura6 from "../src/Assests/Ongoing/17,Hasuragatta/Hasurgatta (6).jpeg"
import hasura7 from "../src/Assests/Ongoing/17,Hasuragatta/Hasurgatta (7).jpeg"
import hasura8 from "../src/Assests/Ongoing/17,Hasuragatta/Hasurgatta (8).jpeg"
import mam1 from "../src/Assests/Ongoing/18Manjushree/Manjushree Mam (1).jpeg"
import mam2 from "../src/Assests/Ongoing/18Manjushree/Manjushree Mam (2).jpeg"
import mam3 from "../src/Assests/Ongoing/18Manjushree/Manjushree Mam (3).jpeg"
import mam4 from "../src/Assests/Ongoing/18Manjushree/Manjushree Mam (4).jpeg"
import mam5 from "../src/Assests/Ongoing/18Manjushree/Manjushree Mam (5).jpeg"
import mam6 from "../src/Assests/Ongoing/18Manjushree/Manjushree Mam (6).jpeg"
import mam7 from "../src/Assests/Ongoing/18Manjushree/Manjushree Mam (7).jpeg"
import mam8 from "../src/Assests/Ongoing/18Manjushree/Manjushree Mam (8).jpeg"
import mam9 from "../src/Assests/Ongoing/18Manjushree/Manjushree Mam (9).jpeg"
import mam10 from "../src/Assests/Ongoing/18Manjushree/Manjushree Mam (10).jpeg"
import mam11 from "../src/Assests/Ongoing/18Manjushree/Manjushree Mam (11).jpeg"
import mam12 from "../src/Assests/Ongoing/18Manjushree/Manjushree Mam (12).jpeg"
import mam13 from "../src/Assests/Ongoing/18Manjushree/Manjushree Mam (13).jpeg"
import mam14 from "../src/Assests/Ongoing/18Manjushree/Manjushree Mam (14).jpg"
import mam15 from "../src/Assests/Ongoing/18Manjushree/Manjushree Mam (15).jpg"
import anu1 from "../src/Assests/Ongoing/19Anu/Anu (1).jpeg"
import anu2 from "../src/Assests/Ongoing/19Anu/Anu (2).jpeg"
import anu3 from "../src/Assests/Ongoing/19Anu/Anu (3).jpeg"
import anu4 from "../src/Assests/Ongoing/19Anu/Anu (4).jpeg"
import anu5 from "../src/Assests/Ongoing/19Anu/Anu (5).jpeg"







export const Ongoing = [
{       
      id: 2,
      src: nandihills1,
      area: " Location: ",
      area1: "Nandi Hills",
      children: [
        {
          id: 51,
          src:nandihills2,
        },
        {
          id: 51,
          src:nandihills3,
        },
        {
          id: 51,
          src:nandihills4,
        },
        {
          id: 51,
          src:nandihills5,
        },
        {
          id: 51,
          src:nandihills6,
        },
        {
          id: 51,
          src:nandihills7,
        },
        {
          id: 51,
          src:nandihills8,
        }
      ]
},
{
  id: 32,
  src: coorgcover,
  area: "Location: ",
  area1: " Coorg",
  children: [
    { id: 23, src: coorg13},
    { id: 11, src: coorg1},
    { id: 12, src: coorg2},
    { id: 13, src: coorg3},
    { id: 14, src: coorg4},
    { id: 15, src: coorg5},
    { id: 16, src: coorg6},
    { id: 17, src: coorg7},
    { id: 19, src: coorg9},
    { id: 20, src: coorg10},
    { id: 25, src: coorg15},
    { id: 22, src: coorg12},
    { id: 21, src: coorg11},
    { id: 41, src: coorg31},
    { id: 24, src: coorg14},
    { id: 26, src: coorg16},// 15

    
    
    
    
    { id: 27, src: coorg17},//  16
    { id: 28, src: coorg18},//17
    { id: 29, src: coorg19},//18
    { id: 33, src: coorg23},//19
    { id: 30, src: coorg20},//20
    { id: 42, src: coorg32},//21
    { id: 44, src: coorg34},//22
    { id: 31, src: coorg21},//23
    { id: 32, src: coorg22},//24
    { id: 34, src: coorg24},//25
    { id: 35, src: coorg25},//26
    { id: 43, src: coorg33},//27
    
    { id: 45, src: coorg35},//28
   
    { id: 46, src: coorg36},//29
    { id: 42, src: coorg37},//30
    { id: 37, src: coorg27},//31
    //32
    { id: 39, src: coorg29},//33
    { id: 36, src: coorg26},//34
    { id: 40, src: coorg30},//35
                            //36
  //37
    { id: 43, src: coorg33},//38
  //39
    { id: 45, src: coorg35},//40
    { id: 46, src: coorg36},//41
    { id: 47, src: coorg37},//42
    { id: 49, src: coorg39},
    { id: 50, src: coorg40},
  
   
  ],
 
},
{
  id: 3,
  src: skcover,
  area: "Location: ",
  area1: " Electronic City",
  children: [
    { id: 11, src: skreddy1},
    { id: 12, src: skreddy2},
    { id: 13, src: skreddy3},
    { id: 14, src: skreddy4},
    { id: 15, src: skreddy5},
    { id: 16, src: skreddy6},
    { id: 17, src: skreddy7},
    { id: 18, src: skreddy8},
    { id: 19, src: skreddy9},
    { id: 20, src: skreddy10},
    { id: 21, src: skreddy11},
    { id: 22, src: skreddy12},
    { id: 23, src: skreddy13},
    { id: 24, src: skreddy14},
    { id: 25, src: skreddy15},
    { id: 26, src: skreddy16},
    { id: 29, src: skreddy19},
    { id: 28, src: skreddy18},
    { id: 27, src: skreddy17},
    { id: 30, src: skreddy20},
    { id: 31, src: skreddy21},
    { id: 32, src: skreddy22},
    { id: 34, src: skreddy24},
    { id: 35, src: skreddy25},
    { id: 42, src: skreddy32},
    { id: 43, src: skreddy33},
    { id: 44, src: skreddy34},
    { id: 45, src: skreddy35},
    { id: 46, src: skreddy36},
    { id: 33, src: skreddy23},
    { id: 42, src: skreddy37},
    { id: 43, src: skreddy38},
    { id: 37, src: skreddy27},
    { id: 38, src: skreddy28},
    { id: 39, src: skreddy29},
    { id: 36, src: skreddy26},
    { id: 40, src: skreddy30},
    { id: 41, src: skreddy31},
   
  ],
 
},

{
  id: 4,
  src: shimja1,
  area: "Location: ",
  area1: "Arekere",
  children:[
    
    { id: 14, src: shimja4},
    { id: 15, src: shimja5},
    { id: 16, src: shimja6},
    { id: 17, src: shimja7},
    { id: 18, src: shimja8},
    { id: 19, src: shimja9},
    { id: 20, src: shimja10},
    { id: 21, src: shimja11},
    { id: 22, src: shimja12},
    { id: 23, src: shimja13},
    { id: 24, src: shimja14},
    { id: 25, src: shimja15},
    { id: 26, src: shimja16},
    { id: 27, src: shimja17},
    { id: 11, src: shimja1},
    { id: 12, src: shimja2},
    { id: 13, src: shimja3},
  ]
},
{
  id: 5,
  src: surjapura1,
  area: "Location: ",
  area1: " sarjapur Road",
  children:[
    ,
    { id: 11, src: surjapura1},
    { id: 12, src: surjapura2},
    { id: 13, src: surjapura3},
    { id: 14, src: surjapura4},
    { id: 15, src: surjapura5},
    { id: 16, src: surjapura6},
    { id: 17, src: surjapura7},
    { id: 18, src: surjapura8},
    { id: 19, src: surjapura9},
  ]
}
,
{
  id: 6,
  src: NazeebaCover1,
  area: "Location: ",
  area1: "AnjanaPura",
  children:[
    { id: 11, src: Anjanapura1},
    { id: 12, src: Anjanapura2},
    { id: 13, src: Anjanapura3},
    { id: 14, src: Anjanapura4},
   
    { id: 17, src: Anjanapura7},
    { id: 18, src: Anjanapura8},
    { id: 19, src: Anjanapura9},
    { id: 20, src: Anjanapura10},
    { id: 21, src: Anjanapura11},
    { id: 15, src: Anjanapura5},
    { id: 16, src: Anjanapura6},
  
  ]
},
{
  id: 7,
  src: palakkad6,
  area: "Location: ",
  area1: "Palakkad",
  children:[
    { id: 11, src:palakkad1},
    { id: 12, src:palakkad2},
    { id: 13, src:palakkad3},
    { id: 14, src:palakkad4},
    { id: 15, src:palakkad5},
    { id: 16, src:palakkad6},
    { id: 17, src:palakkad7},
    { id: 18, src:palakkad8},
    { id: 19, src:palakkad9},
    { id: 20, src:palakkad10},
   
  
  ]
},
{
  id: 8,
  src: ecity25,
  area: "Location: ",
  area1: "Kasavanahalli",
  children: [
    { id: 11, src: hasura1},
    { id: 15, src: hasura5},
    { id: 11, src: ecity1},
    { id: 12, src: ecity2},
    { id: 13, src: ecity3},
    { id: 14, src: ecity4},
    { id: 15, src: ecity5},
    { id: 16, src: ecity6},
    { id: 17, src: ecity7},
    { id: 18, src: ecity8},
    { id: 19, src: ecity9},
    { id: 20, src: ecity10},
    { id: 21, src: ecity11},
    { id: 22, src: ecity12},
    { id: 23, src: ecity13},
    { id: 24, src: ecity14},
    { id: 25, src: ecity15},
    { id: 26, src: ecity16},
    { id: 29, src: ecity19},
    { id: 28, src: ecity18},
    { id: 27, src: ecity17},
    { id: 30, src: ecity20},
    { id: 31, src: ecity21},
    { id: 32, src: ecity22},
    { id: 34, src: ecity24},
    { id: 32, src: ecity23},
    { id: 35, src: ecity25},
    
   
  ],
 
},
{
  id: 10,
  src: hasura7,
  area: "Location: ",
  area1: "Hesaraghatta ",
  children:[
    
    
    { id: 13, src: hasura3},
    { id: 14, src: hasura4},
    
    { id: 16, src: hasura6},
    { id: 17, src: hasura7},
    { id: 18, src: hasura8}
  ]
},
{
  id: 12,
  src: mam15,
  area: "Location: ",
  area1: "Yelahanka",
  children: [
    { id: 20, src: mam10},
    { id: 21, src: mam11},
    { id: 22, src: mam12},
    { id: 23, src: mam13},
    { id: 11, src: mam1},
    { id: 12, src: mam2},
    { id: 13, src: mam3},
    { id: 14, src: mam4},
    { id: 17, src: mam7},
    { id: 15, src: mam5},
    { id: 16, src: mam6},
    
    { id: 18, src: mam8},
    { id: 19, src: mam9},
    { id: 18, src: mam14},
    { id: 19, src: mam15},

   
  ],
 
},

{
  id: 13,
  src: anu4,
  area: "Location",
  area1: "Akshaya Nagar",
  children:[
    { id: 11, src: anu1},
    { id: 12, src: anu2},
    { id: 13, src: anu3},
    { id: 15, src: anu5},
   
  ]    
}
]







