import React from "react";
import "./StyledDetails.css";
import { useParams } from "react-router-dom";
import { itemData } from "./TitlebarBelowMasonryImageList";

const StyleDetails = () => {
  const { id } = useParams();
  const itemDataa = itemData.find((p) => p.id === parseInt(id));

  if (!itemDataa) {
    return <div>Product not found</div>;
  }
  return (
    <div>
      
      <div
        style={{
          display: "flex",
          justifyContent:"center",
          gap:"2vw",
          flexWrap:"wrap",
          margin:"2vw",
          padding:"2vw",
          backgroundColor:"lightgray"
          
        }}
      >
      
        <div>
          <img
            src={itemDataa.img}
            alt={`itemDataa ${itemDataa.id}`}
            style={{ width: "40vw", objectFit: "cover", borderRadius: "20px",  }}
          />
        </div>
        <div>
          <div>
          <h1 style={{ textAlign:"center", margin:".5vw" }}>{itemDataa.author}</h1>
            <p  style={{ width: "40vw", objectFit: "cover", borderRadius: "20px" }}>{itemDataa.contentu}</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default StyleDetails;
