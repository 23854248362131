import React from "react";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import XIcon from "@mui/icons-material/X";
import YouTubeIcon from "@mui/icons-material/YouTube";
import "./ConnectionPopups.css";
import MailIcon from "@mui/icons-material/Mail";

const ConnectionsPopUp = () => {
  return (
    <div>
      <div className="connectionpopus" style={{}}>
        <div>
          <a
            href="https://www.facebook.com/CBEDStudio"
            style={{ color: "black" }}
          >
            {" "}
            <FacebookIcon />
          </a>
        </div>
        <div>
          <a
            href="https://wa.me/8660769235?text=Hello?"
            target="blank"
            style={{ color: "black" }}
          >
            <WhatsAppIcon />
          </a>
        </div>
        <div>
          <a
            href="https://www.instagram.com/cbedesignstudio/"
            style={{ color: "black" }}
          >
            <InstagramIcon />
          </a>
        </div>
        <div>
          <a
            href="https://www.linkedin.com/company/cbe-design-studio/"
            style={{ color: "black" }}
          >
            <LinkedInIcon />
          </a>
        </div>
        <div>
          <a
            href="https://twitter.com/i/flow/login?redirect_after_login=%2Fcbedesignstudio"
            style={{ color: "black" }}
          >
            <XIcon />
          </a>
        </div>
        <div>
          <a
            href="https://www.youtube.com/@cbedesignstudio995"
            style={{ color: "black" }}
          >
            <YouTubeIcon />
          </a>
        </div>
      </div>
    </div>
  );
};

export default ConnectionsPopUp;
