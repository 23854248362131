import React from 'react'
import "./Home.css"
import Image1 from "../../Assests/Image-3.png"
import arc from "../../Assests/Architect.jpeg"
import int from "../../Assests/Interior.jpeg"
import con from "../../Assests/Construction.jpeg"
import TitlebarBelowMasonryImageList from './TitlebarBelowMasonryImageList'
import WorkCard from './WorkCard'
import Experience from './Experience'
import Dsign from './Dsign'
import Contact from '../Contact/Contact'
import Faq from '../../FAQs/Faq'

const Home = () => {
  return (
    <div>
      <div>
        <div style={{display:'flex',
                     justifyContent:"center"}}>
        <div className='main-img'>
    <div className='main-img-tag'>
    </div>
        </div>
        </div>
        <div className='main-tag'>
          <h1>
            
         CBE Design Studio  <br/>A  Luxury Architecture And  <span>Interior Designing Company In Bangalore</span>
          </h1>

        </div>
        
        <div className='service'>
          <div className='service-tag'>
            <h2>Services We Offer</h2>
          </div>
          <div className='service-main'>
            <div className='service-card'>
              <div className='service-card-img'>
                   <img src={arc} alt='Residential Architecture'>
                   </img>
              </div>
              <div className='service-card-text'>
                     <h3>ARCHITECTURAL</h3>
                     <h3> DESIGN</h3>
              </div>
            </div>
            <div className='service-card'>
              <div className='service-card-img'>
                   <img src={int} alt='Interior Design'>
                   </img>
              </div>
              <div className='service-card-text'>
                     <h3>INTERIOR</h3>
                     <h3> DESIGN</h3>
              </div>
            </div>
            <div className='service-card'>
              <div className='service-card-img'>
                   <img src={con} alt='Construction'>
                   </img>
              </div>
              <div className='service-card-text'>
                     <h3>CONSTRUCTION</h3>
                     <h3> ENDEAVOR</h3>
              </div>
            </div>
           

          </div>

        </div>
        <div className='service-txt'>
          <h2>What do we deliver as Architects and Interior Designers ?</h2>
          <p>As one of the best architecture companies in Bangalore, we specialize in creating architectural masterpieces 
            and pride ourselves in delivering stunning interior designs. Fueled by our passion for design, we create exceptional spaces that
            could crown as as one of the best interior designers here. From designing magnificent buildings to transforming interiors, 
            we strive to exceed your expectations. Choose CBE Design Studio as your architectural consultant
            and let us turn your dreams into reality with our exceptional skills and innovative designs.
            We work with both residential architects and commercial architects who strive to elevate  your
            surroundings with their expertise. Experience the transformative power of architecture with us, CBE Design Studio.</p>
        </div>
        <div className='our-div'>
          <div className='finest'>
            <h2>
             <span> Styles </span>That
            </h2>
          </div>
          <div className='arts'>
            <h2><span>We</span> Experienced</h2>
          </div>

        </div>
        <div className='our-images'>
          <TitlebarBelowMasonryImageList/>
        </div>
        <div className='how'>
          <h2>How Does Our Architecture <span>And Interior Designing Firm Work ?</span></h2>
        </div>
        <div>
        <WorkCard/>
        </div>
        <div>
          <Experience/>
        </div>
        <div>
     
     
        </div>
        <div>
          <Contact/>
        </div>
       
      </div>
    </div>
  )
}

export default Home
