import React from 'react'
import Commercial from "../../Assests/Commercials/ComercialMainCover.jpg";
import { Link } from 'react-router-dom';
import { grey } from '@mui/material/colors';
import commercialphonecover from "../../Assests/Commercials/CommercialphoneCover.jpg"



const CommercialProjects = ({CommercialProjectsDetails}) => {
    console.log(CommercialProjectsDetails)
  return (
    <div>
     <div>
        <div>
        <div className="main-complete">
            <img src={Commercial} alt="ww"  />
          </div>
          <div className="video-container1">
            <img src={commercialphonecover} alt="kk" />
          </div>
          
          <div>
          <div className="sub-images-div"
        style={{
          margin: "20px",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          justifyContent: "center",
          gap:"2vw",
        }}
      >
        {CommercialProjectsDetails.map((CommercialProjectsDetail) => (
          <div key={CommercialProjectsDetail.id} className="image1">
            <Link to={`/CommercialDetails/${CommercialProjectsDetail.id}`}>
              <img
                src={CommercialProjectsDetail.src}
                alt={`Product ${CommercialProjectsDetail.id}`}
                style={{
                  width: "350px",
                  height:"250px",
                 
                  objectFit: "cover",
                  borderRadius: "20px",
                 boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                }}
              />
               <div className="content">
              <h2>{CommercialProjectsDetail.content1}  <span>{CommercialProjectsDetail.content2}</span></h2>
              <p>{CommercialProjectsDetail.content3}
              </p>
            </div>
            </Link>
          </div>
        ))}
      </div>
          </div>
        </div>
     </div>
    </div>
  )
}

export default CommercialProjects
