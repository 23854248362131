import React from "react";
import "./Footer.css";
import logo from "../../Assests/CBE Logo 1.png";
import FacebookIcon from "@mui/icons-material/Facebook";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import InstagramIcon from "@mui/icons-material/Instagram";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import XIcon from "@mui/icons-material/X";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CallIcon from "@mui/icons-material/Call";
import MailIcon from "@mui/icons-material/Mail";
import { Link } from "react-router-dom";
const Footer = () => {
  return (
    <div>
      <div>
        <div className=" footer-tag">
          <h2>" Architecture begins where</h2>
          <h2>Engineering ends " </h2>
        </div>
        <div className="main-footer">
          <div className="footer-top">
            <div className="footer-logo-div">
              <img src={logo}></img>
              <figcaption>Architecture And Interiors</figcaption>
            </div>
            <div className="footer-top-right">
              <div>
                <h3>Architecture Styles</h3>
                <ul>
                  
                <li><Link to="styledetails/4">Parametric Style </Link></li>
                  <li><Link to="styledetails/7">Fluidic Style</Link></li>
                  <li><Link to="styledetails/1">Victorian Style</Link></li>
                   <li><Link to="styledetails/5">Kerla Style</Link></li>
                  <li><Link  to="styledetails/3">Spanish Style</Link></li>
                  <li><Link to="styledetails/2">Triangular Style</Link></li>
                  
                </ul>
              </div>
              <div>
                <h3>Resources</h3>
                <ul>
                  <li> <Link to = "/inspiration">Ideas</Link></li>
                  <li><Link  to="/completedProjects">Projects</Link></li>
                </ul>
              </div>
              <div>
                <h3>Company</h3>
                <ul>
                  <li><Link to="/about">About Us</Link></li>
                  <li><Link  to="/careers">Join Us</Link></li>
                 
                </ul>
              </div>
              <div>
                <h3>Support</h3>
                <ul>
                  <li><Link to="/contact">  Contact Us  </Link>  </li>
                  <li> <a href="https://wa.me/8660769235?text=Hello?" target="blank">  Chat With Us </a></li>
                </ul>
              </div>
            </div>
          </div>

          <div className="footer-down">
            <div className="footer-down-left">
              <div className="connect">
                <h4>Connect With Us </h4>
              </div>
              <div className="icons">
                <div>
                  <a href="https://www.facebook.com/CBEDStudio">
                    {" "}
                    <FacebookIcon />
                  </a>
                </div>
                <div>
                 <a href="https://wa.me/8660769235?text=Hello?" target="blank">
                 <WhatsAppIcon />
                 </a>
                </div>
                <div>
                  <a href="https://www.instagram.com/cbedesignstudio/">
                    <InstagramIcon />
                  </a>
                </div>
                <div>
                  <a href="https://www.linkedin.com/company/cbe-design-studio/">
                    <LinkedInIcon />
                  </a>
                </div>
                <div>
                  <a href="https://twitter.com/i/flow/login?redirect_after_login=%2Fcbedesignstudio">
                    <XIcon />
                  </a>
                </div>
              </div>
            </div>
            <div className="footer-down-right">
              <div className="adress">
                <LocationOnIcon />
                No 52, Nirmala Mansion 18th ‘A’Main 4th Cross 5th Block,
                Koramangala Bangalore, Karnataka 560095
              </div>
              <div className="adress">
                <CallIcon /> 086607 69235
              </div>
              <div className="adress">
                <div>
                  <MailIcon />
                </div>
                <div>cbedesignstudio@gmail.com</div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
      <div className="keywordsfooter">
        <p>
        We from CBE Design Studio, are proud to be recognized as one of the
         top architectural firms in Bangalore, renowned for delivering 
         best-in-class design solutions. Our team of experienced designers
          are dedicated to pushing the boundaries of innovation and 
          sustainability in every project we undertake. From crafting 
          visionary residential spaces to designing iconic commercial 
          complexes , we strive to exceed industry standards. Complex 
          designs are a product of our expertise and challenges are the
           source of our motivation.
        </p>
        <br/>
        <p>
        As one of the leading Architecture firms in Bangalore, we are 
        committed to our passion which means, our output is maximum in terms
         of both design and time, which results in timely handovers and 
         visually aesthetic projects. We also pride ourselves to be one of 
         the top resort architects in the south. Our projects speak volumes
          about our dedication and attention to detail.

        </p>
      </div>
      <div style={{ textAlign: "center",  backgroundColor:"#DADADE" }}>
            <h5> <Link to='/Privacy'>Privacy Statement</Link></h5>
          </div>
      <div style={{ textAlign: "center", padding: "2vw" ,  backgroundColor:"#DADADE" }}>
            <h5> © 2021 CB Emporio, ALL RIGHTS RESERVED</h5>
          </div>
         
    </div>
  );
};

export default Footer;
