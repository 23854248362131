import React from "react";
import { useParams } from "react-router-dom";
import "./CompletdDetails.css"
import ProjectHeading from "./ProjectHeading";

const CompletedDetails = ({ products }) => {
  const { id } = useParams();
  const product = products.find((p) => p.id === parseInt(id));

  if (!product) {
    return <div>Product not found</div>;
  }

  return (
    <div>
      <ProjectHeading/>
      {console.log(product.src, "hi")}
      <img
        src={product.src}
        alt={`Product ${product.id}`}
        style={{ width: "100%", height: "350px", objectFit: "cover" }}
      />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: "20px",
          justifyContent: "center",
          marginTop: "20px",
        }}
      >
        {product.children.map((child) => (
          <img
            key={child.id}
            src={child.src}
            alt={`Product ${child.id}`}
            style={{
              margin: "5px",
              width: "350px",

              objectFit: "cover",
              borderRadius: "10px",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            }}
          />
        ))}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          marginTop: "20px",
        }}
      >
        <iframe
         
          src={product.link}
          title="YouTube video player"
          frameborder="0"
          color="white"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </div>
    </div>
  );
};

export default CompletedDetails;
