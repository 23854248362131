import React from "react";
import { Link } from "react-router-dom";
import video from "../../Assests/3DProjects/Video1.mp4";
import "./Dprojects.css";
import dcover from "../../Assests/3DProjects/3DCover.jpg"
import dcover2 from "../../Assests/3DProjects/3DCover2.jpg"
import dcover3 from "../../Assests/3DProjects/3dcover3.jpg"
import bgimagelines from "../../Assests/Screenshot 2024-02-15 162423.png"
import dphonecover from "../../Assests/3DProjects/3dphonecover1.jpg"
const Dprojects = ({details}) => {
 console.log(details)

  return (
    <div>
      <div>
        <div>
          <div className="video-container">
            <img src={dcover3} alt="kk" />
          </div>
          <div className="video-container1">
            <img src={dphonecover} alt="kk" />
          </div>
          <div>
            {/* <div className="complete-details-tag">
                <h1>
                Embark on a Journey Through Architectural Brilliance With Our Immersive 3D Designs.
                </h1>
            </div> */}
          <div className="computer-images">
            <div className="sub-images-div"
        style={{
          margin: "10px",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: "1vw",
       
          justifyContent: "center",

        }}
      >
        {details.map((detail) => (
          <div key={detail.id} className="image1">
            <Link to={`/3dprojectdetails/${detail.id}`}>
              <img
                src={detail.src}
                alt={`Product ${detail.id}`}
                style={{
                  width: "350px",
                  height:"250px",
                 
                  objectFit: "cover",
                  borderRadius: "20px",
                 boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                }}
              />
               <div className="content">
              <h2>{detail.content1}  <span>{detail.content2}</span></h2>
              <p>{detail.paragraph}
              </p>
            </div>
            </Link>
           
          </div>
        ))}
      </div>
            </div>
           
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dprojects;
