import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import "./Contact.css";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import CallIcon from "@mui/icons-material/Call";
import MailIcon from "@mui/icons-material/Mail";
import LanguageIcon from "@mui/icons-material/Language";
import ReCAPTCHA from "react-google-recaptcha";

const Contact = () => {
  const [dis, setDis] = useState(true);
  const [val, setVal] = useState("");
  const [val1, setVal1] = useState("");
  const [val2, setVal2] = useState("");
  const [val3, setVal3] = useState("");

  const form = useRef();

  function handelchangenumber(e) {
    if (e.target.value.length === 10) {
      setDis(false);
    }
  }

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs
      .sendForm(
        "service_mj608qb",
        "template_4woa9gk",
        form.current,
        "5dughSTAV6pWZG0FS"
      )
      .then(
        () => {
          alert("Your form has been submitted successfully");
          console.log("SUCCESS!");
          resetForm();
        },
        (error) => {
          console.log("FAILED...", error.text);
        }
      );
  };

  const resetForm = () => {
    setVal("");
    setVal1("");
    setVal2("");
    setVal3("");
  };

  return (
    <div>
      <div className="contact-tag">
        <h2>
          Contact <span>Us</span>
        </h2>
      </div>
      <div className="contact-div">
        <div className="contact-left">
          <div className="contact-sub-left">
            <div className="contact-left1">
              <h3>Get In Touch</h3>
              <p>
                Connecting with CBE Design Studio is effortless. Our doors are
                always open to those who seek support and guidance in the
                architecture and interior designing field. Whether you have
                questions, feedback, or simply want to share your thoughts, we
                welcome your communication. We prioritize fostering meaningful
                relationships with our well-wishers, ensuring that you feel
                valued and heard. Reach out to us anytime, as we are here to
                serve and support you.
              </p>
            </div>
            <div className="adress-main">
              <div className="adress1">
                <div>
                  <CallIcon className="aa" />
                </div>
                <div>
                  <p>+91 86607 69235</p>
                </div>
              </div>
              <div className="adress1">
                <div>
                  <LanguageIcon className="aa" />
                </div>
                <div>
                  <p> www.cbedesignstudio.com</p>
                </div>
              </div>
              <div className="adress1">
                <MailIcon className="aa" />
                <div>
                  <p> cbedesignstudio@gmail.com</p>
                </div>
              </div>
              <div className="adress1">
                <div>
                  <LocationOnIcon className="aa" />
                </div>
                <div>
                  <p>
                    {" "}
                    No 52, Nirmala Mansion 18th ‘A’Main 4th Cross 5th Block,
                    Koramangala Bangalore, Karnataka 560095
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="contact-right">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <div className="form-quote">
              <h2>Want To Build Your Dream Home With Us ? </h2>
              <h2>Fill Out The Form Given Below </h2>
            </div>
          </div>
          <form ref={form} onSubmit={sendEmail}>
            <div className="contact-name">
              <input
                type="text"
                name="user_name"
                value={val}
                onChange={(e) => setVal(e.target.value)}
                placeholder="Name"
                required
              />
              <input
                type="number"
                name="user_number"
                placeholder="Number"
                required
                onChange={handelchangenumber}
              />
            </div>
            <div className="contact-name">
              <input
                type="text"
                name="user_place"
                value={val1}
                onChange={(e) => setVal1(e.target.value)}
                placeholder="Place / City"
                required
              />
              <input
                type="text"
                name="user_budget"
                value={val2}
                onChange={(e) => setVal2(e.target.value)}
                placeholder="Budget"
                required
              />
            </div>
            <div className="contact-message">
              <div>
                <textarea
                  name="message"
                  value={val3}
                  onChange={(e) => setVal3(e.target.value)}
                  placeholder="Message"
                  required
                />
              </div>
            </div>
            <div className="submit-button">
              <button type="submit" disabled={dis}>
                Book Consultation
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default Contact;
