import React from "react";
import "./Inspiration.css";
import inspiration1 from "../../Assests/Commercials/5,Lakshadweep Resort/InspirationCopy.jpg";
import empire2 from "../../Assests/Commercials/1,Empire Madhur/Empire Resturant view3.jpg";
import empire1 from "../../Assests/Commercials/2,empire devanhalli 2/Empire view 1 (1).jpg";
import inspirationVideo from "../../Assests/InspirationVideo.mp4";
import inspirationVideo2 from "../../Assests/Testimonials/Traingle_Inspiration.mp4";
import inspirationVideo3 from "../../Assests/Inspiration/Home Interiors.mp4"

const Inspiration = () => {
  return (
    <div>
      <div>
        <div className="Inspiration-Intro-tag">
          <h1>    Creating Shapes....<span> Beyond Limits.</span></h1>
          <div className="inspiration-first-image">
            <img src={inspiration1} alt="Resort Architect"/>
          </div>
          <div className="Inspiration-Intro">
            <ul>
              <li>
                As one of the best architecture company in Bangalore, 
                we draw inspiration from the vibrant tapestry of nature that
                surrounds us. The beauty of the natural world invokes ideas for our
                 interior designs. We try to incorporate textures in the designs we create. In our
                homes, you'll find echoes of sandy beaches and windswept dunes,
                evoking a sense of tranquility and serenity. The earthy tones
                and textures makes our designs stand out. As one of the top interior
                 designers in Bangalore, we are inspired by the
                intricate patterns of bee hives, we prioritize functionality and
                organization, ensuring that every element of our designs serves
                a purpose while maintaining a sense of beauty and harmony.
              </li>
              <li>
                Join us as we celebrate the beauty of nature and weave its
                essence into the fabric of your home, creating spaces that are
                not only visually stunning but also deeply connected to the
                world around us.
              </li>
            </ul>
          </div>
          <div className="Inspiration-Intro-tag">
            <h1>Cloud Pattern Wall <span>With Veneer A Finish</span></h1>
          </div>
          <div>
            <div className="inspiration-cloud">
              <div className="insprationVideo">
                <video loop controls>
                  <source src={inspirationVideo} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
              <div>
                <p>
                  At CBE Design Studio, our creative inspiration flows from many
                  sources, but we’ve recently been captivated by the
                  ever-changing beauty of clouds. Clouds represent a fascinating
                  blend of softness and strength, movement and stillness, chaos
                  and order. They drift across the sky, constantly reshaping
                  themselves, illustrating the harmony between fluidity and
                  structure—a balance that is central to our architectural
                  approach.
                </p>
                <p>
                  Clouds can take on various forms, from delicate wisps to
                  towering cumulonimbus formations. They adapt to their
                  surroundings while retaining a sense of individuality. This
                  adaptability is something we strive to emulate in our designs.
                  Just as clouds reflect the changing light and colors of the
                  sky, we aim to create spaces that respond to their environment
                  and the needs of those who use them.
                </p>
                <p>
                  The diverse textures and patterns found in clouds inspire us
                  to think creatively about materials and spatial
                  configurations. Our designs, influenced by these natural
                  elements, seek to evoke a sense of wonder and tranquility. By
                  embracing the lessons we learn from clouds, we create
                  buildings that are not only aesthetically pleasing but also
                  functional and sustainable.
                </p>
                <p>
                  Join us at CBE Design Studio, as we explore the beauty of
                  clouds and channel their essence into our work. We invite you
                  to experience our architectural vision, where each project is
                  a unique reflection of the world around us, shaped by the
                  subtle and grand elements of nature.
                </p>
              </div>
            </div>
            <div>
            <div className="Inspiration-Intro-tag">
              <h1>Crafting Geometry :<span> A Stunning Display</span> </h1>
            </div>
            <div className="inspiration-cloud">
              <div>
                <p>
                  Triangular openings can be an excellent example of how
                  geometry can be seamlessly integrated into architectural
                  design to create visually striking and unique spaces. By
                  incorporating these triangular openings into the compound hall
                  of a house, you're not only adding an intriguing visual
                  element but also infusing the space with a sense of modernity
                  and sophistication.
                </p>
                <p>
                  Integrating geometry into architectural design offers
                  multifaceted benefits. Firstly, geometric elements add a
                  distinctive visual allure to structures. Whether it's the
                  sleek lines of a modernist facade or the intricate patterns of
                  Islamic architecture, geometry captivates the eye and imbues
                  spaces with a sense of sophistication and originality.
                </p>
                <p>
                  Moreover, geometry isn't just about aesthetics; it's deeply
                  intertwined with the structural integrity of buildings. Shapes
                  like triangles and arches distribute weight efficiently,
                  making them essential for creating stable, enduring
                  structures. By leveraging geometric principles, architects can
                  design buildings that not only look stunning but also stand
                  the test of time.
                </p>
                <p>
                  Beyond structural considerations, geometry plays a crucial
                  role in spatial organization. Proportions, ratios, and spatial
                  relationships are carefully calibrated to optimize
                  functionality and flow within a building. Geometric layouts
                  can delineate zones, define circulation paths, and create
                  focal points, enhancing usability and coherence.Get in touch with
                   the one of best architecture companies 
                  in Bangalore to experience elevated living.
                </p>
              </div>
              <div className="insprationVideo">
                <video loop controls>
                  <source src={inspirationVideo2} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
            </div>
            <div className="Inspiration-Intro-tag">
              <h1>Capital by White Sand: <span>A Pine Wood Structure Design</span> </h1>
            </div>
            <div className="inspiration-cloud">
              <div className="insprationVideo3">
                <iframe
                  src="https://www.youtube.com/embed/Xlh7gnMYVd4"
                  title="YouTube video player"
                  frameborder="0"
                  color="white"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowfullscreen
                ></iframe>
              </div>
              <div>
                <p>
                Discover our luxurious resort on the shores of Lakshadweep, a masterpiece where 
                nature and luxury intertwine seamlessly. As resort architects in Bangalore,
                we drew profound inspiration from the natural beauty of the ocean, rippled 
                white sands, and the elegant simplicity of coastal life. Our signature pine
                wood structures exude timeless elegance, while seashells and paddles delicately
                partition spaces, creating a harmonious blend of indoor and outdoor environments.
                Each design element is thoughtfully curated to evoke a sense of serenity and
                connection with the pristine surroundings, promising an unforgettable experience.
                </p>
                <p>
                In designing the reception area, we aimed to capture the essence of coastal serenity. Textured
                 white sand walls create an inviting atmosphere, instantly transporting guests to the tranquil 
                 shores of Lakshadweep. The lobby, inspired by the rippled patterns of the sand, features a floating
                  boat motif that enhances the coastal theme and adds a touch of nautical charm. This design ethos 
                  reflects our commitment to harmonizing luxury
                 with nature's beauty, ensuring that every guest feels a profound sense of relaxation and escape.
                </p>
                <p>
                As one of the best interior designing companies in Bangalore, our mission is to blend nature 
                with luxury in every project we undertake. The use of paddles and seashells as partitions
                 throughout the resort not only serves a functional purpose but also reinforces the connection 
                 to the ocean and beach life. This project is a testament to our dedication to creating spaces
                  that are both beautiful and meaningful, offering guests a sanctuary where they can immerse 
                  themselves
                 in the natural splendor of Lakshadweep while enjoying the highest standards of comfort and elegance.
                </p>
                
              </div>
            </div>
            <div>
            <div>
            <div className="Inspiration-Intro-tag">
              <h1>Illuminating Innovation:<span> Windows of Wonder</span> </h1>
            </div>
            <div className="inspiration-cloud">
              <div>
                <p>
                At CBE Design Studio, we draw inspiration from the boundless creativity that shapes can
                 offer to architectural design. Embracing diverse window shapes, such as triangles an
                 circles, allows us to break away from conventional norms and introduce unique visual
                  elements into our projects. 
                </p>
                <p>
                Incorporating windows of various shapes can dramatically transform the ambiance of a home.
                Triangular windows, for instance, add a dynamic and modern touch, infusing spaces with 
                a sense of movement and energy. Circular windows, on the other hand, offer a softer,
                more organic feel, creating a serene and inviting atmosphere. By strategically
                placing these shapes, we can guide natural light in innovative ways, highlighting
                architectural features and creating captivating patterns of light and shadow 
                throughout the day.
                </p>
                <p>
                One of our most exciting concepts is the introduction of uniquely shaped windows
                 next to the stairs. This innovative design element not only enhances the aesthetic 
                 appeal but also serves a practical purpose. The windows allow natural light to
                  flood the staircase area, making it feel more open and airy. The interplay of 
                  light and shapes transforms a typically utilitarian space into a visually
                   engaging experience. At CBE Design Studio, we believe that the thoughtful
                    integration of diverse window shapes can elevate the design and functionality 
                    of a home, making it truly one of a kind.
                </p>
                
              </div>
              <div className="insprationVideo">
                <video loop controls>
                  <source src={inspirationVideo3} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Inspiration;
