import "./App.css";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom"
import { useState, useEffect } from "react";
import Home from "./Components/Home/Home";
import Contact from "./Components/Contact/Contact";
import Inspiration from "./Components/Insipiration/Inspiration"
import Navbar from "./Components/Navbar.jsx/Navbar";
import Careers from "./Components/Careers/Careers";
import About from "./Components/About/About";
import Projects from "./Components/Projects/Projects";
import Footer from "./Components/Home/Footer";
import ScrollToTop from "./Components/ScrollToTop";
import BarLoader from "react-spinners/BarLoader";
import Dprojects from "./Components/Projects/Dprojects";
import ProjectDetails from "./Components/Projects/ProjectDetails";
import CompletdProjects from "./Components/Projects/CompletdProjects";
import CompletedDetails from "./Components/Projects/CompletedDetails";
import { products } from "./Data";
import { details } from "./Data2";
import { Ongoing } from "./Data4";
import { CommercialProjectsDetails } from "./Data3";
import Ddetails from "./Components/Projects/Ddetails";
import StyleDetails from "./Components/Home/StyleDetails";
import CommercialProjects from "./Components/Projects/CommercialProjects";
import CommercialDeatils from "./Components/Projects/CommercialDeatils";
import ConnectionsPopUp from "./Components/PopUps/ConnectionsPopUp";
import ContactMain from "./Components/Contact/ContactMain";
import OngoingProject from "./Components/Projects/OngoingProject";
import OngoinDetails from "./Components/Projects/OngoingDetails";
import Error from "./Components/Error/Error";
import Privacy from "./Components/PrivacyStatement/Privacy";
import Publication from "./Components/Publication/Publication";
// import Projects from "./Components/Projects/Projects";


function App() {
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);



  return (
    <div className="App">
      {loading ? (
        <BarLoader color={"#35363A"} className="spinner" loading={loading} size={700} />
      ) : (
        <Router>
          <ScrollToTop />
          <Navbar />
          <ConnectionsPopUp/>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/Home" element={<Home />} />
            <Route path="/AboutUs" element={<About />} />
            <Route path="/About Us" element={<About />} />
            <Route path="/Contact" element={<ContactMain />} />
            <Route path="/Contact Us" element={<ContactMain />} />
            <Route path="/careers" element={<Careers />} />
            <Route path="projects" element={<Projects />} />
            <Route path ="/Inspiration" element={<Inspiration />} />
            <Route path = "/Dprojects" element={<ProjectDetails />}/>
            <Route path = "/Publication" element={<Publication/>}/>
            <Route path="/3dReferenceProjects" element={<Dprojects details = {details}/>} />
            <Route path="/3D Reference Projects" element={<Dprojects details = {details}/>} />
            <Route path="/OngoingProjects" element= {<OngoingProject Ongoing={Ongoing}/>}/>
            <Route path="/Ongoing Projects" element= {<OngoingProject Ongoing={Ongoing}/>}/>
            <Route path = "/Ongoing/:id" element={<OngoinDetails Ongoing={Ongoing}/>}/>
            <Route path = "/3dprojectdetails/:id" element={<Ddetails details = {details}/>}/>
            <Route path = "/CompletedProjects" element={<CompletdProjects products={products}/>}/>
            <Route path = "/Completed Projects" element={<CompletdProjects products={products}/>}/>
            <Route path = "/CommercialProjects" element={<CommercialProjects CommercialProjectsDetails={ CommercialProjectsDetails}/>}/>
            <Route path = "/Commercial Projects" element={<CommercialProjects CommercialProjectsDetails={ CommercialProjectsDetails}/>}/>
            <Route path = "/product/:id" element={<CompletedDetails  products={products}/>}/>
            <Route path="/styledetails/:id" element={<StyleDetails />}/>
            <Route path="/CommercialDetails/:id" element={<CommercialDeatils  CommercialProjectsDetails={ CommercialProjectsDetails} />}/>
            <Route path="/Privacy" element={<Privacy/>}/>
            <Route path="/ConnectionsPopUp" element={<ConnectionsPopUp />}/>
            <Route path="*" element ={<Error/>}/>
            
          </Routes>
          <Footer />
        </Router>
)}
    </div>
  );
}

export default App;
