import A1 from "../src/Assests/Completed/A1SK/SadatkhanCover.jpg";
import A2 from "../src/Assests/Completed/A1SK/A2-min.jpg";
import A4 from "../src/Assests/Completed/A1SK/A4-min.jpg";
import A5 from "../src/Assests/Completed/A1SK/A5-min.jpg";
import A6 from "../src/Assests/Completed/A1SK/A6-min.jpg";
import A7 from "../src/Assests/Completed/A1SK/A7-min.jpg";
import A8 from "../src/Assests/Completed/A1SK/A8-min.jpg";
import A9 from "../src/Assests/Completed/A1SK/A9-min.jpg";
import A10 from "../src/Assests/Completed/A1SK/A10-min.jpg";
import A11 from "../src/Assests/Completed/A1SK/A11-min.jpg";
import A12 from "../src/Assests/Completed/A1SK/A12-min.jpg";
import A13 from "../src/Assests/Completed/A1SK/A13-min.jpg";
import A14 from "../src/Assests/Completed/A1SK/A14-min.jpg";
import A15 from "../src/Assests/Completed/A1SK/A15-min.jpg";
import A17 from "../src/Assests/Completed/A1SK/A17-min.jpg";
import A18 from "../src/Assests/Completed/A1SK/A18-min.jpg";
import A19 from "../src/Assests/Completed/A1SK/A19-min.jpg";
import B1 from "../src/Assests/Completed/A2SKREDDY/SKCOVER.jpg";
import B2 from "../src/Assests/Completed/A2SKREDDY/B2.jpg";
import B3 from "../src/Assests/Completed/A2SKREDDY/B3.jpg";
import B4 from "../src/Assests/Completed/A2SKREDDY/B4.jpg";
import B5 from "../src/Assests/Completed/A2SKREDDY/B5.jpg";
import B6 from "../src/Assests/Completed/A2SKREDDY/B6.jpg";
import B7 from "../src/Assests/Completed/A2SKREDDY/B7.jpg";
import B8 from "../src/Assests/Completed/A2SKREDDY/B8.jpg";
import B9 from "../src/Assests/Completed/A2SKREDDY/B9.jpg";
import B10 from "../src/Assests/Completed/A2SKREDDY/B10.jpg";
import C1 from "../src/Assests/Completed/A3HMTT/_DSC8769-min.jpg";
import C2 from "../src/Assests/Completed/A3HMTT/_DSC8657-min.jpg";
import C3 from "../src/Assests/Completed/A3HMTT/_DSC8753-min.jpg";
import C4 from "../src/Assests/Completed/A3HMTT/_DSC8805-min.jpg";
import C5 from "../src/Assests/Completed/A3HMTT/_DSC8666-min.jpg";
import C6 from "../src/Assests/Completed/A3HMTT/_DSC8791-min.jpg";
import C7 from "../src/Assests/Completed/A3HMTT/_DSC8676-min.jpg";
import C9 from "../src/Assests/Completed/A3HMTT/_DSC8690-min.jpg";
import C10 from "../src/Assests/Completed/A3HMTT/_DSC8721-min.jpg";
import C11 from "../src/Assests/Completed/A3HMTT/_DSC8700-min.jpg";
import C12 from "../src/Assests/Completed/A3HMTT/_DSC8734-min.jpg";
import C13 from "../src/Assests/Completed/A3HMTT/_DSC8809-min.jpg";
import C14 from "../src/Assests/Completed/A3HMTT/_DSC8676-min.jpg";
import C15 from "../src/Assests/Completed/A3HMTT/_DSC8786-min.jpg";
import C16 from "../src/Assests/Completed/A3HMTT/_DSC8783-min.jpg";
import C17 from "../src/Assests/Completed/A3HMTT/_DSC8774-min.jpg";
import C18 from "../src/Assests/Completed/A3HMTT/_DSC8771-min.jpg";
import C19 from "../src/Assests/Completed/A3HMTT/_DSC8656-min.jpg";
import D1 from "../src/Assests/Completed/A4MANJU/D6.jpg";
import D2 from "../src/Assests/Completed/A4MANJU/D8.jpg";
import D3 from "../src/Assests/Completed/A4MANJU/D14,.jpg";
import D4 from "../src/Assests/Completed/A4MANJU/D9.jpg";
import D5 from "../src/Assests/Completed/A4MANJU/D10.JPG";
import D6 from "../src/Assests/Completed/A4MANJU/D15,.JPG";
import D7 from "../src/Assests/Completed/A4MANJU/D16.JPG";
import D8 from "../src/Assests/Completed/A4MANJU/D17.JPG";
import E1 from "../src/Assests/Completed/A5LOHIT/POP06980.jpg";
import E2 from "../src/Assests/Completed/A5LOHIT/POP06986.jpg";
import E3 from "../src/Assests/Completed/A5LOHIT/POP06989.jpg";
import E4 from "../src/Assests/Completed/A5LOHIT/POP07001.jpg";
import E5 from "../src/Assests/Completed/A5LOHIT/POP07002.jpg"
import E6 from "../src/Assests/Completed/A5LOHIT/POP07006.jpg"
import E7 from "../src/Assests/Completed/A5LOHIT/POP07016 - Copy.jpg"
import E8 from "../src/Assests/Completed/A5LOHIT/POP07021.jpg"
import E9 from "../src/Assests/Completed/A5LOHIT/POP07029.jpg"
import E10 from "../src/Assests/Completed/A5LOHIT/POP07046.jpg"
import E11 from "../src/Assests/Completed/A5LOHIT/POP07059.jpg"
import E12 from "../src/Assests/Completed/A5LOHIT/POP07071.jpg"
import E13 from "../src/Assests/Completed/A5LOHIT/POP07076.jpg"
import E14 from "../src/Assests/Completed/A5LOHIT/POP07077.jpg"
import E15 from "../src/Assests/Completed/A5LOHIT/POP07102.jpg"
import E16 from "../src/Assests/Completed/A5LOHIT/POP07106.jpg"
import E17 from "../src/Assests/Completed/A5LOHIT/POP07113.jpg"
import U1 from "../src/Assests/Completed/A6(1)Ranjan/RanjanCover.jpg"
import U2 from "../src/Assests/Completed/A6(1)Ranjan/WhatsApp Image 2024-04-05 at 11.08.31 AM.jpeg"
import U3 from "../src/Assests/Completed/A6(1)Ranjan/WhatsApp Image 2024-04-05 at 11.08.32 AM (1).jpeg"
import U4 from "../src/Assests/Completed/A6(1)Ranjan/WhatsApp Image 2024-04-05 at 11.08.32 AM.jpeg"
import U5 from "../src/Assests/Completed/A6(1)Ranjan/WhatsApp Image 2024-04-05 at 11.08.33 AM (1).jpeg"
import U6 from "../src/Assests/Completed/A6(1)Ranjan/WhatsApp Image 2024-04-05 at 11.08.33 AM.jpeg"
import U7 from "../src/Assests/Completed/A6(1)Ranjan/WhatsApp Image 2024-04-05 at 11.08.34 AM (1).jpeg"
import U8 from "../src/Assests/Completed/A6(1)Ranjan/WhatsApp Image 2024-04-05 at 11.08.34 AM.jpeg"
import U9 from "../src/Assests/Completed/A6(1)Ranjan/WhatsApp Image 2024-04-05 at 11.08.35 AM (1).jpeg"
import U10 from "../src/Assests/Completed/A6(1)Ranjan/WhatsApp Image 2024-04-05 at 11.08.37 AM (2).jpeg"
import U11 from "../src/Assests/Completed/A6(1)Ranjan/WhatsApp Image 2024-04-05 at 11.08.37 AM (1).jpeg"
import U12 from "../src/Assests/Completed/A6(1)Ranjan/WhatsApp Image 2024-04-05 at 11.08.36 AM.jpeg"
import U13 from "../src/Assests/Completed/A6(1)Ranjan/WhatsApp Image 2024-04-05 at 11.08.35 AM.jpeg"
import U14 from "../src/Assests/Completed/A6(1)Ranjan/WhatsApp Image 2024-04-05 at 11.08.35 AM (2).jpeg"
import F1 from "../src/Assests/Completed/A6ANU/AnucoverMains.jpg";
import F2 from "../src/Assests/Completed/A6ANU/AnuHouse2 (2).jpeg";
import F3 from "../src/Assests/Completed/A6ANU/AnuHouse2 (3).jpeg";
import F4 from "../src/Assests/Completed/A6ANU/AnuHouse3 (1).jpg";
import F5 from "../src/Assests/Completed/A6ANU/AnuHouse3 (2).jpg";
import F6 from "../src/Assests/Completed/A6ANU/Anucover1.jpg";
import F7 from "../src/Assests/Completed/A6ANU/bAnu (1).jpeg";
import F8 from "../src/Assests/Completed/A6ANU/bAnu (1).jpg";
import F9 from "../src/Assests/Completed/A6ANU/bAnu (2).jpg";
import F10 from "../src/Assests/Completed/A6ANU/bAnu (3).jpeg";
import F11 from "../src/Assests/Completed/A6ANU/bAnu (4).jpeg";
import F12 from "../src/Assests/Completed/A6ANU/bAnu (4).jpg";
import F13 from "../src/Assests/Completed/A6ANU/bAnu (5).jpeg";
import F14 from "../src/Assests/Completed/A6ANU/bAnu (5).jpg";
import F15 from "../src/Assests/Completed/A6ANU/bAnu (8).jpeg";
import F16 from "../src/Assests/Completed/A6ANU/bAnu (9).jpeg";
import F17 from "../src/Assests/Completed/A6ANU/bAnu (10).jpeg";
import F18 from "../src/Assests/Completed/A6ANU/bAnu (11).jpeg";
import F19 from "../src/Assests/Completed/A6ANU/bAnu (12).jpg";
import G21 from "../src/Assests/Completed/A8METRO/11.JPG";
import G2 from "../src/Assests/Completed/A8METRO/12.JPG";
import G3 from "../src/Assests/Completed/A8METRO/13.JPG";
import G4 from "../src/Assests/Completed/A8METRO/14.JPG";
import G5 from "../src/Assests/Completed/A8METRO/15.JPG";
import G6 from "../src/Assests/Completed/A8METRO/24.JPG";
import G7 from "../src/Assests/Completed/A8METRO/25.JPG";
import G8 from "../src/Assests/Completed/A8METRO/26.JPG";
import G9 from "../src/Assests/Completed/A8METRO/27.JPG";
import G10 from "../src/Assests/Completed/A8METRO/05.jpg";
import G11 from "../src/Assests/Completed/A8METRO/6.JPG";
import G12 from "../src/Assests/Completed/A8METRO/7.JPG";
import G13 from "../src/Assests/Completed/A8METRO/1.JPG";
import G14 from "../src/Assests/Completed/A8METRO/2.JPG";
import G15 from "../src/Assests/Completed/A8METRO/3.JPG";
import G16 from "../src/Assests/Completed/A8METRO/8.JPG";
import G17 from "../src/Assests/Completed/A8METRO/9.JPG";
import G18 from "../src/Assests/Completed/A8METRO/10.JPG";
import G1 from "../src/Assests/Completed/A8METRO/CoverMetro.JPG";
import G31 from "../src/Assests/Completed/A8METRO FRONT SIDE(1)/METROFRONT (1).jpeg"
import G32 from "../src/Assests/Completed/A8METRO FRONT SIDE(1)/METROFRONT (2).jpeg"
import G33 from "../src/Assests/Completed/A8METRO FRONT SIDE(1)/METROFRONT (3).jpeg"
import G34 from "../src/Assests/Completed/A8METRO FRONT SIDE(1)/METROFRONT (4).jpeg"
import G35 from "../src/Assests/Completed/A8METRO FRONT SIDE(1)/METROFRONT (5).jpeg"
import G36 from "../src/Assests/Completed/A8METRO FRONT SIDE(1)/METROFRONT (6).jpeg"
import G37 from "../src/Assests/Completed/A8METRO FRONT SIDE(1)/METROFRONT (7).jpeg"
import G38 from "../src/Assests/Completed/A8METRO FRONT SIDE(1)/METROFRONT (8).jpeg"
import G39 from "../src/Assests/Completed/A8METRO FRONT SIDE(1)/METROFRONT (9).jpeg"
import G40 from "../src/Assests/Completed/A8METRO FRONT SIDE(1)/METROFRONT (10).jpeg"
import G42 from "../src/Assests/Completed/A8METRO FRONT SIDE(1)/METROFRONT (11).jpeg"
import G43 from "../src/Assests/Completed/A8METRO FRONT SIDE(1)/METROFRONT (12).jpeg"
import G44 from "../src/Assests/Completed/A8METRO FRONT SIDE(1)/METROFRONT (13).jpeg"
import H1 from "../src/Assests/Completed/A9SHIMJA/WhatsApp Image 2024-01-13 at 12.27.25 PM (5).jpeg";
import H2 from "../src/Assests/Completed/A9SHIMJA/WhatsApp Image 2024-01-13 at 12.27.25 PM (6).jpeg";
import H3 from "../src/Assests/Completed/A9SHIMJA/WhatsApp Image 2024-01-13 at 12.27.25 PM (7).jpeg";
import H4 from "../src/Assests/Completed/A9SHIMJA/WhatsApp Image 2024-01-13 at 12.27.26 PM (1).jpeg";
import H5 from "../src/Assests/Completed/A9SHIMJA/WhatsApp Image 2024-01-13 at 12.27.26 PM.jpeg";
import I1 from "../src/Assests/Completed/A10THAMRASERY/H9.JPG";
import I2 from "../src/Assests/Completed/A10THAMRASERY/H1.JPG";
import I3 from "../src/Assests/Completed/A10THAMRASERY/H2.JPG";
import I4 from "../src/Assests/Completed/A10THAMRASERY/H3.JPG";
import I5 from "../src/Assests/Completed/A10THAMRASERY/H5.JPG";
import I6 from "../src/Assests/Completed/A10THAMRASERY/H4.JPG";
import I7 from "../src/Assests/Completed/A10THAMRASERY/H6.JPG";
import I8 from "../src/Assests/Completed/A10THAMRASERY/H7.JPG";
import I9 from "../src/Assests/Completed/A10THAMRASERY/Thamrsey (1).jpeg"
import I10 from "../src/Assests/Completed/A10THAMRASERY/Thamrsey (2).jpeg"
import I11 from "../src/Assests/Completed/A10THAMRASERY/Thamrsey (3).jpeg"
import I12 from "../src/Assests/Completed/A10THAMRASERY/Thamrsey (4).jpeg"
import I13 from "../src/Assests/Completed/A10THAMRASERY/Thamrsey (5).jpeg"
import I15 from "../src/Assests/Completed/A10THAMRASERY/Thamrsey (6).jpeg"
import I16 from "../src/Assests/Completed/A10THAMRASERY/Thamrsey (7).jpeg"
import I17 from "../src/Assests/Completed/A10THAMRASERY/Thamrsey (8).jpeg"
import I18 from "../src/Assests/Completed/A10THAMRASERY/Thamrsey (9).jpeg"
import I19 from "../src/Assests/Completed/A10THAMRASERY/Thamrsey (10).jpeg"
import I20 from "../src/Assests/Completed/A10THAMRASERY/Thamrsey (11).jpeg"
import I21 from "../src/Assests/Completed/A10THAMRASERY/Thamrsey (12).jpeg"
import I22 from "../src/Assests/Completed/A10THAMRASERY/Thamrsey (13).jpeg"
import I23 from "../src/Assests/Completed/A10THAMRASERY/Thamrsey (14).jpeg"
import I24 from "../src/Assests/Completed/A10THAMRASERY/Thamrsey (15).jpeg"
import I25 from "../src/Assests/Completed/A10THAMRASERY/Thamrsey (16).jpeg"
import I26 from "../src/Assests/Completed/A10THAMRASERY/Thamrsey (17).jpeg"
import I27 from "../src/Assests/Completed/A10THAMRASERY/Thamrsey (18).jpeg"
import I28 from "../src/Assests/Completed/A10THAMRASERY/Thamrsey (19).jpeg"
import I29 from "../src/Assests/Completed/A10THAMRASERY/Thamrsey (20).jpeg"
import I30 from "../src/Assests/Completed/A10THAMRASERY/Thamrsey (21).jpeg"
import J1 from "../src/Assests/Completed/A12SIJUMON/020.jpg";
import J2 from "../src/Assests/Completed/A12SIJUMON/08.jpg";
import J3 from "../src/Assests/Completed/A12SIJUMON/09.jpg";
import J4 from "../src/Assests/Completed/A12SIJUMON/010.jpg";
import J5 from "../src/Assests/Completed/A12SIJUMON/011.jpg";
import J6 from "../src/Assests/Completed/A12SIJUMON/012.jpg";
import J7 from "../src/Assests/Completed/A12SIJUMON/013.jpg";
import J8 from "../src/Assests/Completed/A12SIJUMON/014.jpg";
import J9 from "../src/Assests/Completed/A12SIJUMON/015.jpg";
import J10 from "../src/Assests/Completed/A12SIJUMON/016.jpg";
import J11 from "../src/Assests/Completed/A12SIJUMON/017.jpg";
import J12 from "../src/Assests/Completed/A12SIJUMON/018.jpg";
import J13 from "../src/Assests/Completed/A12SIJUMON/019.jpg";
import J14 from "../src/Assests/Completed/A12SIJUMON/021.jpg";
import K1 from "../src/Assests/Completed/A13EMPIRE/Empire Cover.JPG";
import K2 from "../src/Assests/Completed/A13EMPIRE/Empire1.JPG";
import K3 from "../src/Assests/Completed/A13EMPIRE/Empire2.JPG";
import K4 from "../src/Assests/Completed/A13EMPIRE/Empire3.JPG";
import K5 from "../src/Assests/Completed/A13EMPIRE/Empire4.JPG";
import K6 from "../src/Assests/Completed/A13EMPIRE/Empire11.JPG";
import K7 from "../src/Assests/Completed/A13EMPIRE/Empire6.JPG";
import K8 from "../src/Assests/Completed/A13EMPIRE/Empire7.JPG";
import K9 from "../src/Assests/Completed/A13EMPIRE/Empire8.JPG";
import K10 from "../src/Assests/Completed/A13EMPIRE/Empire9.JPG";
import K11 from "../src/Assests/Completed/A13EMPIRE/Empire10.JPG";
import K12 from "../src/Assests/Completed/A13EMPIRE/Empire12.JPG";
import K13 from "../src/Assests/Completed/A13EMPIRE/Empire13.JPG";
import L1 from "../src/Assests/Completed/A14, Niramaya resort/FB_IMG_1546970180857.jpg";
import L2 from "../src/Assests/Completed/A14, Niramaya resort/FB_IMG_1548941639505.jpg";
import L3 from "../src/Assests/Completed/A14, Niramaya resort/FB_IMG_1548941645667.jpg";
import L4 from "../src/Assests/Completed/A14, Niramaya resort/FB_IMG_1548941650837.jpg";
import L5 from "../src/Assests/Completed/A14, Niramaya resort/FB_IMG_1548941655674.jpg";
import L6 from "../src/Assests/Completed/A14, Niramaya resort/FB_IMG_1548941663434.jpg";
import L7 from "../src/Assests/Completed/A14, Niramaya resort/FB_IMG_1549040053382.jpg";
import L8 from "../src/Assests/Completed/A14, Niramaya resort/FB_IMG_1549040606311.jpg";
import L9 from "../src/Assests/Completed/A14, Niramaya resort/FB_IMG_1549040608767.jpg";
import L10 from "../src/Assests/Completed/A14, Niramaya resort/FB_IMG_1549040611109.jpg";
import L11 from "../src/Assests/Completed/A14, Niramaya resort/FB_IMG_1549040654019.jpg";
import L12 from "../src/Assests/Completed/A14, Niramaya resort/FB_IMG_1549040659687.jpg";
import L13 from "../src/Assests/Completed/A14, Niramaya resort/FB_IMG_1549040778149.jpg";
import L14 from "../src/Assests/Completed/A14, Niramaya resort/FB_IMG_1549040766011.jpg";
import L15 from "../src/Assests/Completed/A14, Niramaya resort/FB_IMG_1549040749697.jpg";
import L16 from "../src/Assests/Completed/A14, Niramaya resort/FB_IMG_1549040741159.jpg";
import L17 from "../src/Assests/Completed/A14, Niramaya resort/FB_IMG_1549040730993.jpg";
import L18 from "../src/Assests/Completed/A14, Niramaya resort/FB_IMG_1549040722125.jpg";
import L19 from "../src/Assests/Completed/A14, Niramaya resort/FB_IMG_1549040718002.jpg";
import L20 from "../src/Assests/Completed/A14, Niramaya resort/FB_IMG_1549040706270.jpg";
import L21 from "../src/Assests/Completed/A14, Niramaya resort/FB_IMG_1549040700426.jpg";
import L22 from "../src/Assests/Completed/A14, Niramaya resort/FB_IMG_1549040684901.jpg";
import L23 from "../src/Assests/Completed/A14, Niramaya resort/FB_IMG_1549040672479.jpg";
import L24 from "../src/Assests/Completed/A14, Niramaya resort/FB_IMG_1549040667590.jpg";
import L25 from "../src/Assests/Completed/A14, Niramaya resort/FB_IMG_1549040662065.jpg";
import M1 from "../src/Assests/Completed/A15saji/saji1.JPG";
import M2 from "../src/Assests/Completed/A15saji/saji2.JPG";
import M3 from "../src/Assests/Completed/A15saji/saji3.JPG";
import N1 from "../src/Assests/Completed/A16,biju/biju1.JPG";
import N2 from "../src/Assests/Completed/A16,biju/biju2.JPG";
import N3 from "../src/Assests/Completed/A16,biju/biju3.JPG";
import N4 from "../src/Assests/Completed/A16,biju/biju4.JPG";
import N5 from "../src/Assests/Completed/A16,biju/biju5.JPG";
import N6 from "../src/Assests/Completed/A16,biju/biju6.JPG";
import O1 from "../src/Assests/Completed/A17, binoy/binoy1.JPG";
import O2 from "../src/Assests/Completed/A17, binoy/binoy2.JPG";
import O3 from "../src/Assests/Completed/A17, binoy/binoy3.JPG";
import O4 from "../src/Assests/Completed/A17, binoy/binoy4.JPG";
import O5 from "../src/Assests/Completed/A17, binoy/binoy5.JPG";
import O6 from "../src/Assests/Completed/A17, binoy/binoy6.JPG";
import O7 from "../src/Assests/Completed/A17, binoy/binoy7.JPG";
import O8 from "../src/Assests/Completed/A17, binoy/binoy8.JPG";
import O9 from "../src/Assests/Completed/A17, binoy/binoy9.JPG";
import P1 from "../src/Assests/Completed/A18,dr babu/babu1.jpg";
import P2 from "../src/Assests/Completed/A18,dr babu/babu2.jpg";
import P3 from "../src/Assests/Completed/A18,dr babu/babu3.jpg";
import P4 from "../src/Assests/Completed/A18,dr babu/babu4.jpg";
import P5 from "../src/Assests/Completed/A18,dr babu/babu5.jpg";
import P6 from "../src/Assests/Completed/A18,dr babu/babu6.jpg";
import Q1 from "../src/Assests/Completed/A19,metro complex/PHOTO-2024-03-16-16-01-01 (1).jpg";
import Q2 from "../src/Assests/Completed/A19,metro complex/PHOTO-2024-03-16-16-01-01 (2).jpg";
import Q3 from "../src/Assests/Completed/A19,metro complex/PHOTO-2024-03-16-16-01-01.jpg";
import R1 from "../src/Assests/Completed/A20, roy prestige/IMG_20190802_174140.jpg";
import R2 from "../src/Assests/Completed/A20, roy prestige/IMG_20190802_174144.jpg";
import R3 from "../src/Assests/Completed/A20, roy prestige/IMG_20190802_174652.jpg";
import R4 from "../src/Assests/Completed/A20, roy prestige/IMG_20190802_174802.jpg";
import R5 from "../src/Assests/Completed/A20, roy prestige/IMG_20190802_174815.jpg";
import R6 from "../src/Assests/Completed/A20, roy prestige/IMG_20190802_174821.jpg";
import R7 from "../src/Assests/Completed/A20, roy prestige/IMG_20190802_174850.jpg";
import R8 from "../src/Assests/Completed/A20, roy prestige/IMG_20190802_174853.jpg";
import R9 from "../src/Assests/Completed/A20, roy prestige/IMG_20190802_182918.jpg";
import R10 from "../src/Assests/Completed/A20, roy prestige/IMG_20190802_182924.jpg";
import R11 from "../src/Assests/Completed/A20, roy prestige/IMG_20190802_182956.jpg";
import R12 from "../src/Assests/Completed/A20, roy prestige/IMG_20190802_183028.jpg";
import S1 from "../src/Assests/Completed/A21shyam/shyam1.JPG";
import S2 from "../src/Assests/Completed/A21shyam/shyam2.JPG";
import S3 from "../src/Assests/Completed/A21shyam/shyam3.JPG";
import S4 from "../src/Assests/Completed/A21shyam/shyam4.JPG";
import S5 from "../src/Assests/Completed/A21shyam/shyam5.JPG";
import S6 from "../src/Assests/Completed/A21shyam/shyam6.JPG";
import S7 from "../src/Assests/Completed/A21shyam/shyam7.JPG";
import S8 from "../src/Assests/Completed/A21shyam/shyam8.JPG";
import S9 from "../src/Assests/Completed/A21shyam/shyam9.JPG";
import T1 from "../src/Assests/Completed/A22,mn sathhesh/PHOTO-2024-03-16-16-12-38.jpg";
import T2 from "../src/Assests/Completed/A22,mn sathhesh/PHOTO-2024-03-16-16-13-58.jpg";
import T3 from "../src/Assests/Completed/A22,mn sathhesh/PHOTO-2024-03-16-16-13-59.jpg";
import T4 from "../src/Assests/Completed/A22,mn sathhesh/PHOTO-2024-03-16-16-15-41.jpg";
import T5 from "../src/Assests/Completed/A22,mn sathhesh/PHOTO-2024-03-16-16-15-43.jpg";
import T6 from "../src/Assests/Completed/A22,mn sathhesh/PHOTO-2024-03-16-16-15-44 (1).jpg";
import T7 from "../src/Assests/Completed/A22,mn sathhesh/PHOTO-2024-03-16-16-15-44.jpg";

export const products = [
  {
    id: 1,
    src: A1,
    content1:"Location: ",
    content2:"Kolar",
    content3:"Area: 5143 sqft ",
    children: [
      { id: 11, src: A1 },
      { id: 22, src: A12 },
      { id: 16, src: A7 },
      { id: 12, src: A2 },
      { id: 13, src: A4 },
      { id: 14, src: A5 },
      { id: 15, src: A6 },
      { id: 17, src: A8 },
      { id: 18, src: A9 },
      { id: 20, src: A10 },
      { id: 21, src: A11 },
      { id: 23, src: A13 },
      { id: 24, src: A14 },
      { id: 25, src: A15 },
      { id: 27, src: A17 },
      { id: 28, src: A18 },
      { id: 29, src: A19 },
    ],
    link:"https://www.youtube.com/embed/6GX8RpyWbcg?si=xw12fUO9uulCcW-D "
  },
  {
    id: 2,
    src: B1,
    content1:"Location:",
    content2:"Electronic City ",
    content3:"Area: 10,000 sqft",
    children: [
      { id: 21, src: B1 },
      { id: 27, src: B7 },
      { id: 22, src: B2 },
      { id: 23, src: B3 },
      { id: 24, src: B4 },
      { id: 25, src: B5 },
      { id: 26, src: B6 },
      { id: 28, src: B8 },
      { id: 29, src: B9 },
    
    ],
    link:" "
  },
  {
    id: 3,
    src: C1,
    content1:"Location:",
    content2:"RT Nagar ",
    content3:"Area: 3000 sqft ",
    children: [
      {
        id: 21,
        src: C3,
      },
      {
        id: 22,
        src: C4,
      },
      {
        id: 23,
        src: C5,
      },
      {
        id: 24,
        src: C6,
      },
      {
        id: 25,
        src: C7,
      },
      // {
      //   id: 26,
      //   src: C8,
      // },
      {
        id: 27,
        src: C9,
      },
      {
        id: 28,
        src: C10,
      },
      {
        id: 29,
        src: C11,
      },
      {
        id: 30,
        src: C12,
      },
      {
        id: 30,
        src: C13,
      },
      {
        id: 30,
        src: C14,
      },
      {
        id: 30,
        src: C15,
      },
      {
        id: 30,
        src: C16,
      },
      {
        id: 30,
        src: C17,
      },
      {
        id: 30,
        src: C18,
      },
      {
        id: 30,
        src: C19,
      }
    ],
    link:"https://www.youtube.com/embed/UOacqe4mX-E?si=cgBFOsbL9MunrYLj"
  },
  {
    id: 4,
    src: D8,
    content1:"Location:",
    content2:"Yelahanka ",
    content3:"Area: 4000 sqft ",
    children: [
      {
        id: 41,
        src: D1,
      },
      {
        id: 42,
        src: D2,
      },
      {
        id: 43,
        src: D3,
      },
      {
        id: 44,
        src: D4,
      },
      {
        id: 41,
        src: D5,
      },
      {
        id: 42,
        src: D6,
      },
      {
        id: 43,
        src: D7,
      },
      {
        id: 44,
        src: D1,
      },
    ],
    link:"https://www.youtube.com/embed/8HvSoCAXcaI?si=MMxwIOjIFQyaFT_9"
  },
  {
    id: 5,
    src: E1,
    content1:"Location:",
    content2:"Chandra Layout ",
    content3:"Area: 4550 sqft ",
    children: [
      {
        id: 51,
        src: E2,
      },
      {
        id: 22,
        src: E3,
      },
      {
        id: 23,
        src: E4,
      },
      {
        id: 24,
        src: E5,
      },
      {
        id: 25,
        src: E6,
      },
      {
        id: 26,
        src: E7,
      },
      {
        id: 27,
        src: E8,
      },
      {
        id: 28,
        src: E9,
      },
      {
        id: 29,
        src: E10,
      },
      {
        id: 29,
        src: E11,
      },
      {
        id: 51,
        src: E12,
      },
      {
        id: 22,
        src: E13,
      },
      {
        id: 23,
        src: E14,
      },
      {
        id: 26,
        src: E17,
      },
      {
        id: 24,
        src: E15,
      },
      {
        id: 25,
        src: E16,
      }
    ],
    link:"https://www.youtube.com/embed/FxTylyDWmxI?si=kvJKEKBTYjWHMXgO"
  },
  {
    id: 25,
    src: U1,
    content1:"Location:",
    content2:"Akshaya Nagar ",
    content3:"Area: 5400 sqft ",
    children: [
      {
        id: 51,
        src: U2,
      },
      {
        id: 22,
        src: U3,
      },
      {
        id: 23,
        src: U4,
      },
      {
        id: 24,
        src: U5,
      },
      {
        id: 25,
        src: U6,
      },
      {
        id: 26,
        src: U7,
      },
      {
        id: 27,
        src: U8,
      },
      {
        id: 28,
        src: U9,
      },
      {
        id: 29,
        src: U10,
      },
      {
        id: 29,
        src: U11,
      },
      {
        id: 51,
        src: U12,
      },
      {
        id: 22,
        src: U13,
      },
      {
        id: 23,
        src: U14,
      }
    ],
    link:"https://www.youtube.com/embed/VJK4B3HvZsQ"
  },
  {
    id: 7,
    src: G1,
    content1:"Location:",
    content2:"Kalady ",
    content3:"Area: 13000 sqft ",
    children: [
      {
        id: 73,
        src: G14,
      },
      {
        id: 74,
        src: G15,
      },
      {
        id: 75,
        src: G16,
      },
      
      {
        id: 72,
        src: G3,
      },
      
      {
        id: 74,
        src: G5,
      },
      {
        id: 75,
        src: G6,
      },
     
      {
        id: 72,
        src: G8,
      },
      {
        id: 73,
        src: G9,
      },
      {
        id: 74,
        src: G10,
      },
      {
        id: 75,
        src: G11,
      },
      {
        id: 71,
        src: G12,
      },
      {
        id: 72,
        src: G13,
      },
      
      {
        id: 71,
        src: G17,
      },
      {
        id: 72,
        src: G18,
      },

      {
        id: 75,
        src: G21,
      },
    ],
    link:"https://www.youtube.com/embed/L6k6cpzUbGk?si=MeFc5H2vJ70f3ctM"
  },
  {
    id: 8,
    src: H1,
    content1:"Location:",
    content2:"Arekera ",
    content3:"Area: 3200 sqft ",
    children: [
      {
        id: 81,
        src: H2,
      },
      {
        id: 82,
        src: H3,
      },
      {
        id: 83,
        src: H4,
      },
      {
        id: 84,
        src: H5,
      },
    ],
    link:" "
  },
  {
    id: 9,
    src: I3,
    content1:"Location:",
    content2:"Thamarassery ",
    content3:"Area: 7500 sqft ",
    children: [
      {
        id: 91,
        src: I3,
      },
      {
        id: 92,
        src: I7,
      },
      {
        id: 93,
        src: I9,
      },
      {
        id: 94,
        src: I15,
      },
      {
        id: 95,
        src: I6,
      },
      {
        id: 96,
        src: I1,
      },
      {
        id: 97,
        src: I8,
      },
      {
        id: 91,
        src: I4,
      },
      {
        id: 92,
        src: I10,
      },
      {
        id: 93,
        src: I11,
      },
      {
        id: 94,
        src: I12,
      },
      {
        id: 95,
        src: I13,
      },
      {
        id: 97,
        src: I5,
      },
      {
        id: 93,
        src: I16,
      },
      {
        id: 94,
        src: I17,
      },
      {
        id: 95,
        src: I18,
      },
      {
        id: 96,
        src: I19,
      },
      {
        id: 97,
        src: I20,
      },
      {
        id: 95,
        src: I21,
      },
      {
        id: 96,
        src: I22,
      },
      {
        id: 97,
        src: I23,
      },
      {
        id: 94,
        src: I24,
      },
      {
        id: 95,
        src: I25,
      },
      {
        id: 96,
        src: I26,
      },
      {
        id: 97,
        src: I27,
      },
      {
        id: 95,
        src: I28,
      },
      {
        id: 96,
        src: I29,
      },
      {
        id: 97,
        src: I20,
      }
    ],
    link:" "
  },
  {
    id: 10,
    src: J1,
    content1:"Location:",
    content2:"Kalady ",
    content3:"Area: 13000 sqft ",
    children: [
      {
        id: 91,
        src: J2,
      },
      {
        id: 92,
        src: J3,
      },
      {
        id: 93,
        src: J4,
      },
      {
        id: 94,
        src: J5,
      },
      {
        id: 95,
        src: J6,
      },
      {
        id: 96,
        src: J7,
      },
      {
        id: 97,
        src: J8,
      },
      {
        id: 98,
        src: J9,
      },
      {
        id: 99,
        src: J10,
      },
      {
        id: 100,
        src: J11,
      },
    ],
    link:"https://www.youtube.com/embed/L6k6cpzUbGk?si=MeFc5H2vJ70f3ctM0"
  },
  {
    id: 14,
    src: K1,
    content1:"Location:",
    content2:" Channapatna ",
    content3:"Area: 22000 sqft ",
    children: [
      {
        id: 91,
        src: K2,
      },
      {
        id: 92,
        src: K3,
      },
      {
        id: 93,
        src: K4,
      },
      {
        id: 94,
        src: K5,
      },
      {
        id: 95,
        src: K6,
      },
      {
        id: 96,
        src: K7,
      },
      {
        id: 97,
        src: K8,
      },
      {
        id: 98,
        src: K9,
      },
      {
        id: 99,
        src: K10,
      },
      {
        id: 100,
        src: K11,
      },
      {
        id: 100,
        src: K12,
      },
      {
        id: 100,
        src: K13,
      },
    ],
    link:"https://www.youtube.com/embed/XaDLjx9YPw4"
  },
  {
    id: 15,
    src: L25,
    content1:"Location:",
    content2:"Kerala ",
    content3:"Area: 5 Acres",
    children: [
      {
        id: 91,
        src: L2,
      },
      {
        id: 92,
        src: L3,
      },
      {
        id: 93,
        src: L4,
      },
      {
        id: 94,
        src: L5,
      },
      {
        id: 95,
        src: L6,
      },
      {
        id: 96,
        src: L7,
      },
      {
        id: 97,
        src: L8,
      },
      {
        id: 98,
        src: L9,
      },
      {
        id: 99,
        src: L10,
      },
      {
        id: 100,
        src: L11,
      },
      {
        id: 100,
        src: L12,
      },
      {
        id: 100,
        src: L13,
      },
      {
        id: 91,
        src: L14,
      },
      {
        id: 92,
        src: L15,
      },
      {
        id: 93,
        src: L16,
      },
      {
        id: 94,
        src: L17,
      },
      {
        id: 95,
        src: L18,
      },
      {
        id: 96,
        src: L19,
      },
      {
        id: 97,
        src: L20,
      },
      {
        id: 98,
        src: L21,
      },
      {
        id: 99,
        src: L22,
      },
      {
        id: 100,
        src: L23,
      },
      {
        id: 100,
        src: L24,
      },
      {
        id: 100,
        src: L25,
      },
    ],
    link:" "
  },
  {
    id: 6,
    src: F1,
    content1:"Location:",
    content2:"Akshaya Nagar ",
    content3:"Area: 5400 sqft ",
    children: [
      {
        id: 61,
        src: F1,
      },
      {
        id: 62,
        src: F2,
      },
      {
        id: 63,
        src: F3,
      },
      {
        id: 64,
        src: F4},
      {
        id: 65,
        src: F5,
      },
      {
        id: 66,
        src: F6,
      },
      {
        id: 61,
        src: F7,
      },
      {
        id: 62,
        src: F8,
      },
      {
        id: 63,
        src: F9,
      },
      {
        id: 64,
        src: F10,
      },
      {
        id: 65,
        src: F11,
      },
      {
        id: 66,
        src: F12,
      }
      ,
      {
        id: 65,
        src: F13,
      },
      {
        id: 66,
        src: F14,
      },
      {
        id: 61,
        src: F15,
      },
      {
        id: 62,
        src: F16,
      },
      {
        id: 63,
        src: F17,
      },
      {
        id: 64,
        src: F18,
      },
      {
        id: 65,
        src: F19,
      }
    ],
    link:"https://www.youtube.com/embed/niLJksp28qs"
  },
  {
    id:24  ,
    src: G31,
    content1:"Location:",
    content2:"Kalady ",
    content3:"Area: 13000 sqft ",
    children: [
      { id: 21, src: G31 },
      { id: 22, src: G32 },
      { id: 23, src: G33 },
      { id: 24, src: G34 },
      { id: 25, src: G35 },
      { id: 26, src: G36 },
      { id: 27, src: G37 },
      { id: 28, src: G38 },
      { id: 29, src: G39 },
      { id: 30, src: G40 },
      { id: 31, src: G42 },
      { id: 32, src: G43 },
      { id: 33, src: G44 },
  G1
    ],
    link:" "
  },
  {
    id: 16,
    src: M3,
    content1:"Location:",
    content2:"Aluva ",
    content3:"Area: 3000 sqft ",
    children: [
      {
        id: 91,
        src: M1,
      },
      {
        id: 92,
        src: M2,
      },
      {
        id: 93,
        src: M2,
      },
    ],
    link:"  "
  },
  {
    id: 17,
    src: N2,
    content1:"Location:",
    content2:"Angamali ",
    content3:"Area: 5550 sqft ",
    children: [
      {
        id: 91,
        src: N4,
      },
      {
        id: 92,
        src: N3,
      },
      {
        id: 93,
        src: N1,
      },
      {
        id: 94,
        src: N5,
      },
      {
        id: 95,
        src: N6,
      },
    ],
    link:" "
  },
  {
    id: 18,
    src: O7,
    content1:"Location:",
    content2:"Angamali ",
    content3:"Area: 5500 sqft ",
    children: [
      {
        id: 91,
        src: O2,
      },
      {
        id: 92,
        src: O3,
      },
      {
        id: 93,
        src: O4,
      },
      {
        id: 94,
        src: O5,
      },
      {
        id: 95,
        src: O6,
      },
      {
        id: 96,
        src: O1,
      },
      {
        id: 97,
        src: O8,
      },
      {
        id: 98,
        src: O9,
      },
    ],
    link:" "
  },
  {
    id: 19,
    src: P1,
    content1:"Location:",
    content2:"Coachin ",
    content3:"Area: 6000 sqft",
    children: [
      {
        id: 91,
        src: P2,
      },
      {
        id: 92,
        src: P3,
      },
      {
        id: 93,
        src: P4,
      },
      {
        id: 94,
        src: P5,
      },
      {
        id: 95,
        src: P6,
      },
    ],
    link:" "
  },
  {
    id: 20,
    src: Q1,
    content1:"Location:",
    content2:"Kalady ",
    content3:"Area:15000 sqft ",
    children: [
      {
        id: 91,
        src: Q2,
      },
      {
        id: 92,
        src: Q3,
      },
      {
        id: 93,
        src: Q1,
      },
    ],
    link:" "
  },
  {
    id: 21,
    src: R1,
    content1:"Location:",
    content2:"Varthoor",
    content3:"Area: 1800 sqft ",
    children: [
      {
        id: 91,
        src: R2,
      },
      {
        id: 92,
        src: R3,
      },
      {
        id: 93,
        src: R4,
      },
      {
        id: 94,
        src: R5,
      },
      {
        id: 95,
        src: R6,
      },
      {
        id: 96,
        src: R7,
      },
      {
        id: 97,
        src: R8,
      },
      {
        id: 98,
        src: R9,
      },
    ],
    link:" "
  },
  
  {
    id: 22,
    src: S1,
    content1:"Location:",
    content2:"Thodupuzha ",
    content3:"Area: 3000 sqft ",
    children: [
      {
        id: 91,
        src: S2,
      },
      {
        id: 92,
        src: S3,
      },
      {
        id: 93,
        src: S4,
      },
      {
        id: 94,
        src: S5,
      },
      {
        id: 95,
        src: S6,
      },
      {
        id: 96,
        src: S7,
      },
      {
        id: 97,
        src: S8,
      },
      {
        id: 98,
        src: S9,
      },
    ],
    link:" "
  },
  {
    id: 23,
    src: T1,
    content1:"Location:",
    content2:"RR Nagar ",
    content3:"Area: 3000 sqft ",
    children: [
      {
        id: 91,
        src: T2,
      },
      {
        id: 92,
        src: T3,
      },
      {
        id: 93,
        src: T4,
      },
      {
        id: 94,
        src: T5,
      },
      {
        id: 95,
        src: T6,
      },
      {
        id: 96,
        src: T7,
      },
    ],
    link:" "
  },
];
