import React from "react";
import "./Projects.css";
import Dprojects from "./Dprojects";
import { Link } from "react-router-dom";

const Projects = () => {
  return (
    <div>
      {/* <Dprojects/> */}
      <div>
        <div className="projects-option-div">
          <div>
            <p>
              <Link to="/3dReferenceProjects">3D Reference Projects</Link>
            </p>
          </div>
          <div>
            <p>
              <Link to="/ongoingProjects">Ongoing Projects</Link>
            </p>
          </div>
          <div>
            <p>
              <Link to="/completedProjects">Completed Projects</Link>
            </p>
          </div>
          <div>
            <p>
            <Link to="/CommercialProjects">Commercial Projects</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Projects;
