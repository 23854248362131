import React from 'react'
import "./Error.css"
const Error = () => {
  return (
    <div>
      <div className='error-div'>
        <div className='error-sub-div'>
            <h1>Error 404!!</h1>
            <h1>Page Not Found.</h1>
        </div>
      </div>
    </div>
  )
}

export default Error
