import React from 'react'
import './Workcard.css'
import Experience from './Experience'
import cards from "../../Assests/How We Work1.jpg"

const WorkCard = () => {
  return (
    <div>
      <div className='work-card'>
      <img src={cards} alt='How this archticture and designing firm in banglore works'/>
      </div>
     <div>
     </div>
  
    </div>
  )
}

export default WorkCard
