import React from "react";
import "./CompletedProjects.css";
import video2 from "../../Assests/3DProjects/completedProject2.mp4";
import { Link } from "react-router-dom";

import completedCover from "../../Assests/Completed/CompltedCover.jpg"
import completedphoneCover from "../../Assests/Completed/completedphonecover.jpg"

const CompletdProjects = ({ products }) => {
  console.log(products);
  return (
    <div className="MainCompletedProjects">
      <div>
        <div>
          <div className="main-complete">
            <img src={completedCover}></img>
          </div>
          <div className="video-container1">
            <img src={completedphoneCover} alt="kk" />
          </div>
       
          <div>
            <div
              style={{
                margin: "20px",
                display: "flex",
                justifyContent: "center",
                flexDirection: "row",
                flexWrap: "wrap",
                gap: "20px",
              }}
            >
              {products.map((product) => (
                <div key={product.id} className="image1">
                  <Link to={`/product/${product.id}`}>
                    <img
                      src={product.src}
                      alt={`Product ${product.id}`}
                      style={{
                        width: "350px",
                        height: "250px",
                        objectFit: "cover",
                        borderRadius: "20px",
                        boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                      }}
                    />
                     <div className="content">
              <h2>{product.content1}  <span>{product.content2}</span></h2>
              <h3   style={{
                       margin:"0px 0px 0px 20px"
                      }}>{product.content3} 
              </h3>
            </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CompletdProjects;
