import React from "react";
import "./Careers.css";
import carrerimage1 from "../../Assests/Careerimg.jpeg";
import office1 from "../../Assests/careerPage/company (12).jpeg";
import office2 from "../../Assests/careerPage/company (14).jpeg";
import office3 from "../../Assests/careerPage/company (16).jpeg";
import office4 from "../../Assests/careerPage/company (18).jpeg";
import CallIcon from "@mui/icons-material/Call";
import MailIcon from "@mui/icons-material/Mail";

const Careers = () => {
  return (
    <div>
      <div>
        <div className="carrerimage1">
          <img src={carrerimage1} />
        </div>
        <div className="career-tag">
          <h1>Join Our Team At CBE Design Studio </h1>
         
          <h2>
           " Help us shape spaces, and we'll help you shape yourself "
          </h2>
        </div>
        <div className="perksOfWorking-main">
          <div className="perksOfWorking">
            <div className="perksOfWorking-sub1">
              <h2>
                Perks of <span>working with us</span>
              </h2>
            </div>
            <div>
              <ul>
                <li>Fast progressing company.</li>
                <li>Work with creative minds. </li>
                <li>Be a part of our beautiful work space.</li>
                <li>
                  Committed to using sustainable materials and cutting-edge
                  technology in our designs.
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="ourOffice">
          <h2>
            Our <span>Office</span>
          </h2>
        </div>
        <div className="officepics1">
          <div className="pic1">
            <img src={office4} />
          </div>
          <div className="pic2">
            {" "}
            <img src={office2} />
          </div>
        </div>
        <div className="officepics1">
          <div className="pic2">
            {" "}
            <img src={office3} />
          </div>
          <div className="pic1">
            <img src={office1} />
          </div>
        </div>
        <div className="ourOffice">
          <h2>
            Open <span>Positions</span>
          </h2>
        </div>
        <div className="main-vacancy-div">
          {jobvacancies.map((vacancy) => (
            <div className="vacancy-div">
              <h1>{vacancy.title}</h1>
              <div className="experience-button">
                <div>
                  <h4>{vacancy.experiance}</h4>
                  <h4>{vacancy.Qualification}</h4>
                  <h4>{vacancy.Language}</h4>
                </div>
                <div>
                  <a href="https://forms.gle/9cQkYqqgBVowVoxv8" target="blank">
                    {" "}
                    <button> Apply Now</button>
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div className="contact-us-banner-main">
          <div className="contact-us-banner-sub">
            <div>
              <h2> Contact us for more job related querries</h2>
            </div>
            <div
              style={{
                display: "flex",
                gap: "10px",
                width:"250px",
                margin:"15px"
              }}
            >
              <div>
                <CallIcon />
              </div>
              <div><b>+91 8660769235</b></div>
            </div>
            <div
              style={{
                display: "flex",
                gap: "10px",
                width:"250px",
               
              
              }}
            >
              <div>
                <MailIcon />
              </div>
              <div><b>cbedesignstudio@gmail.com</b></div>
            </div>
          </div>
        </div>
        <div className="sucess-at">
          <h2>
            Sucess At <span>CBE Design Studio</span>
          </h2>
          <ul>
            <li>
              <b>Innovation: </b> At CBE Design Studio, we thrive on innovation.
              We foster a culture where every idea is valued, encouraging each
              team member to contribute their unique perspective to our
              projects.
            </li>
            <li>
              <b>Continuous Growth: </b> We believe in the power of lifelong
              learning. Our team is encouraged to pursue knowledge passionately,
              continuously expanding our skills and expertise to deliver
              cutting-edge solutions to our clients.
            </li>
            <li>
              <b>Excellence in Service: </b>We are committed to excellence in
              every aspect of our work - from concept to delivery. Quality is at
              the forefront of everything we do, ensuring that our clients
              receive nothing but the best.
            </li>
            <li>
              <b>Individual Excellence: </b>We recognize the value of each team
              member. We seek individuals who embody the highest standards of
              excellence and personal integrity, empowering them to leverage
              their unique talents to advance our firm's mission.
            </li>
            <li>
              <b>Collaborative Teamwork: </b> Collaboration is the cornerstone
              of our success. We believe in the power of interdisciplinary
              integration, fostering a positive team culture where open
              collaboration drives us towards our shared goals.
            </li>
            <li>
              <b>Innovative Solutions: </b> CBE Design Studio is dedicated to
              pushing the boundaries of architectural design. We are constantly
              exploring new approaches, solutions, and processes to deliver
              innovative and sustainable designs that make a positive impact on
              our communities.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Careers;
const jobvacancies = [
  {
    id: 1,
    title: "FRONT DESK RECEPTIONIST",
    experiance: "Experience : 1-2 years",
    Qualification: "Graduation : Any Field ",
    Language: "Prefferd Language :  Kannada, English, Hindi",
  },
  {
    id: 2,
    title: "JUNIOR ARCHITECT",
    experiance: "Experience : 1-2 years",
    Qualification: "Graduation : B.Arch (Compulsory)",
    Language: "Prefferd Language :  Kannada, English, Hindi",
  },
  {
    id: 3,
    title: "SITE ENGINEER",
    experiance: "Experience : 1-2 years",
    Qualification: "Graduation : B.E/B.Tech (Civil) ",
    Language: "Prefferd Language : Kannada, English, Hindi",
  },
  {
    id: 3,
    title: "SALES EXECUTIVE",
    experiance: "Experience : 1-2 years",
    Qualification: "Graduation : Any Field ",
    Language: "Prefferd Language : Kannada, English, Hindi",
  },
];
