import React from "react";
import "./Experience.css";
import testimonial3 from "../../Assests/Testimonials/RajesSir.jpeg";
import testimonialimage2 from "../../Assests/Testimonials/testimonial2.jpeg";
import testimonialimage1 from "../../Assests/Testimonials/Lohit.png";
import Testiomonial1 from "../../Assests/Testimonials/testimonial1.mp4";
const Experience = () => {
  return (
    <div>
      <div className="testimonial-1-1">
        <div>
          <h1>Client <span>Testimonials</span></h1>
        </div>
        <div className="testimonial-1">
          <p>
            Success to us is a happy customer. Which means we strive to think
            outside the box and exceed expectations. At the end of the day,
            building a house is like an investment on yourself. The space you
            put yourself in could very much impact moods, give you a sense of
            motivation and comfort. As one of the best interior designing companies in Bangalore, 
            we understand that more than anyone, which
            is why, we would like to give you a glimpse of happiness among a few
            of our clients. Testimonials speak volumes about our commitment to
            excellence, attention to detail, and seamless project management.
            Experience joy through our clientele!
          </p>
        </div>
        <div className="testimonial-images">
  

          
          <div>
            <div className="testimonial-2">
              <div>
              <iframe  src="https://www.youtube.com/embed/lbBhvN832P8" title="CBE Design studio " frameborder="0" allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
              </div>
              <div>
                <b>
                  <u>Dr.Lohit & Family</u>
                </b>
              </div>
              <div>
                <p>
                  
                    " We are absolutely delighted with the work done by CBE Design Studio.
                     My wife is particularly enamored with the fluidic design of our home. It creates
                     such a smooth and harmonious flow throughout the space, making it both beautiful and
                     functional. Our son is over the moon with the home theatre. It has become his favorite 
                     spot to unwind and enjoy his favorite movies and games.From my perspective, 
                     what truly stood out was the way CBE Design Studio handled the entire project from 
                     start to finish. They made us feel comfortable and valued our input every step of the
                     way. Their professional approach and willingness to incorporate our suggestions made
                     the process seamless and enjoyable. Thank you, CBE Design Studio, for crafting a home
                     that perfectly meets our family's needs and desires "
              
                </p>
                
              </div>
            </div>
          </div>

          <div className="testimonial-2">
            <div>
              <img src={testimonial3} />
            </div>
            <div>
              <b>
                <u>Mr. Rajesh & Family</u>
              </b>
            </div>
            <div>
              <p>
               
                  " We couldn't be happier with our new home designed by CBE Design Studio.
                   My wife, Manjusree, absolutely loves the glass kitchen. It's not only stunning 
                   but also incredibly easy to clean and maintain, making her daily routine much 
                   smoother. Our son, Karthik, is thrilled with the terrace, which doubles as a 
                   gym and garden. It's his favorite spot in the house, where he can exercise and 
                   enjoy the greenery simultaneously. As for me, I am particularly impressed with the 
                   elegant use of white throughout the house. It gives our home a clean, modern look 
                   that I find very appealing. Thank you, CBE Design Studio, for creating a space that
                    perfectly suits our family's needs and aesthetic preferences."
            
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Experience;
