import React from "react";
import { useParams } from "react-router-dom";
import ProjectHeading from "./ProjectHeading";

const OngoinDetails = ({ Ongoing }) => {
    const { id } = useParams();
    const Ongoings = Ongoing.find((p) => p.id === parseInt(id));
  
    if (!Ongoings) {
      return <div>Product not found</div>;
    }
  {console.log()}
    return (
      <div>
      <ProjectHeading/>
        <img
          src={Ongoings.src}
          alt={`Ongoing ${Ongoings.id}`}
          style={{ width: "100%", height: "400px", objectFit: "cover" }}
        />
      
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "wrap",
            gap: "20px",
              justifyContent: "center",
              marginTop: "20px",
          }}
        >
          {Ongoings.children.map((child) => (
            <img
              key={child.id}
              src={child.src}
              alt={`Product ${child.id}`}
              style={{
              
                width: "350px",
              
               
                objectFit: "cover",
                borderRadius: "10px",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
              }}
            />
          ))}
        </div>
      </div>
    );
  };

export default OngoinDetails
