import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import Sidebar from "./Sidebar";
import logo from "../../Assests/CBE Logo 1.png";
import bgimg from "../../Assests/Screenshot 2024-02-15 162423.png";
import { FiAlignJustify } from "react-icons/fi";
import { Box } from "@mui/material";

const Navbar = () => {
  const [display, setDisplay] = useState(false);
  const [isSticky, setSticky] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const offset = window.scrollY;
      if (offset > 100) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  function display1() {
    setDisplay(!display);
  }

  return (
    <div className={`navbar ${isSticky ? "sticky" : ""}`}>
      <div className="nav-div">
        <div className="Logo-div">
          <Link to="/">
            <img src={logo} alt="" />
          </Link>
        </div>
        <div className="link-div">
          <ul>
            <li>
              <Link to="/">Home</Link>
            </li>
            <li>
              <Link to="/AboutUs">About Us</Link>
            </li>
            <li>
              <div class="dropdown-container">
                <div class="dropdown-button">Projects</div>
                <div class="dropdown-content">
                <p>
                <Link to="/3dReferenceProjects" >3D Reference Projects</Link>
                </p>
                <p>
                <Link to="/ongoingProjects" >Ongoing Projects</Link>
                </p>
                <p>
                 <Link to="/completedProjects">Completed Projects</Link>
                </p>
                <p>
                 <Link to="/CommercialProjects">Commercial Projects</Link>
                </p>

                </div>
              </div>
            </li>
            <li>
              <Link to="/contact">Contact Us</Link>
            </li>
            <li>
              <Link to="/careers">Careers</Link>
            </li>
            <li>
              <Link to="/inspiration">Inspiration</Link>
            </li>
            {/* <li>
              <Link to="/Publication">Publications</Link>
            </li> */}
          </ul>
        </div>
        <div>
          <Box
            sx={{
              display: {
                xs: "flex",
                sm: "none",
                md: "none",
              },
              margin: 2,
            }}
          >
            <Sidebar />
          </Box>
        </div>
       
      </div>
     
    </div>
  );
};

export default Navbar;
