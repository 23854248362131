import React, { useState } from "react";
import { useParams } from "react-router-dom";
import ProjectHeading from "./ProjectHeading";
import "./Ddetails.css";

const Ddetails = ({ details }) => {
  const [data, setData] = useState({ img: "", i: 0 });

  const viewImage = (img, i) => {
    setData({ img: img.src, i: i });
  };

  const { id } = useParams();
  const detail = details.find((p) => p.id === parseInt(id));
  const imgAction = (action) => {
    let i = data.i;
    const a = detail.children.length;
    if (i > 0 && i < a - 1) {
      if (action === "next-img") {
        console.log(i, "this is index");
        console.log(detail.children[i + 1].src);
        setData({ img: detail.children[i + 1].src, i: i + 1 });
      }
      if (action === "prev-img") {
        console.log(i, "this is index");
        setData({ img: detail.children[i - 1].src, i: i - 1 });
      }
      if (!action) {
        setData({ img: "", i: 0 });
      }
    } else {
      setData({ img: "", i: 0 });
    }
  };
  if (!detail) {
    return <div>Product not found</div>;
  }

  return (
    <div>
      {/* <ProjectHeading /> */}
      {data.img && (
        <div className="image-gallery">
          <button className="gallery-button"
            onClick={() => imgAction()}>
            X
          </button>
          <button
            onClick={() => imgAction("prev-img")}
            style={{
              backgroundColor: "black",
              border: "2px solid black ",
              color: "white",
            }}
          >
            <h2
              style={{
                padding: "10px",
                fontSize: "50px",
                backgroundColor: "black",
                color: "white",
                cursor: "pointer",
              }}
            >
              &lt;
            </h2>
          </button>
          <img
            src={data.img}
            style={{
              width: "auto",
              maxWidth: "90%",
              maxHeight: "90%",
            }}
          />
          <button
            onClick={() => imgAction("next-img")}
            style={{
              backgroundColor: "black",
              color: "white",
              cursor: "pointer",
            }}
          >
            <h2
              style={{
                padding: "10px",
                fontSize: "50px",
                backgroundColor: "black",
                color: "white",
              }}
            >
              &gt;
            </h2>
          </button>
        </div>
      )}
      <div className="project-main-image">
        <div>
          <img
            src={detail.src}
            alt={`Product ${detail.id}`}
            style={{
              width: "100%",
              textAlign: "center",
              objectFit: "cover",
              position: "relative",
            }}
          />
        </div>
        <div className="DprojectStrip"> 
        <h2>{detail.content1} <span>{detail.content2}</span></h2>
        <br></br>
        <p>{detail.content3}</p>
        </div>
      </div>

      <div
        style={{
          backgroundSize: "cover",
          display: "flex",
          flexDirection: "row",
          flexWrap: "wrap",
          gap: "20px",
          justifyContent: "center",
          marginTop: "20px",
        }}
      >
        {detail.children.map((child, i) => (
          <img
            key={child.id}
            src={child.src}
            onClick={() => viewImage(child, i)}
            alt={`Product ${child.id}`}
            style={{
              // Adjust as needed,
              width: "350px",
              objectFit: "cover",
              borderRadius: "10px",
              cursor: "pointer",
              boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default Ddetails;
