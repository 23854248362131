import ArunVan from "../src/Assests/3DProjects/Compressed Images/1,Arjun Vandhana -min.jpg";
import kasarkode from "../src/Assests/3DProjects/3 kasarkode reception view3.jpg";
import arunModel from "../src/Assests/3DProjects/Compressed Images/arun model 2 view1-min.jpg";
import aneesh from "../src/Assests/3DProjects/Compressed Images/aneesh house view 1 -min.jpg";
import biju from "../src/Assests/3DProjects/Compressed Images/biju-min.JPG";
import bineesh from "../src/Assests/3DProjects/bineesh view 1.jpg";
import binjo from "../src/Assests/3DProjects/Compressed Images/Binjo house view2-min.jpg";
import bobby from "../src/Assests/3DProjects/boby.jpg";
import lohit from "../src/Assests/3DProjects/Dr. Lohit exterior new view (1).jpg";
import gopi from "../src/Assests/3DProjects/gopi house front view copy.jpg";
import kiran1 from "../src/Assests/3DProjects/kiran hegde14.JPG";
import kiran2 from "../src/Assests/3DProjects/Compressed Images/kiran hegde18-min.jpg";
import muhammad from "../src/Assests/3DProjects/muhammad house view.jpg";
import nazeeba from "../src/Assests/3DProjects/Compressed Images/Nazeeba house view2-min.jpg";
import nazeeba2 from "../src/Assests/3DProjects/Children/A25 Nazeeba/Nazeeba (2).jpeg"
import nazeeba3 from "../src/Assests/3DProjects/Children/A25 Nazeeba/Nazeeba (3).jpeg"
import nazeeba4 from "../src/Assests/3DProjects/Children/A25 Nazeeba/Nazeeba (4).jpeg"
import nazeeba5 from "../src/Assests/3DProjects/Children/A25 Nazeeba/Nazeeba (5).jpeg"
import nazeeba6 from "../src/Assests/3DProjects/Children/A25 Nazeeba/Nazeeba (6).jpeg"
import nazeeba7 from "../src/Assests/3DProjects/Children/A25 Nazeeba/Nazeeba (7).jpeg"
import nazeeba8 from "../src/Assests/3DProjects/Children/A25 Nazeeba/Nazeeba (8).jpeg"
import nazeeba9 from "../src/Assests/3DProjects/Children/A25 Nazeeba/Nazeeba (9).jpeg"
import nazeeba10 from "../src/Assests/3DProjects/Children/A25 Nazeeba/Nazeeba (10).jpeg"
import nazeeba11 from "../src/Assests/3DProjects/Children/A25 Nazeeba/Nazeeba (11).jpeg"
import continental from "../src/Assests/3DProjects/Continental.jpg";
import santosh from "../src/Assests/3DProjects/Santhosh interior view6.jpg";
import rasheed from "../src/Assests/3DProjects/rashid.jpg";
import sherly from "../src/Assests/3DProjects/Shaji and sherly.jpg";
import sanal from "../src/Assests/3DProjects/sanal traditional.jpg";
import siddhique1 from "../src/Assests/3DProjects/siddique25.jpg";
import siddhique2 from "../src/Assests/3DProjects/Compressed Images/siddhique26-min.jpg";
import sunil1 from "../src/Assests/3DProjects/sunil27.jpg";
import sunil2 from "../src/Assests/3DProjects/Compressed Images/sunil27-min.jpg";
import ajit from "../src/Assests/3DProjects/ajith.jpg";
import narsimha from "../src/Assests/3DProjects/Narsimha.jpg";
import magan from "../src/Assests/3DProjects/Compressed Images/mathan-min.jpg";
import sadat from "../src/Assests/3DProjects/sadat.JPG";
import Sujumon1 from "../src/Assests/3DProjects/Sujumon.JPG";
import Sujumon2 from "../src/Assests/3DProjects/sujomon2.JPG";
import SkReddy from "../src/Assests/3DProjects/Sk Reddy house exterior view2.jpg";
import sadat1 from "../src/Assests/3DProjects/Children/A1SADAT/1 Sadath Khan living view.jpg";
import sadat2 from "../src/Assests/3DProjects/Children/A1SADAT/10 Sadath Khan Mbed view(1st floor).jpg";
import sadat3 from "../src/Assests/3DProjects/Children/A1SADAT/13 Sadath Khan bed2 view(1st floor).jpg";
import sadat4 from "../src/Assests/3DProjects/Children/A1SADAT/15 Sadath Khan bed2 view(1st floor).jpg";
import sadat5 from "../src/Assests/3DProjects/Children/A1SADAT/17 Sadath Khan bed3 view(1st floor).jpg";
import sadat6 from "../src/Assests/3DProjects/Children/A1SADAT/19 Sadath Khan Mbed view(2nd floor).jpg";
import sadat7 from "../src/Assests/3DProjects/Children/A1SADAT/2 Sadath Khan living view.jpg";
import sadat8 from "../src/Assests/3DProjects/Children/A1SADAT/3 Sadath Khan stair view.jpg";
import sadat9 from "../src/Assests/3DProjects/Children/A1SADAT/4 Sadath Khan family living view.jpg";
import sadat10 from "../src/Assests/3DProjects/Children/A1SADAT/5 Sadath Khan family living view.jpg";
import sadat11 from "../src/Assests/3DProjects/Children/A1SADAT/7 Sadath Khan dinning view.jpg";
import sk1 from "../src/Assests/3DProjects/Children/A2SKREDDY/11,SKR family living view2.jpg";
import sk2 from "../src/Assests/3DProjects/Children/A2SKREDDY/16,SKR upper living view4.jpg";
import sk3 from "../src/Assests/3DProjects/Children/A2SKREDDY/18,SKR Masterbed view1.jpg";
import sk4 from "../src/Assests/3DProjects/Children/A2SKREDDY/19,SKR Masterbed view2.jpg";
import sk5 from "../src/Assests/3DProjects/Children/A2SKREDDY/21,SKR Masterbed view6.jpg";
import sk6 from "../src/Assests/3DProjects/Children/A2SKREDDY/23.SKR Masterbed view5.jpg";
import sk7 from "../src/Assests/3DProjects/Children/A2SKREDDY/24,SKR 3rd floor bed view1.jpg";
import sk8 from "../src/Assests/3DProjects/Children/A2SKREDDY/26,SKR 3rd floor bed view2.jpg";
import sk9 from "../src/Assests/3DProjects/Children/A2SKREDDY/7,SKR stair view.jpg";
import sk10 from "../src/Assests/3DProjects/Children/A2SKREDDY/SKR dinning view2.jpg";
import sk11 from "../src/Assests/3DProjects/Children/A2SKREDDY/SKR living view2.jpg";
import sk12 from "../src/Assests/3DProjects/Children/A2SKREDDY/13.SKR upper living view1.jpg";
import DL1 from "../src/Assests/3DProjects/Children/A3LOHIT/Dr. Lohit Masterbed view1.jpeg";
import DL2 from "../src/Assests/3DProjects/Children/A3LOHIT/Dr. Lohit Masterbed view1.jpeg";
import DL3 from "../src/Assests/3DProjects/Children/A3LOHIT/Dr. Lohith bed view1.jpg";
import DL4 from "../src/Assests/3DProjects/Children/A3LOHIT/Dr.Lohith Guest bed view1.jpg";
import DL5 from "../src/Assests/3DProjects/Children/A3LOHIT/Dr.Lohith Guest bed view3.jpg";
import DL6 from "../src/Assests/3DProjects/Children/A3LOHIT/Dr.Lohith upper living view1.jpg";
import DL7 from "../src/Assests/3DProjects/Children/A3LOHIT/Dr.Lohith upper living view2.jpg";
import DL8 from "../src/Assests/3DProjects/Children/A3LOHIT/Dr.Lothith view2.jpg";
import DL9 from "../src/Assests/3DProjects/Children/A3LOHIT/Dr.Lothith view6.jpg";
import DL10 from "../src/Assests/3DProjects/Children/A3LOHIT/Kids bed view1.jpg";
import DL11 from "../src/Assests/3DProjects/Children/A3LOHIT/Masterbed view1.jpg";
import DL12 from "../src/Assests/3DProjects/Children/A3LOHIT/Masterbed view3.jpg";
import DL13 from "../src/Assests/3DProjects/Children/A3LOHIT/home theatre view1.jpg";
import DL14 from "../src/Assests/3DProjects/Children/A3LOHIT/parents bed view3.jpg";
import suju1 from "../src/Assests/3DProjects/Children/A4SUJUMON1/suju1.JPG";
import suju2 from "../src/Assests/3DProjects/Children/A4SUJUMON1/suju2.JPG";
import suju3 from "../src/Assests/3DProjects/Children/A4SUJUMON1/suju3.JPG";
import suju4 from "../src/Assests/3DProjects/Children/A4SUJUMON1/suju4.JPG";
import suju5 from "../src/Assests/3DProjects/Children/A4SUJUMON1/suju5.JPG";
import suju6 from "../src/Assests/3DProjects/Children/A4SUJUMON1/suju6.JPG";
import suju7 from "../src/Assests/3DProjects/Children/A4SUJUMON1/suju7.JPG";
import suju8 from "../src/Assests/3DProjects/Children/A4SUJUMON1/suju8.JPG";
import suju9 from "../src/Assests/3DProjects/Children/A4SUJUMON1/suju9.JPG";
import suju10 from "../src/Assests/3DProjects/Children/A4SUJUMON1/suju10.JPG";
import suju11 from "../src/Assests/3DProjects/Children/A4SUJUMON1/suju11.JPG";
import suju12 from "../src/Assests/3DProjects/Children/A4SUJUMON1/New suju (1).jpeg";
import suju13 from "../src/Assests/3DProjects/Children/A4SUJUMON1/New suju (2).jpeg";
import suju14 from "../src/Assests/3DProjects/Children/A4SUJUMON1/New suju (3).jpeg";
import suju15 from "../src/Assests/3DProjects/Children/A4SUJUMON1/New suju (4).jpeg";
import suju16 from "../src/Assests/3DProjects/Children/A4SUJUMON1/New suju (5).jpeg";
import suju17 from "../src/Assests/3DProjects/Children/A4SUJUMON1/New suju (6).jpeg";
import suju18 from "../src/Assests/3DProjects/Children/A4SUJUMON1/New suju (7).jpeg";
import suju19 from "../src/Assests/3DProjects/Children/A4SUJUMON1/New suju (8).jpeg";
import suju20 from "../src/Assests/3DProjects/Children/A4SUJUMON1/New suju (9).jpeg";
import suju21 from "../src/Assests/3DProjects/Children/A4SUJUMON1/New suju (10).jpeg";
import suju22 from "../src/Assests/3DProjects/Children/A4SUJUMON1/New suju (11).jpeg";
import bobby1 from "../src/Assests/3DProjects/Children/A5BOBBY/bobby beglow view2 -1.jpg";
import bobby2 from "../src/Assests/3DProjects/Children/A5BOBBY/bobby interior view10.jpg";
import bobby3 from "../src/Assests/3DProjects/Children/A5BOBBY/bobby interior view11 (1).jpg";
import bobby4 from "../src/Assests/3DProjects/Children/A5BOBBY/bobby interior view8.jpg";
import bobby5 from "../src/Assests/3DProjects/Children/A5BOBBY/bobby interior view9.jpg";
import bobby7 from "../src/Assests/3DProjects/Children/A5BOBBY/booby6.JPG";
import bobby6 from "../src/Assests/3DProjects/Children/A5BOBBY/PHOTO-2024-01-20-18-32-02.jpg";
import bobby8 from "../src/Assests/3DProjects/Children/A5BOBBY/PHOTO-2024-01-20-18-32-15.jpg";
import mathen1 from "../src/Assests/3DProjects/Children/A6MATHEN/mathan mathew dinning view 01.jpg";
import mathen2 from "../src/Assests/3DProjects/Children/A6MATHEN/mathan mathew family living view 01.jpg";
import mathen7 from "../src/Assests/3DProjects/Children/A6MATHEN/mathan mathew family living view 02.jpg";
import mathen3 from "../src/Assests/3DProjects/Children/A6MATHEN/mathan mathew family living view 03 (1).jpg";
import mathen4 from "../src/Assests/3DProjects/Children/A6MATHEN/mathan mathew livingroom view 01.jpg";
import mathen5 from "../src/Assests/3DProjects/Children/A6MATHEN/mathan mathew livingroom view 02.jpg";
import mathen6 from "../src/Assests/3DProjects/Children/A6MATHEN/mathan mathew livingroom view 03.jpg";
import ajiit1 from "../src/Assests/3DProjects/Children/A7Ajit/VIew1.jpg";
import ajiit2 from "../src/Assests/3DProjects/Children/A7Ajit/view10.jpg";
import ajiit3 from "../src/Assests/3DProjects/Children/A7Ajit/view12.jpg";
import ajiit4 from "../src/Assests/3DProjects/Children/A7Ajit/view13.jpg";
import ajiit5 from "../src/Assests/3DProjects/Children/A7Ajit/view15.jpg";
import ajiit6 from "../src/Assests/3DProjects/Children/A7Ajit/view2.jpg";
import ajiit7 from "../src/Assests/3DProjects/Children/A7Ajit/view4.jpg";
import ajiit8 from "../src/Assests/3DProjects/Children/A7Ajit/view5.jpg";
import ajiit9 from "../src/Assests/3DProjects/Children/A7Ajit/view6.jpg";
import ajiit10 from "../src/Assests/3DProjects/Children/A7Ajit/view9.jpg";
import jomon1 from "../src/Assests/3DProjects/Children/A8JOMON/ancorage bed 2 view 1.jpg";
import jomon2 from "../src/Assests/3DProjects/Children/A8JOMON/ancorage courtyard view1 2.jpg";
import jomon3 from "../src/Assests/3DProjects/Children/A8JOMON/ancorage courtyard view1.jpg";
import jomon4 from "../src/Assests/3DProjects/Children/A8JOMON/ancorage courtyard view2 (1).jpg";
import jomon8 from "../src/Assests/3DProjects/Children/A8JOMON/ancorage courtyard view3.jpg";
import jomon9 from "../src/Assests/3DProjects/Children/A8JOMON/ancorage kitchen view1.jpg";
import jomon10 from "../src/Assests/3DProjects/Children/A8JOMON/ancorage kitchen view2.jpg";
import jomon11 from "../src/Assests/3DProjects/Children/A8JOMON/ancorage kitchen view3.jpg";
import jomon12 from "../src/Assests/3DProjects/Children/A8JOMON/ancorage living 1.jpg";
import jomon13 from "../src/Assests/3DProjects/Children/A8JOMON/ancorage living 3.jpg";
import jomon14 from "../src/Assests/3DProjects/Children/A8JOMON/ancorage living 4.jpg";
import kz1 from "../src/Assests/3DProjects/Children/A8KAZZERKODU/10 kasarkode Wudhu  view.jpg";
import kz2 from "../src/Assests/3DProjects/Children/A8KAZZERKODU/12 kasarkode dinning view2.jpg";
import kz3 from "../src/Assests/3DProjects/Children/A8KAZZERKODU/16 kasarkode kitchen view2 (1).jpg";
import kz4 from "../src/Assests/3DProjects/Children/A8KAZZERKODU/21 kasarkode ground masterbed view3.jpg";
import kz5 from "../src/Assests/3DProjects/Children/A8KAZZERKODU/27 kasarkode ground child bed view1.jpg";
import kz6 from "../src/Assests/3DProjects/Children/A8KAZZERKODU/3 kasarkode reception view3.jpg";
import kz7 from "../src/Assests/3DProjects/Children/A8KAZZERKODU/30 kasarkode ground child bed view4.jpg";
import kz8 from "../src/Assests/3DProjects/Children/A8KAZZERKODU/31 kasarkode upper stair room view1.jpg";
import kz9 from "../src/Assests/3DProjects/Children/A8KAZZERKODU/33 kasarkode upper masterbed view1.jpg";
import kz10 from "../src/Assests/3DProjects/Children/A8KAZZERKODU/37 kasarkode upper drawing1 view1.jpg";
import kz11 from "../src/Assests/3DProjects/Children/A8KAZZERKODU/38 kasarkode upper masterbed view5.jpg";
import kz12 from "../src/Assests/3DProjects/Children/A8KAZZERKODU/40  kasarkode upper drawing1 view3.jpg";
import kz13 from "../src/Assests/3DProjects/Children/A8KAZZERKODU/41 kasarkode upper gust bed view1.jpg";
import kz14 from "../src/Assests/3DProjects/Children/A8KAZZERKODU/45 kasarkode upper gust bed living 3.jpg";
import kz15 from "../src/Assests/3DProjects/Children/A8KAZZERKODU/47 kasarkode  upper child bed view1.jpg";
import kz16 from "../src/Assests/3DProjects/Children/A8KAZZERKODU/48 kasarkode  upper child bed view2.jpg";
import kz17 from "../src/Assests/3DProjects/Children/A8KAZZERKODU/49 kasarkode  upper child bed view3.jpg";
import kz18 from "../src/Assests/3DProjects/Children/A8KAZZERKODU/50 kasarkode upper child bed living view1.jpg";
import kz19 from "../src/Assests/3DProjects/Children/A8KAZZERKODU/7 kasarkode Qibla view.jpg";
import gopi1 from "../src/Assests/3DProjects/Children/A9GOPI/1 Gopi living view.jpg";
import gopi2 from "../src/Assests/3DProjects/Children/A9GOPI/10 Gopi dinning view.jpg";
import gopi3 from "../src/Assests/3DProjects/Children/A9GOPI/11 Gopi dinning view.jpg";
import gopi4 from "../src/Assests/3DProjects/Children/A9GOPI/17 Gopi Mbed view.jpg";
import gopi5 from "../src/Assests/3DProjects/Children/A9GOPI/21  Gopi bed2  view.jpg";
import gopi6 from "../src/Assests/3DProjects/Children/A9GOPI/24 Gopi bed3 view.jpg";
import gopi7 from "../src/Assests/3DProjects/Children/A9GOPI/3 Gopi living view.jpg";
import santosh1 from "../src/Assests/3DProjects/Children/A10SANTOSH/Santhosh interior view1.jpg";
import santosh2 from "../src/Assests/3DProjects/Children/A10SANTOSH/Santhosh interior view10.jpg";
import santosh3 from "../src/Assests/3DProjects/Children/A10SANTOSH/Santhosh interior view2.jpg";
import santosh4 from "../src/Assests/3DProjects/Children/A10SANTOSH/Santhosh interior view6.jpg";
import santosh5 from "../src/Assests/3DProjects/Children/A10SANTOSH/Santhosh interior view8.jpg";
import santosh6 from "../src/Assests/3DProjects/Children/A10SANTOSH/Santhosh interior view9.jpg";
import muhmad1 from "../src/Assests/3DProjects/Children/A11MUHAMMAD/courtyard.jpg";
import muhmad2 from "../src/Assests/3DProjects/Children/A11MUHAMMAD/dinning area view1.jpg";
import muhmad3 from "../src/Assests/3DProjects/Children/A11MUHAMMAD/dinning area view2.jpg";
import muhmad4 from "../src/Assests/3DProjects/Children/A11MUHAMMAD/dinning area view5.jpg";
import muhmad5 from "../src/Assests/3DProjects/Children/A11MUHAMMAD/family living view1.jpg";
import muhmad6 from "../src/Assests/3DProjects/Children/A11MUHAMMAD/family living view3.jpg";
import muhmad7 from "../src/Assests/3DProjects/Children/A11MUHAMMAD/first kitchen view.jpg";
import muhmad8 from "../src/Assests/3DProjects/Children/A11MUHAMMAD/living view 1.jpg";
import bineesh1 from "../src/Assests/3DProjects/Children/A12BINEESH/1 living.jpeg";
import bineesh2 from "../src/Assests/3DProjects/Children/A12BINEESH/10 dinning.jpeg";
import bineesh3 from "../src/Assests/3DProjects/Children/A12BINEESH/11 kitchen.jpeg";
import bineesh4 from "../src/Assests/3DProjects/Children/A12BINEESH/2 living.jpeg";
import bineesh5 from "../src/Assests/3DProjects/Children/A12BINEESH/2 living.jpeg";
import bineesh6 from "../src/Assests/3DProjects/Children/A12BINEESH/4 courtyard.jpeg";
import bineesh7 from "../src/Assests/3DProjects/Children/A12BINEESH/3 living.jpeg";
import bineesh8 from "../src/Assests/3DProjects/Children/A12BINEESH/5 courtyard.jpeg";
import bineesh9 from "../src/Assests/3DProjects/Children/A12BINEESH/6 familiy living.jpeg";
import bineesh10 from "../src/Assests/3DProjects/Children/A12BINEESH/7  familiy living.jpeg";
import bineesh11 from "../src/Assests/3DProjects/Children/A12BINEESH/8 stair& courtyard.jpeg";
import bineesh12 from "../src/Assests/3DProjects/Children/A12BINEESH/9 dinning.jpeg";
import arjun1 from "../src/Assests/3DProjects/Children/A13ARJUN/1,Arjun Vandhana .jpg";
import arjun2 from "../src/Assests/3DProjects/Children/A13ARJUN/1_10 - Photo 2.jpg";
import arjun3 from "../src/Assests/3DProjects/Children/A13ARJUN/1_10 - Photo.jpg";
import arjun4 from "../src/Assests/3DProjects/Children/A13ARJUN/1_3 - Photo.jpg";
import sunil22 from "../src/Assests/3DProjects/Children/A14SUNIL/sunil2.JPG";
import sujumon1 from "../src/Assests/3DProjects/Children/A15SUJU/m1.JPG";

import sujumon3 from "../src/Assests/3DProjects/Children/A15SUJU/m3.JPG";
import sujumon4 from "../src/Assests/3DProjects/Children/A15SUJU/m4.JPG";
import sujumon5 from "../src/Assests/3DProjects/Children/A15SUJU/m5.JPG";
import siddique1 from "../src/Assests/3DProjects/Children/A16SIDDHIQUE/siddhique view3 (1).jpg";
import binjo1 from "../src/Assests/3DProjects/Children/A19BINJO/Binjo house view2.jpg";
import binjo2 from "../src/Assests/3DProjects/Children/A19BINJO/Binjo house view3.jpg";
import arun1 from "../src/Assests/3DProjects/Children/A20ARUN/PHOTO-2024-01-26-17-11-53_1.jpg";
import arun2 from "../src/Assests/3DProjects/Children/A20ARUN/PHOTO-2024-01-26-17-11-54.jpg";
import arun3 from "../src/Assests/3DProjects/Children/A20ARUN/arun model 2 view1.jpg";
import arun4 from "../src/Assests/3DProjects/Children/A20ARUN/arun model 2 view2.jpg";
import rasheed1 from "../src/Assests/3DProjects/Children/A18Rashed/view1 new.jpg";
import rasheed2 from "../src/Assests/3DProjects/Children/A18Rashed/view2 new.jpg";
import rasheed3 from "../src/Assests/3DProjects/Children/A18Rashed/view3 new.jpg";
import tripthi1 from "../src/Assests/3DProjects/Children/Tripti/Tripthi (1).jpeg";
import tripthi2 from "../src/Assests/3DProjects/Children/Tripti/Tripthi (2).jpeg";
import tripthi3 from "../src/Assests/3DProjects/Children/Tripti/Tripthi (3).jpeg";
import tripthi4 from "../src/Assests/3DProjects/Children/Tripti/Tripthi (4).jpeg";
import tripthi5 from "../src/Assests/3DProjects/Children/Tripti/Tripthi (5).jpeg";
import tripthi6 from "../src/Assests/3DProjects/Children/Tripti/Tripthi (6).jpeg";
import tripthi7 from "../src/Assests/3DProjects/Children/Tripti/Tripthi (7).jpeg";
import tripthi8 from "../src/Assests/3DProjects/Children/Tripti/Tripthi (8).jpeg";
import tripthi9 from "../src/Assests/3DProjects/Children/Tripti/Tripthi (9).jpeg";
import tripthi10 from "../src/Assests/3DProjects/Children/Tripti/Tripthi (10).jpeg";
import tripthi11 from "../src/Assests/3DProjects/Children/Tripti/Tripthi (11).jpeg";
import tripthi12 from "../src/Assests/3DProjects/Children/Tripti/Tripthi (12).jpeg";
import tripthi13 from "../src/Assests/3DProjects/Children/Tripti/Tripthi (13).jpeg";
import tripthi14 from "../src/Assests/3DProjects/Children/Tripti/Tripthi (14).jpeg";
import tripthi15 from "../src/Assests/3DProjects/Children/Tripti/Tripthi (15).jpeg";
import manjunath1 from "../src/Assests/3DProjects/Children/A21Manjunath/Manjunath (1).jpeg"
import manjunath2 from "../src/Assests/3DProjects/Children/A21Manjunath/Manjunath (3).jpeg"
import manjunath3 from "../src/Assests/3DProjects/Children/A21Manjunath/ManjunathCover.jpg"
import anuranjan1 from "../src/Assests/3DProjects/Children/A22AnuRanjan/AnuRanjan (1).jpeg"
import anuranjan2 from "../src/Assests/3DProjects/Children/A22AnuRanjan/AnuRanjan (2).jpeg"
import anuranjan3 from "../src/Assests/3DProjects/Children/A22AnuRanjan/AnuRanjan (3).jpeg"
import anuranjan4 from "../src/Assests/3DProjects/Children/A22AnuRanjan/AnuRanjan (4).jpeg"
import skreddywhite1 from "../src/Assests/3DProjects/Children/A23SKWhite/SkWhite.jpeg"
import skreddywhite2 from "../src/Assests/3DProjects/Children/A23SKWhite/SkWhite2.jpg"
import test1 from "../src/Assests/3DProjects/Children/A24/A1 (1).jpeg"
import test2 from "../src/Assests/3DProjects/Children/A24/A1 (2).jpeg"
import test3 from "../src/Assests/3DProjects/Children/A24 (2)/AC.jpeg"
import test4 from "../src/Assests/3DProjects/Children/A24 (3)/AB (1).jpeg"
import test5 from "../src/Assests/3DProjects/Children/A24 (3)/AB (2).jpeg"
import test6 from "../src/Assests/3DProjects/Children/A24 (4)/AD.jpeg"


export const details = [
  {
    id: 1,
    src: sadat,
    tittle: " sadat",
    content1: "Luxe",
    content2: "Horizon",
    content3: "Luxe Horizon is a breathtaking architectural marvel crafted by our expert team at CBE Design Studio. We as a top architecture firm put in a lot of time and effort to design this masterpiece. As one of the best interior designing firms based in Bangalore we made sure that the striking triangular facade is a visual statement, seamlessly integrated with rich black marble that exudes luxury and durability. Complementing this are the iconic lighting features, casting an enchanting glow and highlighting the intricate textures throughout the space. Every detail, from the sleek surfaces to the meticulously designed textured areas, has been thoughtfully curated to create an ambiance of refined opulence and contemporary charm. Experience a residence where architectural innovation meets aesthetic brilliance.",
    paragraph:
      " This project embodies lavish living with its exquisite interiors and breathtaking views, setting the standard for upscale elegance.",

    children: [
      {
        id: 101,
        src: sadat11,
      },
      {
        id: 102,
        src: sadat1,
      },
      {
        id: 103,
        src: sadat2,
      },
      {
        id: 104,
        src: sadat3,
      },
      {
        id: 105,
        src: sadat4,
      },
      {
        id: 106,
        src: sadat5,
      },
      {
        id: 107,
        src: sadat6,
      },
      {
        id: 108,
        src: sadat7,
      },
      {
        id: 109,
        src: sadat8,
      },
      {
        id: 110,
        src: sadat9,
      },
      {
        id: 111,
        src: sadat10,
      },
    
    ],
  },
  {
    id: 2,
    src: SkReddy,
    tittle: " SkReddy",
    content1: "Sprawling ",
    content2: "Radiance",
    content3: "We from CBE Design Studio helped design a tropical contemporary house. As one of the best architects and interior designers in Bangalore, we created a stunning four-floor residence that features a spacious living room with a double-height ceiling, creating an airy and open ambiance. The house is completely wrapped in glass, allowing natural light to flood the interiors while the accompanying grillwork ensures security. The facade is adorned with imported Brazilian granite, adding a touch of elegance and durability. High-Pressure Laminate (HPL) sheets cover the windows, enhancing the aesthetic appeal. A unique outdoor staircase winds through a beautifully landscaped area, seamlessly integrating the home with its lush surroundings. An attached lift provides convenient access to all floors, making this a perfect blend of modern design and tropical charm.",
    paragraph:
      " A testament to grandeur, where expansive spaces and luminous interiors converge, illuminating luxury living at its finest. ",

    children: [
      {
        id: 121,
        src: sk1,
      },
      {
        id: 122,
        src: sk2,
      },
      {
        id: 123,
        src: sk3,
      },
      {
        id: 124,
        src: sk4,
      },
      {
        id: 125,
        src: sk5,
      },
      {
        id: 126,
        src: sk6,
      },
      {
        id: 127,
        src: sk7,
      },
      {
        id: 128,
        src: sk8,
      },
      {
        id: 129,
        src: sk9,
      },
      {
        id: 130,
        src: sk10,
      },
      {
        id: 131,
        src: sk11,
      },
      {
        id: 132,
        src: sk12,
      },
    ],
  },
  {
    id: 3,
    src: lohit,
    tittle: " lohit",
    content1: "Geomax ",
    content2: "Villa",
    content3: "Introducing Geomax Villa, a captivating architectural marvel meticulously crafted by our team at CBE Design Studio, one of the best Interior Designing firms and top architects in Bangalore,. This stunning residence embodies a fluidic design concept, characterized by seamless transitions and a harmonious flow, creating a captivating living space free from sharp edges. Nestled in Bangalore's vibrant landscape, Geomax Villa offers a unique blend of contemporary design and timeless elegance. Immerse yourself in the fluidity of space and experience unparalleled luxury at Geomax Villa, where every detail is thoughtfully curated to redefine modern living.",
    paragraph:
      " Where geometric precision and architectural excellence converge, offering a geometrical blend of sophistication and innovation.",
    children: [
      {
        id: 141,
        src: DL1,
      },
      // {
      //   id: 142,
      //   src: DL2,
      // },
      {
        id: 143,
        src: DL3,
      },
      {
        id: 144,
        src: DL4,
      },
      {
        id: 145,
        src: DL5,
      },
      {
        id: 146,
        src: DL6,
      },
      {
        id: 147,
        src: DL7,
      },
      {
        id: 148,
        src: DL8,
      },
      {
        id: 149,
        src: DL9,
      },
      {
        id: 150,
        src: DL10,
      },
      {
        id: 151,
        src: DL11,
      },
      {
        id: 152,
        src: DL12,
      },
      {
        id: 153,
        src: DL13,
      },
      {
        id: 154,
        src: DL14,
      },
    ],
  },
  {
    id: 33,
    src: manjunath3,
    tittle: " manjunath",
    content3: "Introducing 'Aura Residence', an architectural marvel rising gracefully to four stories, meticulously crafted by our team of architects in Bangalore. We from CBE Design Studio pride ourselves to be one of the top architects in Bangalore and the best Interior Designing firm here. As a testament to that, this towering abode stands tall, with each floor exuding elegance and sophistication. Situated in the heart of Bangalore, Aura Residence captivates with its innovative design and impeccable craftsmanship. Experience the epitome of modern living in this luxurious sanctuary, where every detail is thoughtfully curated to inspire and delight.",
    children: [
      {
        id: 141,
        src: manjunath1,
      },
      {
        id: 141,
        src: manjunath2,
      },

    ],
    content1: "Aura",
    content2: "Residence",
    paragraph:"Where strip lighting illuminates every corner, casting an aura of luxury and sophistication over this towering residence, inviting you to experience the epitome of modern elegance.",
  },
  {
    id: 4,
    src: nazeeba,
    tittle: " nazeeba",
    content1: "Vertical ",
    content2: "Heights",
    content3: "Introducing 'Vertical Heights' - an architectural masterpiece crafted with a lavish abundance of wood for its elevation, complemented by a luxury finish adorned with modern lighting fixtures and a meticulously patterned terrace. Designed in collaboration with one of the best architecture firms in Bangalore, this residence exudes sophistication and warmth, blending contemporary elegance with timeless natural elements. An an interior designing firm we made sure that the lavish use of wood adds a touch of organic charm, while modern lighting fixtures illuminate the space with an enchanting glow. Step onto the patterned terrace and immerse yourself in a realm of unparalleled beauty, where every detail is thoughtfully curated to elevate your living experience to new heights of luxury.",
    paragraph:
      "  Ascend to unparalleled luxury and panoramic vistas in this epitome of elevated living.",
      children: [
       
        {
          id: 103,
          src: nazeeba2,
        },
        {
          id: 112,
          src: nazeeba11,
        },
        {
          id: 105,
          src: nazeeba4,
        },
        {
          id: 107,
          src: nazeeba6,
        },
        {
          id: 104,
          src: nazeeba3,
        },
        {
          id: 106,
          src: nazeeba5,
        },
        
        {
          id: 108,
          src: nazeeba7,
        },
        {
          id: 110,
          src: nazeeba9,
        },
        {
          id: 109,
          src: nazeeba8,
        },
        
        {
          id: 111,
          src: nazeeba10,
        },
       
      ],
  },
  {
    id: 5,
    src: Sujumon1,
    tittle: " Sujumon1",
    content1: "360° View",
    content2: "House",
    content3: "Introducing the 360 Degree View House. This unique residence offers panoramic vistas from every angle, immersing occupants in breathtaking scenery and boundless horizons. Designed to maximize natural light and embrace the surrounding landscape, the 360 Degree View House redefines modern living with its seamless integration of indoor and outdoor spaces. Experience the services of our company CBE Design Studio, one of the best architecture firms in Bangalore. We, as top interior designers helped with innovation and adding luxury in this unparalleled sanctuary, where every detail is meticulously curated to elevate your living experience.",
     paragraph:
      " A project Where every angle offers a breathtaking panorama, redefining the concept of immersive living.",
    children: [
      {
        id: 161,
        src: suju21,
      },
      {
        id: 162,
        src: suju9,
      },
      {
        id: 163,
        src: suju13,
      },
      {
        id: 164,
        src: suju12,
      },
      {
        id: 165,
        src: suju18,
      },
      {
        id: 166,
        src: suju19,
      },
      {
        id: 167,
        src: suju20,
      },
      {
        id: 168,
        src: suju3,
      },
      {
        id: 169,
        src: suju6,
      },
      {
        id: 170,
        src: suju15,
      },
      {
        id: 171,
        src: suju16,
      }, {
        id: 161,
        src: suju1,
      },
    
      {
        id: 163,
        src: suju4,
      },
      {
        id: 164,
        src: suju5,
      },
      {
        id: 165,
        src: suju7,
      },
      {
        id: 166,
        src: suju8,
      },
      {
        id: 167,
        src: suju9,
      },
      {
        id: 168,
        src: suju10,
      },
      {
        id: 169,
        src: suju11,
      },
      {
        id: 170,
        src: suju14,
      },
      {
        id: 171,
        src: suju17,
      },
      {
        id: 171,
        src: suju2,
      },
      {
        id: 171,
        src: suju22,
      },
    ],
  },
  {
    id: 45,
    src: anuranjan1,
    tittle: " anuranjan1",
    content1: "Twin",
    content2: "Contemperory",
    content3: "Introducing Twin Contemporary - a unique architectural endeavor tailored for two best friends, meticulously designed by the top architects in Bangalore. This modern dwelling offers a harmonious blend of style and functionality, embodying the shared aspirations and individual tastes of its discerning occupants. Experience the epitome of contemporary living in these bespoke residences, where innovative design seamlessly integrates with urban comfort to create an unparalleled living experience. Enjoy the thrill of rich interiors with one the best interior designers in Bangalore, CBE Design Studio.",
    paragraph: "A project where contemporary design intertwines two identical residences, providing separate yet connected spaces for two best friends to share their lives in modern luxury and mutual camaraderie. ",
    children: [
      {
        id: 161,
        src: anuranjan1,
      },
      {
        id: 162,
        src: anuranjan2,
      },
      {
        id: 163,
        src: anuranjan3,
      },
      {
        id: 164,
        src: anuranjan4,
      },
      
    ],
  },
  {
    id: 34,
    src: skreddywhite1,
    tittle: " skreddywhite",
    content1: "Glass",
    content2: "Prism",
    content3: "Introducing Glass Prism - an architectural marvel characterized by its striking geometric design and abundant use of glass, offering captivating views both inside and out. Crafted with meticulous attention to detail by one of the best architects in Bangalore and top interior designers here, this contemporary masterpiece embodies modern elegance and sophistication. The innovative use of glass creates a seamless connection between interior and exterior spaces, inviting natural light to flood the living areas and accentuate the sleek lines of the design. Step into Glass Prism and experience a world of transparency and luminosity, where the boundaries between architecture and nature are blurred, offering a truly immersive living experience.",
    paragraph: "A project where geometric design seamlessly blends with expansive glass walls, offering panoramic views and a modern architectural masterpiece that harmonizes transparency with elegance. ",
    children: [
      {
        id: 161,
        src: skreddywhite1,
      },
      {
        id: 162,
        src: skreddywhite2,
      }
    ],
  },
  {
    id: 6,
    src: biju,
    tittle: "biju ",
    content1: "Casa De ",
    content2: "Palmos",
    content3: "Casa de Palmos is a captivating embodiment of Spanish architecture by CBE Design Studio, transporting you to the sun-drenched landscapes of Spain with its distinctive style and charm. From the terra cotta roofs and whitewashed walls to the intricate wrought iron details and vibrant tile work, every element of this house reflects the rich cultural heritage of Spain. As one of the best interior designing firms in Bangalore, we pride ourselves in attention to detail. Step inside and you'll find yourself surrounded by arched doorways, rustic wooden beams, and cozy courtyards that evoke the essence of Mediterranean living. Experience the romance and allure of Spanish architecture in every corner of Casa de Palmos.",
    paragraph:
      " Embracing Spanish architecture, this luxurious haven beckons with its blend of timeless charm and modern opulence.",
    children: [],
  },
  {
    id: 7,
    src: bobby,
    tittle: " bobby",
    content1: "Glass House ",
    content2: "Tavern",
    content3: "Introducing Glass House Tavern - a unique restaurant crafted entirely from glass, offering an unparalleled dining experience. This architectural gem stands as a testament to innovation and creativity, with its transparent walls allowing guests to immerse themselves in the surrounding ambiance while enjoying exquisite cuisine and drinks. Designed by CBE Design Studio, one of the best architecture and interior designing firm in Bangalore, Glass House Tavern is a stunning fusion of modern architecture and culinary excellence, where every detail is carefully curated to delight the senses. Step inside and indulge in a feast for both the eyes and the palate, as you savor the magic of this extraordinary glass-encased venue.",
    paragraph:
      "A contemporary restaurant where sleek glass accents reflect the vibrant atmosphere within, offering a modern twist on classic tavern hospitality. ",
    children: [
      {
        id: 181,
        src: bobby1,
      },
      {
        id: 182,
        src: bobby2,
      },
      {
        id: 183,
        src: bobby3,
      },
      {
        id: 184,
        src: bobby4,
      },
      {
        id: 185,
        src: bobby5,
      },
      // {id: 186,src: bobby6},
      {
        id: 187,
        src: bobby7,
      },
      {
        id: 188,
        src: bobby8,
      },
    ],
  },
  {
    id: 8,
    src: sanal,
    tittle: " sanal",
    children: [],
    content1: "Gods Own",
    content2: "Villa",
    content3: "God's Own Villa stands as a timeless tribute to Kerala's traditional architectural charm, meticulously crafted by one of the best architects in Bangalore. With its distinctive sloping roofs, wooden accents, and intricate detailing, this traditional-style Kerala house exudes warmth and character. From the serene verandas to the spacious interiors adorned with traditional furnishings and decor, every aspect of God's Own Villa reflects the unique beauty and elegance of Kerala's architectural legacy. Step into this enchanting abode and immerse yourself in the tranquility and charm of Kerala's rich cultural heritage.",
    paragraph:
      " A traditional Kerala-style retreat, where timeless elegance and natural beauty converge, offering a sanctuary of luxury and tranquility in God's own country.",
  },
  {
    id: 9,
    src: magan,
    tittle: " magan",
    content1: "Wavy ",
    content2: "Horizon",
    content3: "Wavy Horizon mesmerizes with its distinctive wavy elements scattered throughout the house, evoking a sense of movement and fluidity in its design. As one of the best architects in Bangalore, we have put in a lot of dedication and hardwork to come up with this design. These unique features seamlessly extend from the exterior to the interiors, adding a touch of dynamism and character to every space. Crafted with meticulous attention to detail by one of the best interior designers in Bangalore, Wavy Horizon stands as a one-of-a-kind architectural masterpiece that showcases innovation and creativity at its finest. Experience the beauty of fluid lines and organic shapes in every corner of this exceptional residence.",
    paragraph:
      " Where architectural waves cascade through every detail, creating a fluid harmony between design and nature, promising a horizon of serenity and sophistication.",
    children: [
      {
        id: 191,
        src: mathen1,
      },
      {
        id: 192,
        src: mathen2,
      },
      {
        id: 193,
        src: mathen3,
      },
      {
        id: 194,
        src: mathen4,
      },
      {
        id: 195,
        src: mathen5,
      },
      {
        id: 196,
        src: mathen6,
      },
      {
        id: 197,
        src: mathen7,
      },
    ],
  },
  {
    id: 10,
    src: ajit,
    tittle: " ajit",
    content1: "Fluidic ",
    content2: "Functionality",
    content3: "Fluidic Functionality is a one-of-a-kind residence that embraces free-flowing, fluidic elements throughout its design, setting it apart as a true architectural marvel. The seamless Intergration of these elements by CBE Design Studio not only enhances the aesthetic appeal of the house but also contributes to its functionality and overall ambiance. Crafted by top architects and one of the best interior designers, Fluidic Functionality offers a unique living experience that blends innovation with style and comfort.",
    paragraph:
      "Where sleek design elements seamlessly blend with practical utility. From the flowing layout to the adaptable features, it's modern living at its finest. ",
    children: [
      {
        id: 191,
        src: ajiit1,
      },
      {
        id: 192,
        src: ajiit2,
      },
      {
        id: 193,
        src: ajiit3,
      },
      {
        id: 194,
        src: ajiit4,
      },
      {
        id: 195,
        src: ajiit5,
      },
      {
        id: 196,
        src: ajiit6,
      },
      {
        id: 197,
        src: ajiit7,
      },
      {
        id: 198,
        src: ajiit8,
      },
      {
        id: 199,
        src: ajiit9,
      },
    ],
  },
  {
    id: 11,
    src: binjo,
    tittle: " binjo",
    content1: "Crescent ",
    content2: "Ridge",
    content3: "Crescent Ridge stands as a striking testament to architectural innovation, with the entire building gracefully curved in the shape of a crescent moon. This distinctive design by CBE Design Studio, not only sets it apart but also lends an air of elegance and uniqueness to the mansion. The front yard, complemented by ample parking space, welcomes visitors with grandeur and charm, setting the tone for the architectural marvel that lies within. Explore Crescent Ridge and experience the beauty of curved elegance in every aspect of its design with one of the best architects and top interior designers in Bangalore.",
    paragraph:
      "Embracing its subtle curvature, this unique home exudes charm and character, offering a modern twist on traditional design amidst serene surroundings. ",
    children: [
      {
        id: 191,
        src: binjo1,
      },
      {
        id: 192,
        src: binjo2,
      },
    ],
  },
  {
    id: 12,
    src: jomon8,
    tittle: " jomun",
    content1: "Green",
    content2: "Revelation",
    content3: "Green Revelation, a house that harmoniously blends modern design with nature. As one of the best Interior Designing firms and top architects in Bangalore, we put in a lot of effort to make sure this house is one of a kind. One of the distinct features of this home is its lush courtyard, a verdant oasis filled with greenery and plants, perfect for relaxing and enjoying fresh air. Inside, the fluidic interiors feature a sleek black and white color palette, creating a contemporary and sophisticated ambiance. The ceilings are adorned with elegant curved designs, adding a dynamic flow to the space, while the textured walls provide depth and interest, enhancing the overall aesthetic. Green Revelation is a sanctuary where nature and modernity coexist beautifully, offering a serene and stylish living experience.",
    paragraph:
      " A sustainable oasis where eco-friendly design and luxurious comfort converge, inviting harmony with nature in every corner.",

    children: [
      {
        id: 191,
        src: jomon1,
      },
      {
        id: 193,
        src: jomon3,
      },
      {
        id: 194,
        src: jomon4,
      },
      {
        id: 198,
        src: jomon8,
      },
      {
        id: 199,
        src: jomon9,
      },
      {
        id: 195,
        src: jomon10,
      },
      {
        id: 196,
        src: jomon11,
      },
      {
        id: 197,
        src: jomon12,
      },
      {
        id: 198,
        src: jomon13,
      },
      {
        id: 199,
        src: jomon14,
      },
    ],
  },
  {
    id: 13,
    src: kiran1,
    tittle: "kiran1 ",
    content1: "Panorama",
    content2: "House",
    content3: "Panorama House offers a breathtaking view of the surroundings with its abundance of windows, including two remarkable features: a curved window and another with a subtle geometric element. These expansive windows not only flood the interior with natural light but also frame the picturesque vistas outside, creating a seamless connection between the indoors and the outdoors. The curved window adds a touch of elegance and fluidity to the design, while the geometric element infuses a modern flair. As one of the best interior designers in Bangalore, we from CBE Design Studio have put in a lot of thought and effort to make this home stand out.  Experience the beauty of Panorama House as it invites the outdoors in and provides a stunning backdrop for luxurious living with architects in Bangalore.",
    paragraph:
      "Where every window frames a breathtaking vista, inviting you to immerse yourself in the beauty of the surrounding landscape. ",
    children: [],
  },
  {
    id: 14,
    src: kasarkode,
    tittle: "kasarkode ",
    content1: "Hall Of",
    content2: " Grandeur",
    content3: "As one of the best luxury Interior Designing firms in Bangalore, we made sure that the Hall of Grandeur exemplifies opulence and sophistication. From the moment you step inside, you are greeted by an atmosphere of grandeur and elegance. As one of the best architects in bangalore, we made sure that this design exudes richness, with lavish furnishings, sumptuous fabrics, and intricate detailing adorning every room. One of the standout features of Hall of Grandeur is the prominent use of rich wood elements throughout the interiors. From polished hardwood floors to ornate wooden furnishings and accents, the warmth and beauty of wood infuse the space with a sense of timeless luxury. Experience the epitome of refined living in Hall of Grandeur, where every detail is crafted to exude magnificence and grandeur.",
    paragraph:
      "A grandiose residence where expansive interiors and majestic halls converge, redefining luxury living with its breathtaking design. ",
    children: [
      {
        id: 191,
        src: kz1,
      },
      {
        id: 192,
        src: kz2,
      },
      {
        id: 193,
        src: kz3,
      },
      {
        id: 194,
        src: kz4,
      },
      {
        id: 195,
        src: kz5,
      },
      {
        id: 196,
        src: kz6,
      },
      {
        id: 197,
        src: kz7,
      },
      {
        id: 198,
        src: kz8,
      },
      {
        id: 199,
        src: kz9,
      },
      {
        id: 195,
        src: kz10,
      },
      {
        id: 196,
        src: kz11,
      },
      {
        id: 197,
        src: kz12,
      },
      {
        id: 198,
        src: kz13,
      },
      {
        id: 199,
        src: kz14,
      },
      {
        id: 198,
        src: kz15,
      },
      {
        id: 199,
        src: kz16,
      },
      {
        id: 195,
        src: kz17,
      },
      {
        id: 196,
        src: kz18,
      },
      {
        id: 197,
        src: kz19,
      },
    ],
  },
  {
    id: 15,
    src: narsimha,
    tittle: " narsimha",
    content1: "Humble ",
    content2: "Abode",
    content3: "Humble Abode is a testament to modern design innovation, where CNC metal and Bergolar are seamlessly incorporated to elevate the house's aesthetic appeal. The use of CNC metal adds a sleek and contemporary touch, creating structures that redefine the traditional concept of home design. Bergolar, with its natural and rustic charm, provides contrast and texture, adding warmth and character to the overall composition. As one of the best architects in Bangalore, we made sure that this fusion of materials not only enhances the visual appeal of Humble Abode but also reflects a harmonious balance between modernity and nature. Experience the unique charm and sophistication of this humble yet stylish residence.",
    paragraph:
      "Embracing a simplistic design ethos, this charming home exudes warmth and comfort, inviting you to find solace in its unassuming elegance and understated beauty. ",
    children: [],
  },
  {
    id: 16,
    src: kiran2,
    tittle: " kiran2",
    content1: "Imperial",
    content2: "Mansion",
    content3: "Imperial Mansion stands as a testament to grandeur and architectural sophistication, adorned with several geometric elements that add to its majestic allure. Made by one of the best architecture firms in Bangalore, this project is truly one of a kind. From the symmetrical lines of the facade to the intricately designed geometric patterns found in the interiors, every detail reflects meticulous craftsmanship and attention to detail.We consider ourselves to be one of the top interior designers in Bangalore which is why these geometric elements not only enhance the visual appeal of the mansion but also contribute to its structural integrity and timeless elegance. Combined with the lush green lawn and stunning entrance, they create a harmonious blend of beauty and symmetry that defines the essence of Imperial Mansion. Step inside this grand residence and immerse yourself in a world where luxury meets geometric perfection.",
    paragraph:
      " A grand estate where regal opulence and architectural magnificence reign supreme, offering a timeless symbol of luxury and prestige. ",
    children: [],
  },
  {
    id: 17,
    src: arunModel,
    tittle: "  arunModel ",
    content1: "Peacful",
    content2: "Eden",
    content3: "Peaceful Eden is a modern and unique residence characterized by its distinctive boxy elements. These geometric features give the house a striking and contemporary look in terms of elevation, creating a visually captivating facade. The clean lines and structured form of the design reflect a sophisticated modern aesthetic, making Peaceful Eden a standout in architectural innovation. Inside, the seamless blend of form and function provides a tranquil and luxurious living space, showcasing the artistry of top architects and interior designers. Experience the perfect harmony of modern design and serene living at Peaceful Eden",
    paragraph:
      " A serene sanctuary where tranquility and harmony abound, enveloping you in a haven of natural beauty and serene bliss.",
    children: [
      {
        id: 191,
        src: arun1,
      },
      {
        id: 192,
        src: arun2,
      },
      {
        id: 193,
        src: arun3,
      },
      {
        id: 194,
        src: arun4,
      },
    ],
  },
  {
    id: 18,
    src: continental,
    tittle: " continental",
    content1: "Victorian",
    content2: "Art",
    content3:"Victorian Art is a splendid representation of Victorian-style architecture, embodying timeless elegance and intricate detailing. The house features classic Victorian elements such as ornate facades, steep gabled roofs, and decorative trims, reflecting the grandeur of a bygone era. Inside, the meticulous craftsmanship continues with luxurious interiors that blend antique furnishings, rich fabrics, and intricate moldings. Designed by one of the top architecture firms in Bangalore, Victorian Art showcases the perfect marriage of historical charm and modern luxury, creating a home that is both majestic and inviting.",
    paragraph:
      " A masterpiece of architectural elegance, this home pays homage to the intricate details and timeless beauty of the Victorian era, offering a blend of sophistication and charm.",
    children: [],
  },
  {
    id: 19,
    src: gopi,
    tittle: "gopi",
    content1: "God's Own",
    content2: "Fusion",
    content3: "God's Own Fusion is a unique residence that harmoniously blends traditional Kerala-style exteriors with contemporary interiors. We chose to help design this house as it involves culture and a design style we specialise in. The exterior showcases the rich heritage of Kerala architecture, characterized by elegant wooden elements and classic design. As one of the best interior designers we made sure that this house features textured walls throughout, creating a stylish and sophisticated ambiance. This one-of-a-kind design is brought to life by top architects, while the luxurious interior design was crafted by one of the best interior designers in Bangalore, CBE Design Studio. God's Own Fusion perfectly balances tradition and modernity, offering a distinctive and elegant living experience.",
    paragraph:
      " Blending traditional Kerala-style architecture with modern elements, this exquisite residence offers a harmonious fusion of heritage and contemporary luxury, creating a divine retreat in God's own country. ",
    children: [
      {
        id: 191,
        src: gopi1,
      },
      {
        id: 192,
        src: gopi2,
      },
      {
        id: 193,
        src: gopi3,
      },
      {
        id: 194,
        src: gopi4,
      },
      {
        id: 195,
        src: gopi5,
      },
      {
        id: 196,
        src: gopi6,
      },
      {
        id: 197,
        src: gopi7,
      },
    ],
  },
  {
    id: 20,
    src: santosh,
    tittle: " santosh",
    content1: "Flowing ",
    content2: "Lines",
    content3: "Flowing Lines is a masterpiece of interior design, where fluidic forms and the absence of sharp edges create a serene and harmonious living space. The interiors of this house are meticulously crafted to ensure a seamless flow between rooms, enhancing both functionality and aesthetic appeal. We, CBE Design Studio, top luxury interior designers in Bangalore have brought our expertise to this project, using high-quality materials and sophisticated finishes to elevate the overall ambiance. Flowing Lines stands as a testament to the beauty of continuous, unbroken design, offering a luxurious and tranquil retreat.",
    paragraph:
      " Inspired by the grace of nature, this home's design embodies fluidity and movement, with sweeping lines and organic forms creating a sense of elegant continuity throughout.",

    children: [
      {
        id: 191,
        src: santosh1,
      },
      {
        id: 192,
        src: santosh2,
      },
      {
        id: 193,
        src: santosh3,
      },
      {
        id: 194,
        src: santosh4,
      },
      {
        id: 195,
        src: santosh5,
      },
      {
        id: 196,
        src: santosh6,
      },
    ],
  },
  {
    id: 21,
    src: muhammad,
    tittle: " muhammad",
    content1: "Curvacious",
    content2: "Manor",
    content3: "Curvaceous Manor embraces a pattern of curves, creating a free-flowing and elegant aesthetic throughout the house. This unique design showcases the hard work and innovative vision of one of the best architects in Bangalore, resulting in a residence where every element seamlessly integrates into the overall flow. Inside, top interior designers have meticulously crafted each space, ensuring that the curves are complemented by high-quality materials and refined finishes. The result is a harmonious blend of comfort and sophistication, making Curvaceous Manor a true testament to exceptional architectural and interior design.",
    paragraph:
      "A grand estate where sinuous lines and graceful curves define every detail, offering a luxurious retreat of timeless beauty and captivating elegance. ",
    children: [
      {
        id: 191,
        src: muhmad1,
      },
      {
        id: 192,
        src: muhmad2,
      },
      {
        id: 193,
        src: muhmad3,
      },
      {
        id: 194,
        src: muhmad4,
      },
      {
        id: 195,
        src: muhmad5,
      },
      {
        id: 196,
        src: muhmad6,
      },
      {
        id: 195,
        src: muhmad7,
      },
      {
        id: 196,
        src: muhmad8,
      },
    ],
  },
  {
    id: 22,
    src: rasheed,
    tittle: " rasheed",
    content1: "A-Frame ",
    content2: "Haven",
    content3: "A-Frame Haven stands out with its unique design, incorporating distinctive A-frame elements into various parts of the house. While not the entire structure follows this form, the selective use of the A-frame shape adds a striking and innovative touch to the overall architecture. This one-of-a-kind residence showcases the hard work and creativity of one of the best architecture firms in Bangalore, CBE Design Studio, resulting in a home that is both visually appealing and functionally exceptional. Inside, the expertise of top interior designers ensures that every detail complements the unique architectural style, creating a harmonious blend of comfort and modern elegance. Experience the unparalleled charm of A-Frame Haven, where distinctive design meets luxurious living.",
    paragraph:
      "With its distinctive A-shaped exteriors, this cozy retreat combines rustic charm with modern comfort, offering a unique architectural embrace amidst nature's tranquility. ",

    children: [
      {
        id: 191,
        src: rasheed1,
      },
      {
        id: 192,
        src: rasheed2,
      },
      {
        id: 193,
        src: rasheed3,
      },
    ],
  },
  {
    id: 23,
    src: siddhique1,
    tittle: " siddhique1",
    content1: "Prestige ",
    content2: "Palace",
    content3: "Prestige Palace is a luxurious residence designed by one of the best architecture firms in Bangalore, that masterfully employs geometric elements to distinguish different parts of the house, creating a visually stunning and cohesive design. The architecture features bold lines and shapes that give each section of the home a unique character. Inside, the meticulous hard work of top interior designers here, ensures every space is both functional and elegant, with high-quality materials and refined finishes enhancing the overall aesthetic. Prestige Palace stands as a testament to innovative design and architectural excellence, offering a harmonious blend of style and luxury.",
    paragraph:
      "A stately residence where grandeur meets sophistication, exuding timeless elegance and offering an unmatched symbol of luxury living.",
    children: [],
  },
  {
    id: 24,
    src: siddhique2,
    tittle: " siddhique2",
    content1: "Riverside",
    content2: "Retreat",
    content3: "Riverside Retreat is a stunning masterpiece built on the serene banks of River Kayal, combining natural beauty with luxurious design. This residence showcases meticulous hard work with its striking architecture by one of the best luxury architecture firms in Bangalore. Inside, top interior designers from CBE Design Studio have ensured that every detail exudes opulence, featuring high-quality materials and elegant finishes. Experience the ultimate in luxury living at Riverside Retreat, where thoughtful design meets the tranquil beauty of the river.",
    paragraph:
      " Nestled along the banks of the Kayal River, this tranquil haven offers a serene escape, where the gentle flow of the river harmonizes with the peaceful ambiance of nature.",
    children: [
      {
        id: 201,
        src: siddique1,
      },
    ],
  },
  {
    id: 25,
    src: sunil2,
    tittle: " sunil2",
    content1: "Modern",
    content2: "Comfort",
    content3: "Modern Comfort embraces the elegance of Modern architecture, with its distinctive features such as symmetrical facades, grand columns, and spacious verandas that evoke a sense of timeless charm. We from CBE Design Studio have made sure that each element is meticulously crafted to capture the essence of Modern-era aesthetics while providing modern comfort and luxury. We as one of the top interior designers and one of the best architects in Bangalore have lent our expertise to enhance the Modern ambiance of Modern Comfort. From the tasteful selection of furnishings to the intricate detailing of moldings and finishes, every aspect of the interior design reflects a commitment to authenticity and refinement.Experience the epitome of modern living at Modern Comfort, where classic design meets contemporary luxury in a harmonious blend curated by the best  architects.",
    paragraph:
      "A timeless abode infused with the elegance of Modern design, offering a warm and inviting retreat that blends classic charm with modern comfort.",
    children: [],
  },
  {
    id: 26,
    src: Sujumon2,
    tittle: " Sujumon2",
    content1: "Tropical",
    content2: "Serenity",
    content3: "Tropical Serenity offers a haven of peace and tranquility amidst lush greenery and vibrant tropical surroundings. This enchanting retreat designed by CBE Design Studio, is made to seamlessly blend with its natural environment, creating a harmonious oasis where residents can escape the hustle and bustle of everyday life. As Top architects in Bangalore we were  inspired by the beauty of tropical landscapes, with open-air spaces, expansive windows, and natural materials that invite the outdoors in. Each element of the design is carefully crafted to maximize natural light and ventilation, fostering a sense of connection with nature. We as one the best interior designing firms in Bangalore focus on natural lighting more than anything. This gives the house a soft and warm glow.",
    paragraph:
      "An idyllic escape where lush foliage and gentle breezes create a serene ambiance, offering a blissful retreat amidst the beauty of the tropics.",

    children: [
      {
        id: 191,
        src: sujumon1,
      },
      // {
      //   id: 192,
      //   src: sujumon2,
      // },
      {
        id: 193,
        src: sujumon3,
      },
      {
        id: 194,
        src: sujumon4,
      },
    ],
  },
  {
    id: 27,
    src: sherly,
    tittle: " sherly",
    content1: "Patriot's",
    content2: "Palace",
    content3: "Patriots Palace exudes an air of majestic beauty with its stunning colonial architecture. The graceful lines, intricate detailing, and stately presence of the structure evoke a sense of timeless elegance and sophistication. Every aspect of the design pays homage to the grandeur of colonial-era aesthetics, creating a truly regal ambiance.Inside, the interior design is meticulously curated by one of the top architecture company in Bangalore, ensuring that every space exudes luxury and refinement. From opulent furnishings to exquisite decor accents, every element is chosen to complement the architectural splendor of the palace.",
    paragraph:
      " Embracing colonial design, this majestic residence stands as a tribute to heritage and tradition, offering a regal retreat steeped in timeless elegance and patriotic pride.",
    children: [],
  },
 
  {
    id: 29,
    src: bineesh,
    tittle: " bineesh",
    content1: "Structural",
    content2: "Stones",
    content3: "Structural Stones boasts a distinctive charm with its usage of natural stones for the elevation, imparting a timeless and organic aesthetic. The carefully selected stones lend a sense of authenticity and solidity to the structure, creating a unique and striking facade. Crafted by one of the best architects and luxury interior designers in Bangalore, this one-of-a-kind design seamlessly blends modern elegance with the rustic beauty of natural materials. Experience the allure of Structural Stones, where architectural ingenuity meets the inherent beauty of nature.",
    paragraph:
      "With a part of its exterior adorned in rugged stone, this architectural masterpiece pays homage to nature's strength and beauty, creating a solid foundation for timeless elegance and enduring charm. ",
    children: [
      {
        id: 191,
        src: bineesh1,
      },
      {
        id: 192,
        src: bineesh2,
      },
      {
        id: 193,
        src: bineesh3,
      },
      {
        id: 194,
        src: bineesh4,
      },
      {
        id: 195,
        src: bineesh5,
      },
      {
        id: 196,
        src: bineesh6,
      },
      {
        id: 195,
        src: bineesh7,
      },
      {
        id: 196,
        src: bineesh8,
      },
      {
        id: 196,
        src: bineesh9,
      },
    ],
  },
  {
    id: 30,
    src: aneesh,
    tittle: " aneesh ",
    content1: "Tetra",
    content2: "Terrace",
    content3: "Tetra Terrace one of CBE Design Studio's projects, stands as a testament to innovative design, drawing inspiration from the classic game of Tetris. Each floor is distinctively differentiated in elevation, resembling the iconic shapes of Tetris blocks. This creative architectural approach but one of the best architecture company in Bangalore, not only adds visual interest but also maximizes functionality and space utilization. The seamless integration of form and function demonstrates the ingenuity of the creative architects and designers behind Tetra Terrace. Discover a modern marvel where architectural imagination meets playful inspiration.",
    paragraph:
      " Inspired by geometric precision, this modern marvel offers a contemporary retreat where clean lines and sleek design converge, inviting you to elevate your living experience amidst stylish simplicity.",
    children: [],
  },
  {
    id: 31,
    src: ArunVan,
    tittle: " ArunVan ",
    content1: "Luminous",
    content2: "Timber",
    content3: "Luminous Timber captivates with its extensive use of wood, creating a warm and inviting ambiance throughout the house. The strategic placement of lighting highlights the intricate beauty of the timber, accentuating its natural grains and textures. A significant amount of effort has been dedicated to ensuring that the house shines brightly, even in the darkest of nights. Through meticulous planning and execution by our best architects in Bangalore, this feat has been achieved, transforming Luminous Timber into a beacon of elegance after sunset. This remarkable fusion of wood craftsmanship and lighting design exemplifies the expertise of the architecture and interior designing company in Bangalore behind the project.",
    paragraph:
      " A radiant fusion of natural beauty and modern design, this exquisite home showcases the timeless elegance of timber, infusing every corner with warmth and luminosity.",
    children: [
      {
        id: 191,
        src: arjun1,
      },
      {
        id: 192,
        src: arjun2,
      },
      {
        id: 193,
        src: arjun3,
      },
      {
        id: 194,
        src: arjun4,
      },
    ],
  },
  {
    id: 32,
    src: tripthi1,
    tittle: " tripthi1",
    content1: "Elite",
    content2: "Elegance",
    content3: "Elite Elegance, by CBE Design Studio seamlessly combines luxury interior design with minimalist elegance, creating a tranquil and sophisticated living space. Curated by one of the best Interior designing companies in Bangalore, this project features a luxurious jacuzzi, providing a private retreat for ultimate relaxation. The minimalist design incorporates clean lines and neutral tones, fostering a serene atmosphere. Elegant finishes and bespoke furniture add refinement, enhancing the overall sophistication. Additionally, the design includes thoughtful, integrated storage solutions, ensuring both functionality and style. Discover how Elite Elegance offers a unique blend of luxury living and minimalist style, transforming your home into a haven of peace and sophistication.",
    paragraph: "Where luxury is defined by panoramic vistas and indulgent relaxation, as you soak in the jacuzzi, surrounded by breathtaking views, indulging in the pinnacle of opulent living. ",
    children: [
      {
        id: 161,
        src: tripthi1,
      },
      {
        id: 162,
        src: tripthi2,
      },
      {
        id: 163,
        src: tripthi3,
      },
      {
        id: 164,
        src: tripthi4,
      },
      {
        id: 165,
        src: tripthi5,
      },
      {
        id: 166,
        src: tripthi6,
      },
      {
        id: 167,
        src: tripthi7,
      },
      {
        id: 168,
        src: tripthi8,
      },
      {
        id: 169,
        src: tripthi9,
      },
      {
        id: 170,
        src: tripthi10,
      },
      {
        id: 171,
        src: tripthi11,
      },
      {
        id: 167,
        src: tripthi12,
      },
      {
        id: 168,
        src: tripthi13,
      },
      {
        id: 169,
        src: tripthi14,
      },
      {
        id: 170,
        src: tripthi15,
      },
    ],
  },
  {
    id: 36,
    src: test1,
    tittle: " tripthi1",
    content1: "Ecliptic",
    content2: "Elegance",
    content3: "Ecliptic Elegance, by CBE Design Studio exudes sophistication with its design focused on two striking elements, a vast window allowing abundant natural light and an acute angle defining the main entrance. The large window floods the interior with sunlight, creating a bright and airy atmosphere that enhances the sense of spaciousness and serenity within. Meanwhile, the acute angle at the entrance adds a dynamic and modern touch to the overall aesthetic, inviting guests into a space that is both elegant and welcoming. Experience the harmony of natural light and contemporary design in Ecliptic Elegance, with one the best architecture companies in Bangalore.",
    paragraph: "With a façade that mesmerizes and windows that reach for the heavens, this residence epitomizes sophistication amidst nature's grand canvas. ",
    children: [
      {
        id: 161,
        src: test1,
      },
      {
        id: 162,
        src: test2,
      },
      
    ],
  },
  {
    id: 37,
    src: test3,
    tittle: " tripthi1",
    content1: "Woodland ",
    content2: "Ridge",
    content3: "Woodland Ridge, by CBE Design Studio showcases how the strategic use of wood enhances the elevation, creating a warm and inviting exterior that blends seamlessly with the natural surroundings. The lush green lawn complements the wooden elements, adding to the property's charm and providing a serene outdoor space. Designed by one of the best architecture  and interior designing company in Bangalore, Woodland Ridge makes excellent use of space, ensuring functionality and aesthetic appeal. The combination of wooden accents and verdant landscaping results in a home that is both stylish and harmonious with its environment. Discover how Woodland Ridge epitomizes sophisticated design and thoughtful spatial planning.",
    paragraph: "A sanctuary of tranquility nestled amidst majestic trees, where nature's embrace meets architectural elegance, offering a haven where every moment is a breath of fresh air. ",
    children: [
      {
        id: 161,
        src: test3,
      },
     
    
    ],
  }
  ,{
    id: 38,
    src: test4,
    tittle: " tripthi1",
    content1: "Natures ",
    content2: "Retreat",
    content3: "Nature's Retreat is a haven of tranquility, featuring amazing landscaping that seamlessly integrates a picturesque pond at the front of the house. This pond, nestled within the lush lawn, enhances the serene ambiance and provides a focal point for the beautifully manicured grounds. The drive-in area is thoughtfully designed by CBE Design Studio to complement the natural beauty, creating an inviting entrance that blends functionality with aesthetic appeal. One of the best architects in Bangalore have meticulously crafted this space, ensuring every element harmonizes with the surrounding environment. Additionally, top interior designers have ensured that the interior spaces reflect the natural beauty and elegance of the exterior. Nature's Retreat is where exquisite landscaping and elegant design come together to create a peaceful and welcoming home.",
    paragraph: "Nestled within an enchanting garden, this home offers a tranquil escape from the bustle of everyday life. Serenade your senses with the symphony of nature in every corner of this lush oasis. ",
    children: [
      {
        id: 161,
        src: test4,
      },
      {
        id: 162,
        src: test5,
      },
    ]
  },
  {
    id: 39,
    src: test6,
    tittle: " tripthi1",
    content1: "Anglescape ",
    content3: "Anglescape Abode by CBE Design Studio stands out with its innovative design, featuring a huge window that floods the interior with natural light and provides stunning views of the surroundings. The use of acute angles throughout the architecture creates a dynamic and modern aesthetic, making this home a true architectural masterpiece. One of the best architecture and top interior designing company in Bangalore have meticulously crafted every detail, ensuring that the striking angles are both visually appealing and functionally efficient. Anglescape Abode is a testament to cutting-edge design, where bold geometric elements meet exceptional craftsmanship. Discover the unique blend of style and functionality that defines Anglescape Abode.",
    content2: "Abode",
    paragraph: " A place where architectural ingenuity meets natural grace, with acute angles framing panoramic views, inviting you to dwell in a masterpiece of modern design, seamlessly integrated with its surroundings. ",
    children: [
      {
        id: 161,
        src: test6,
      },
     
    
    ],
  }
  
];
