import Madhur from "../src/Assests/Commercials/1,Empire Madhur/Empire Resturant view1.jpg";
import Madhur1 from "../src/Assests/Commercials/1,Empire Madhur/Empire Resturant view2.jpg";
import Madhur2 from "../src/Assests/Commercials/1,Empire Madhur/Empire Resturant view3.jpg";
import Madhur3 from "../src/Assests/Commercials/1,Empire Madhur/Empire interior view1.jpg";
import Madhur4 from "../src/Assests/Commercials/1,Empire Madhur/Empire interior view2.jpg";
import Madhur5 from "../src/Assests/Commercials/1,Empire Madhur/Empire interior view3.jpg";
import Madhur6 from "../src/Assests/Commercials/1,Empire Madhur/Empire interior view4.jpg";
import Madhur7 from "../src/Assests/Commercials/1,Empire Madhur/Empire interior view5.jpg";
import Madhur8 from "../src/Assests/Commercials/1,Empire Madhur/Empire view2-1.jpg";
import Madhur9 from "../src/Assests/Commercials/1,Empire Madhur/Empire view3-1.jpg";
import devanahalli from "../src/Assests/Commercials/2,empire devanhalli 2/Empire view 1 (1).jpg";
import devanahalli1 from "../src/Assests/Commercials/2,empire devanhalli 2/Empire view 2.jpg";
import devanahalli2 from "../src/Assests/Commercials/2,empire devanhalli 2/Empire view 3 (1) (1).jpg";
import deva from "../src/Assests/Commercials/3,empire devanhalli/Empire devanahalli view1(metalic silver).jpg";
import deva1 from "../src/Assests/Commercials/3,empire devanhalli/Empire devanahalli view2(metalic silver) (1).jpg";
import deva2 from "../src/Assests/Commercials/3,empire devanhalli/Empire devanahalli view3(jade silver).jpg";
import deva3 from "../src/Assests/Commercials/3,empire devanhalli/Empire devanahalli view3(metalic silver).jpg";
import audi from "../src/Assests/Commercials/4,auditorium chennai( ar+interior)/convention centre interior view1.jpg";
import audi1 from "../src/Assests/Commercials/4,auditorium chennai( ar+interior)/convention centre interior view2.jpg";
import audi2 from "../src/Assests/Commercials/4,auditorium chennai( ar+interior)/convention centre interior view3.jpg";
import audi3 from "../src/Assests/Commercials/4,auditorium chennai( ar+interior)/elevation.JPG"
import audi4 from "../src/Assests/Commercials/4,auditorium chennai( ar+interior)/audi4 (1).jpg"
import audi5 from "../src/Assests/Commercials/4,auditorium chennai( ar+interior)/audi4 (3).jpg"
import Lakshadeep from "../src/Assests/Commercials/5,Lakshadweep Resort/PHOTO-2023-03-09-11-38-18.jpg";
import Lakshadeep1 from "../src/Assests/Commercials/5,Lakshadweep Resort/PHOTO-2023-03-09-11-38-18_1.jpg";
import Lakshadeep2 from "../src/Assests/Commercials/5,Lakshadweep Resort/PHOTO-2023-03-09-11-38-18_2.jpg";
import Lakshadeep3 from "../src/Assests/Commercials/5,Lakshadweep Resort/PHOTO-2023-03-09-11-38-19.jpg";
import Lakshadeep4 from "../src/Assests/Commercials/5,Lakshadweep Resort/PHOTO-2023-03-09-11-38-20.jpg";
import Lakshadeep5 from "../src/Assests/Commercials/5,Lakshadweep Resort/PHOTO-2023-03-09-11-38-21.jpg";
import Lakshadeep6 from "../src/Assests/Commercials/5,Lakshadweep Resort/PHOTO-2023-03-09-11-38-21_1.jpg";
import Lakshadeep7 from "../src/Assests/Commercials/5,Lakshadweep Resort/topview.jpg"
import Lakshadeep8 from "../src/Assests/Commercials/5,Lakshadweep Resort/newlaks (1).jpg"
import Lakshadeep9 from "../src/Assests/Commercials/5,Lakshadweep Resort/newlaks (2).jpg"
import Lakshadeep10 from "../src/Assests/Commercials/5,Lakshadweep Resort/newlaks (3).jpg"
import Lakshadeep11 from "../src/Assests/Commercials/5,Lakshadweep Resort/newlaks (4).jpg"
import Lakshadeep12 from "../src/Assests/Commercials/5,Lakshadweep Resort/newlaks (5).jpg"
import Lakshadeep13 from "../src/Assests/Commercials/5,Lakshadweep Resort/newlaks (6).JPG"
import vaisnodevi from "../src/Assests/Commercials/6,vishnodevi builders office/1.jpg";
import vaisnodevi1 from "../src/Assests/Commercials/6,vishnodevi builders office/2.jpg";
import vaisnodevi2 from "../src/Assests/Commercials/6,vishnodevi builders office/3.jpg";
import vaisnodevi3 from "../src/Assests/Commercials/6,vishnodevi builders office/4.jpg";
import vaisnodevi4 from "../src/Assests/Commercials/6,vishnodevi builders office/5.jpg";
import vaisnodevi5 from "../src/Assests/Commercials/6,vishnodevi builders office/6.jpg";
import vaisnodevi6 from "../src/Assests/Commercials/6,vishnodevi builders office/7.jpg";
import vaisnodevi7 from "../src/Assests/Commercials/6,vishnodevi builders office/8.jpg";
import vaisnodevi8 from "../src/Assests/Commercials/6,vishnodevi builders office/Vikas Reception view 1.jpg";
import vaisnodevi9 from "../src/Assests/Commercials/6,vishnodevi builders office/Vikas Reception view 2.jpg";
import vaisnodevi10 from "../src/Assests/Commercials/6,vishnodevi builders office/Vikas Reception view 3.jpg";
import vaisnodevi11 from "../src/Assests/Commercials/6,vishnodevi builders office/confernce view2.jpg";
import stone from "../src/Assests/Commercials/7,,stone store thrissur/PHOTO-2024-01-26-17-08-35.jpg";
import virendra from "../src/Assests/Commercials/8,viera sandalia apartment/P 9.jpg";
import skc from "../src/Assests/Commercials/9, Skc mall bangalore/STIL-skc (latest).jpg";
import calicut from "../src/Assests/Commercials/10, stone store calicut/PHOTO-2024-01-26-18-48-04.jpg";
import calicut1 from "../src/Assests/Commercials/10, stone store calicut/PHOTO-2024-01-26-18-48-43.jpg";
import metro from "../src/Assests/Commercials/Metro Complex/Metrocomplex.jpeg"

export const CommercialProjectsDetails = [
  {
    id: 1,
    src: Madhur,
    content1:"Empire",
    content2:"Restaurant ",
    content4:"Channapatna",
    content3: "This highway-side restaurant in Channapatna designed by CBE Design Studio boasts a contemporary facade...",
    paragraph:"This highway-side restaurant in Channapatna designed by CBE Design Studio boasts a contemporary facade stretching 220 feet, 22000 sqft dining area including veg and non veg restaurant 45000v sqft of landscape and parking along with kiosk, adorned with large windows and sleek design elements. Inside, high ceilings, artistic adornments, and scenic views create a vibrant atmosphere, making it a sought-after destination for travelers and locals alike. As one of the best architecture and interior design companies in Bangalore, the effort we put in can clearly be seen in the elevation.",
    children: [
      { id: 11, src: Madhur1 },
      { id: 12, src: Madhur2 },
      { id: 13, src: Madhur3 },
      { id: 14, src: Madhur4 },
      { id: 15, src: Madhur5 },
      { id: 16, src: Madhur6 },
      { id: 17, src: Madhur7 },
      { id: 18, src: Madhur8 },
      { id: 20, src: Madhur9 },
    ],
  },
  {
    id: 2,
    src: devanahalli,
    content1:"Empire",
    content2:"Restaurant ",
    content4:"Channapatna",
    content3: "In the heart of the cityscape, another masterpiece emerges from the hands of architectural visionaries ...",
    paragraph:"In the heart of the cityscape, another masterpiece emerges from the hands of architectural visionaries the next iteration of the Empire building, resplendent in its golden allure. As daylight yields to dusk, the structure comes alive with a radiant glow.At its forefront stands an entrance of unparalleled elegance a curvaceous, egg-shaped marvel, adorned with a delicate lighting strip that traces its contours, guiding visitors towards the threshold of grandeur. This luminous pathway is but a prelude to the spectacle that awaits within.Emanating from the heart of the edifice are a series of sinuous, curved arch which is a MS frame, each a testament to the fluidity of design and the boundless creativity of its creators. These graceful arcs, bathed in the gentle embrace of illumination, serve not only as architectural elements but as beacons of inspiration, drawing the eye and stirring the soul.Yet, it is the golden frame that envelops the majority of the building that truly captivates the imagination. Bathed in light, it glimmers and dances with an ethereal radiance, casting a warm and inviting ambiance that beckons all who pass by to bask in its splendor.As one of the best architecture company in Bangalore, we put in a lot of time and effort to design this project. It screams extravagant and we are blessed to be part of the design process for Empire.",
    children: [
      { id: 11, src: devanahalli1 },
      { id: 12, src: devanahalli2 },
    ],
  },
  {
    id: 3,
    src: deva,
    content1:"Empire",
    content2:"Restaurant ",
    content4:"Channapatna",
    content3: "Amidst the urban expanse, this Empire Restaurant stands as a beacon of culinary delight and architectural...",
    paragraph:"Amidst the urban expanse, this Empire Restaurant stands as a beacon of culinary delight and architectural magnificence. We, CBE Design Studio, one of the top architects and interior designers in Bangalore were thrilled to be part of the designing process for such a prestigious restaurant chain. Its facade, a symphony of elegance and innovation, captivates the gaze of passersby and patrons alike. Embellished with meticulous detail, the walls adorned with white ACP beading, gracefully adorned with a subtle yet enchanting lighting strip, exude an aura of sophistication.However, it is the inclusion of blue reflective glass, strategically positioned to catch and reflect the ambient light, that elevates the building to a realm of transcendent beauty. With each passing hour, as the sun sets and the city begins to shimmer with the glow of streetlights, the building transforms into a spectacle of mesmerizing brilliance. Yet, it is not only the facade that mesmerizes; the borders of the building, aglow with the same enchanting lighting strip, cast a soft and inviting radiance that envelops the entire structure. This illumination not only guides guests to its doors but also imbues the surrounding streetscape with an undeniable charm and allure.",
    children: [
      { id: 11, src: deva1 },
      { id: 12, src: deva2 },
      { id: 13, src: deva3 },
    ],
  },
  {
    id: 4,
    src: audi,
    content1:"Convention",
    content2:"Hall",
    content4:"Chennai",
    content3: "A convention hall takes on an avant-garde allure with a fluidic, car-shaped exterior. Inspired by automotive aesthetics...",
    paragraph:"A convention hall takes on an avant-garde allure with a fluidic, car-shaped exterior. Inspired by automotive aesthetics we, CBE Design Studio, one of the top architects and best interior designers in Bangalore put in a lot of effort to make sure that its sleek design evokes motion and modernity. Within, it hosts gatherings with style and sophistication, a fusion of innovative architecture and functional elegance, setting the stage for memorable events.",
    children: [
      { id: 14, src: audi4 },
      { id: 13, src: audi3 },
      { id: 11, src: audi1 },
      { id: 12, src: audi2 },
      { id: 15, src: audi5 },
    ],
  },
  {
    id: 5,
    src: Lakshadeep,
    content1:"Capital By",
    content2:"White Sand",
    content4:"Lakshadeep",
    content3: "Discover our luxurious resort on the shores of Lakshadweep, where signature pine wood structures exude...",
    paragraph:"Discover our luxurious resort on the shores of Lakshadweep, where signature pine wood structures exude elegance. Seashells and paddles delicately partition spaces, blending indoor and outdoor seamlessly. As resort architects in Bangalore we made sure that the reception area captivates with textured white sand walls, invoking coastal serenity. Our design ethos harmonizes luxury with nature's beauty, promising an unforgettable experience amidst pristine surroundings. As one of the best Interior designing company in Bangalore, we created this masterpiece with the hope of blending nature with luxury. This project is a testament to that.",
    children: [
      { id: 11, src: Lakshadeep1 },
      { id: 12, src: Lakshadeep2 },
      { id: 13, src: Lakshadeep3 },
      { id: 14, src: Lakshadeep4 },
      { id: 15, src: Lakshadeep5 },
      { id: 16, src: Lakshadeep6 },
      { id: 16, src: Lakshadeep7 },
      { id: 16, src: Lakshadeep8 },
      { id: 16, src: Lakshadeep9 },
      { id: 16, src: Lakshadeep10 },
      { id: 16, src: Lakshadeep11 },
      { id: 16, src: Lakshadeep12 },
      { id: 16, src: Lakshadeep13 },
    ],
  },
  {
    id: 6,
    src: vaisnodevi8,
    content1:"Vyshnodevi ",
    content2:"office",
    content4:"Channapatna",
    content3: "In crafting the office interiors for Vaishnodevi,we from CBE Design Studio have made sure that the geometric elements ...",
    paragraph:"In crafting the office interiors for Vaishnodevi, we from CBE Design Studio have made sure that the geometric elements and textured walls take center stage, transforming the space into a modern sanctuary of productivity and style. As one of the best architects Bangalore and top Interiors here,, we used bold geometric patterns in the design, offering visual interest and depth. Meanwhile, carefully selected wall textures added dimension and sophistication, enhancing the ambiance and elevating the overall aesthetic. This thoughtful integration of geometric elements and textured walls not only lent a contemporary flair to the workspace but also fostered a conducive environment for creativity and collaboration.",
    children: [
      { id: 11, src: vaisnodevi1 },
      { id: 12, src: vaisnodevi2 },
      { id: 13, src: vaisnodevi3 },
      { id: 14, src: vaisnodevi4 },
      { id: 15, src: vaisnodevi5 },
      { id: 16, src: vaisnodevi6 },
      { id: 11, src: vaisnodevi7 },
      { id: 12, src: vaisnodevi },
      { id: 13, src: vaisnodevi9 },
      { id: 14, src: vaisnodevi10 },
      { id: 15, src: vaisnodevi11 },
    ],
  },
  {
    id: 7,
    src: stone,
    content1:"Traingular  ",
    content2:"Geometric White",
    content4:"Channapatna",
    content3: "We from CBE Design Studio are inspired by the simplicity of triangles which is why, this architectural gem stands as a beacon of modern...",
    paragraph:"We from CBE Design Studio are inspired by the simplicity of triangles which is why, this architectural gem stands as a beacon of modern design. Designed by the best architecture company in Bangalore, this house is cloaked in a pristine white facade, its triangular contours create a striking visual appeal, commanding attention without ostentation. Bathed in natural light, the angular surfaces cast captivating shadows, adding depth to its geometric allure. Within these walls, a harmonious blend of form and function awaits, offering a tranquil retreat from the urban bustle. This home is not just a dwelling but a testament to the enduring beauty of geometric simplicity in contemporary architecture.",
    children: [],
    
  },
  {
    id: 8,
    src: virendra,
    content1:"Viera",
    content2:"Sandalia",
    content4:"Channapatna",
    content3: "Welcome to Veira Sandalia. This project is designed by CBE Design Studio, one of the top Architects in Bangalore. This is...",
    paragraph:"Welcome to Veira Sandalia. This project is designed by CBE Design Studio, one of the top Architects in Bangalore. This is a luxurious 4-star rated hotel apartment project, nestled in the prime location of Nedumbassery. This project offers exceptional proximity to the airport and is only 8 kilometers from Angamaly, making it an excellent investment opportunity. As one of the best interior designing firm in Bnaglaore we made sure that Veira Sandalia boasts an array of premium amenities, including a reception and guest waiting lounge, prayer room, restaurant with doorstep assistance, rooftop lotus pond garden, banquet hall, cab service, beauty parlor, smart home features, 3-tier security, reading and TV lounge, conference facilities, business center, personal concierge service, shopping space, travel desk, motion sensor lighting, and a mini theatre. Enjoy unparalleled luxury and convenience in a prime location with Veira Sandalia.",
    children: [],
  },
  {
    id: 9,
    src: skc,
    content1:"Shopping",
    content2:"Mall",
    content4:"Channapatna",
    content3: "Welcome to SKC Mall, where modernity meets architectural innovation. As you step into the lobb...",
    paragraph:"Welcome to SKC Mall, where modernity meets architectural innovation. As you step into the lobby, your gaze is drawn upwards to the striking sight of metal rods suspended from the ceiling, a testament to contemporary design. Beyond the glass facade, metal rods create a mesmerizing backdrop, while large fins stand proudly, defining the mall's unique identity. This fusion of metal and glass creates a dynamic space where shopping becomes an immersive experience. As one of the best interior design company in Bangalore, we made sure that every visit to the mall would be a slightly different experience as those metal rods play with light and shadows. This keeps changing depending of the time of the day.",
    children: []
  },
  {
    id: 10,
    src: calicut,
    content1:"Gabion Stone",
    content2:"House",
    content4:"Channapatna",
    content3: "With a sleek triangular design softened by a rustic touch, this residence features a pebbled gabion wall wrapping its exterior...",
    paragraph:"With a sleek triangular design softened by a rustic touch, this residence features a pebbled gabion wall wrapping its exterior. We, as one of the best Interior Designers in Bangalore were inspired by geometric simplicity. It stands as a modern architectural marvel. The blend of angular lines and natural textures, by CBE Design Studio, creates a striking visual contrast. Under the sun's warm embrace, light and shadow dance across its surfaces, enhancing its geometric allure. Inside, a tranquil oasis awaits, seamlessly blending contemporary comfort with the rugged charm of nature. As one of the top architects in Bangalore, this home is a harmonious fusion of modern design and natural elements, inviting inhabitants to embrace simplicity and sophistication in equal measure.",
    children: [{ id: 11, src: calicut1 }],
  },
  {
    id: 12,
    src: metro,
    content1:"Contemporary",
    content2:"Mall",
    content4:"",
    content3: "Introducing one of our projects in Kalady, Kerala, crafted by the best architecture company in Bangalore, CBE Design Studio....",
    paragraph:"Introducing one of our projects in Kalady, Kerala, crafted by the best architecture company in Bangalore, CBE Design Studio. This contemporary mall features a striking white and blue design, offering a modern aesthetic that stands out in the vibrant locale of Kalady. One of the key highlights of this project is the innovative tensile structure on the terrace, which not only enhances the visual appeal of the mall but also provides functional benefits such as shade and a dynamic space for events and gatherings. Our meticulous design approach ensures that this mall is not only a shopping destination but also a landmark in architectural excellence. Experience the blend of contemporary style and cutting-edge design with this masterpiece, brought to you by the leading architecture firm in Bangalore.",
    children: [],
  },
];
