import * as React from "react";
import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import ImageListItemBar from "@mui/material/ImageListItemBar";
import style1 from "../../Assests/Style1.jpeg";
import style2 from "../../Assests/Style2.jpeg";
import style3 from "../../Assests/Style3.jpeg";
import style4 from "../../Assests/Style4.jpeg";
import style5 from "../../Assests/Style5.jpeg";
import style6 from "../../Assests/Style6.jpeg";
import style7 from "../../Assests/Style7.jpeg";
import style8 from "../../Assests/Style8.jpeg";
import { Link } from "react-router-dom";

export default function TitlebarBelowMasonryImageList() {
  return (
    <Box
      sx={{
        width: { xs: 330, sm: 550, md: 850 },
        margin: "3vw",
        borderRadius: "10px",
      }}
    >
      <ImageList variant="masonry" cols={3} gap={8}
      sx={{
        
      }}
      >
        {itemData.map((item) => (
          <Link
            style={{ color: "black", textDecoration: "none",     

             }}
            to={`/styledetails/${item.id}`}
          >
            <ImageListItem key={item.img} sx={{
               position: 'relative', // Ensure the parent element is positioned
               "&::after": {
                 content: '""', // Use double quotes for an empty string
                 position: "absolute",
                 height: "0.175rem",
                 width: "100%",
                 left: 0,
                 bottom: 0,
                 backgroundColor: "black",
                 transform: "scale(0 , 1)",
                 transition: "transform 0.3s ease-in-out",
               },
               "&:hover::after": {
                 transform: "scale(1 , 1)",
               },
            }}>
              <img
                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                src={`${item.img}?w=248&fit=crop&auto=format`}
                alt={item.title}
                loading="lazy"
              />
              <ImageListItemBar
                position="below"
                title={item.author}
                sx={{
                  textAlign: "center",
                  fontWeight: "600",

                  "&::after": {
                    content: "''",
                    position: "absolute",
                    height: "0.175rem",
                    width: "100%",
                    left: 0,
                    bottom: 0,
                    backgroundColor: "black",
                    transform: "scale(0 , 1)",
                    transition: "transform 0.3s ease-in-out",
                  },
                  "&:hover::after": {
                    transform: "scale(1 , 1)",
                  },
                }}
              />
            </ImageListItem>
          </Link>
        ))}
      </ImageList>
    </Box>
  );
}

const itemData = [
  {
    img: style1,
    id: 1,
    title: "Victorian",
    author: "Victorian Architecture ",
    contentu:
      "Mumbai, Chennai and Kolkata are some of the regions where Victorian Architecture can be observed. This type of Architecture stands out due to its intricate details, usage of elaborate ornamentation and coloured brickwork, which has significantly influenced Tropical architecture in such regions. What makes these designs so evident is the influence in the usage of canted bay windows, pitched roofs along with a few more architectural elements which were deliberately incorporated as a way of adjustment to the tropical climate of south india. For example, the addition of verandas, high ceilings etc, which was mainly used for ventilation purposes. This combination produced a unique architectural style that blended Victorian sophistication with tropical functionality.",
  },
  {
    img: style2,
    id: 2,
    title: "Traingular",
    author: "Traingular Architecture ",
    contentu:
      "A futuristic style of architecture, considered to be appealing to the wealthy, is what defines Triangular architecture. These designs scream exclusivity as they are characterized by sleek lines and geometric precision. They stand as a symbol of innovation and creativity highlighting the fusion of art and engineering at its finest, at the same time challenging traditional notions of form and functionality. Architects and Designers express themselves through their designs. The canvas being the building and the artist being themselves. Triangular architecture gives birth to a compelling and versatile canvas for them. Usage of this shape in the creation of unique buildings pushes the boundaries of shadow, spatial relationships and allows the creative exploration of light. In turn this leads to the birth if building that stand out.",
  },
  {
    img: style3,
    id: 3,
    title: "Spanish",
    author: "Spanish Architecture ",
    contentu: ` The influence of Spanish architecture in Kerala can be seen in certain structures that incorporate elements such as arched doorways, intricate detailing, and vibrant color palettes. Spanish architectural elements, with their rich history and cultural flair, can add a distinctive touch to buildings in Kerala. Additionally, Spanish-inspired gardens may feature elements like terracotta tiles, wrought iron furniture, and lush greenery, creating a charming`,
  },
  {
    img: style4,
    id: 4,
    title: "Parametric",
    author: "Parametric Architecture ",
    contentu:
      "Math, when used practically to create unique designs in terms of architecture, is called Parametric Architecture.These types of designs are rare and eco-friendly. Buildings of this style are specifically known for reacting to their environment and constantly adapting to its surroundings. It's considered to be more efficient and enjoyable for people to use. Designs like these do not come easy, as our architects need to push the boundaries of creativity and innovation within a versatile framework, allowing customizable, site- specific designs that promote sustainability and elegance. A combination of environmental awareness, technology and art gives birth to the future of design (Parametric Architecture).",
  },
  {
    img: style5,
    id: 5,
    title: "Kerala Style",
    author: "Kerala Style Architecture ",
    contentu:
      "Kerala's traditional architecture, with its distinctive style, often features sloping roofs, wooden structures, and intricate carvings. The beauty lies in the harmonious blend of traditional elements with modern influences, creating a unique fusion that respects heritage while accommodating contemporary needs.The charm of Kerala's traditional architecture is further enhanced by the use of locally sourced materials, vibrant colors, and attention to detail. This blending of tradition and modernity not only preserves the cultural identity of Kerala but also adds a timeless elegance to the architectural landscape",
  },
  {
    img: style6,
    id: 6,
    title: "Geometric",
    author: "Geometric Architecture ",
    contentu:
      "Draws inspiration from precise shapes and forms, emphasizing clean lines and symmetry. The influence of geometry in design provides a cutting-edge perfection, creating structures that are visually striking and often technologically advanced.Architecture's connection with geometry goes beyond aesthetics; it plays a crucial role in optimizing spatial functionality, structural stability, and energy efficiency. The use of geometric principles allows architects to achieve innovative and efficient designs, contributing to both the visual appeal and functionality of modern buildings. contrast to the often hectic pace of city living.",
  },
  {
    img: style7,
    id: 7,
    title: "Fluidic",
    author: "Fluidic Architecture ",
    contentu: `Embraces flowing and organic forms, rejecting sharp angles in favor of smooth and interconnected structures. The belief that Earth has no sharp ends is reflected in designs that aim to mimic the natural contours of the environment, creating a more harmonious relationship between architecture and nature.

    The beauty of fluidic architecture lies in its ability to evoke a sense of movement and fluidity, offering a visually dynamic and aesthetically pleasing experience. This design approach often prioritizes sustainability and energy efficiency, aligning with modern environmental consciousness.
    
    In terms of trends, fluidic architecture represents a contemporary movement, with innovative buildings adopting these fluid forms. These structures not only showcase cutting-edge design but also contribute to a more organic and visually engaging urban landscape.`,
  },
  {
    img: style8,
    id: 8,
    title: "Colonial",
    author: "Colonial Architecture ",
    contentu:
      "Holds historical significance, reflecting the influence of colonial powers on a region's built environment. Its importance lies in preserving cultural heritage and providing a tangible link to the past. In India, the everlasting beauty of colonial architecture is often embraced in residential projects. The timeless appeal of colonial concepts, with their classic aesthetics and functional layouts, contributes to creating elegant and enduring homes that blend tradition with modern living.",
  },
];
export { itemData };
