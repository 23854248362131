import React from "react";
import "./About.css";
import aboutMain from "../../Assests/AboutUs/futureLaptop.jpg";
import CEO from "../../Assests/CEO.jpg";
import value1 from "../../Assests/AboutUs/PremiumQuality.png"
import value2 from "../../Assests/AboutUs/Preconstruction (2).png";
import value3 from "../../Assests/AboutUs/Timely Delivery.png";
import aboutmainphone from "../../Assests/AboutUs/futurePHONE.jpg"
import vision from "../../Assests/Vision.jpg";
const About = () => {
  return (
    <div>

      <div>
        <div className="about-us-color-span">
          {/* This is the starting point of the About US page */}
          <div className="main-img-about">
            <img src={aboutMain} alt="About Best architecture company"></img>
          </div>
          <div className="main-img-about-phone">
            <img src={aboutmainphone} alt="About Best interior designing company"/>
          </div>
          <div className="about-us-tag">
            <h1>Meet One Of The Best Architects <span>and Interior Designers In Bangalore</span></h1>
          </div>
          <div className="about-us-tag-content">
            <p>Dedicated to delivering excellence is our motto, whether they're for homes,
               businesses, or public spaces. We ensure that every project receives the same 
               level of passion and attention to detail irrespective of the budget. From creating
                beautiful homes to enhancing workplace environments and shaping public spaces, 
                our unwavering pursuit of quality remains constant. We strive to deliver innovative, 
                insightful, and impactful design solutions to every client, demonstrating our work ethic 
                towards each and every project.

            </p>
          </div>
          <div className="ceo-tag">
            <h2>
             Insights From Our <span>Architectural Experts</span>
            </h2>
          </div>
          <div className="words">
            <div className="words-left">
              <h2> Words from CEO</h2>
              <p>
                "As the CEO of our esteemed architectural firm, I am proud to
                lead a team driven by creativity, innovation, and a profound
                commitment to excellence. We embark on each project with a
                passion for transforming spaces and creating architectural
                marvels that stand the test of time. Our collaborative spirit,
                coupled with a keen understanding of client needs, ensures that
                every design solution is not just functional but also an
                embodiment of aesthetic brilliance. Together, we strive to
                redefine architectural landscapes, leaving a lasting impact on
                the built environment. Join us on this inspiring journey of
                architectural ingenuity and transformative design."
              </p>
            </div>
            <div className="words-right">
              <img src={CEO}></img>
            </div>
          </div>
          <div className="mission-tag-div">
            <h2>Our <span>Mission</span> </h2>
          </div>
          <div className="mission-div">
            <div className="mission-img-div">
              <img src={vision}></img>
            </div>
            <div className="mission-content-div">
              <h2>Designing Spaces, Inspiring Lives.</h2>
              <p>
              As an Architecture and Interior Designing firm in Bangalore, CBE Design Studio
               strives to create thriving environments that nurture dreams. Through inventive and 
               eco-conscious designs we aim to build spaces that ignite inspiration, boost well-being, 
               and cultivate a communal spirit. Guided by expertise, sustainability, and close collaboration,
                we turn aspirations into tangible structures. Our team of architects, designers and skilled 
                workers embrace innovation, harmonizing practicality with aesthetics. Together, we envision a 
                future where each building narrates its own tale and makes a meaningful contribution to society.
                 Embark with us on a journey of architectural mastery and human-centric design.

              </p>
            </div>
          </div>
          <div className="values-tag">
            <h2>Our Values As <span>Architects In Shaping Homes</span></h2> 

          </div>
          <div className="values">
            <div className="values-left">
              <div className="values-left1">
                <h3>Preconstruction Design</h3>
                <p>
                We approach each project individually, crafting tailored solutions that align precisely with your unique needs and desires. From home renovations to interior design projects, we strive to transform your ideas into a tangible reality that is a personalized expression of your individuality. 
Why don't we stick to one style?Why do we strive to think outside the box? Why do we make complicated yet elegant designs? <b>That is for you to find out and for us to deliver</b>.

                </p>
              </div>
              <div className="values-left-img">
                <img src={value1}></img>
              </div>

              <div className="values-left1">
                <h3>Serves Best Quality</h3>
                <p>
                Our homes are built to stay beautiful with time. We only use the best materials in everything we build, ensuring that they stay strong and attractive for many years to come. Keeping overall maintenance in mind we use unique materials which means lesser expenditure on maintenance and more visually pleasing homes.
                </p>
              </div>
            </div>
          </div>
          <div className="values-left">
            <div className="values-left-img">
              <img src={value2}></img>
            </div>
            <div className="values-left1">
              <h3>Timely Handovers
</h3>
              <p>
              We ensure a timely transition into your new home by following strict schedules in every 
              phase of the design process. With your approval, we meticulously manage deadlines, 
              guaranteeing a smooth experience from concept to completion. That includes having direct 
              contact with the CEOs 
              of CBE Design Studio company  

              </p>
            </div>
            <div className="values-left-img">
              <img src={value3}></img>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
